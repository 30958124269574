// Module
var code = `<template>
    <div class="navbar-area fixed-top">
        <nav id="navigation-bar" class="navbar navbar-expand-md sticky-top cx-background navbar-transparent \${router.currentInstruction.config.route === 'privacy-policy' ||
            router.currentInstruction.config.route === 'copyright-policy' || router.currentInstruction.config.route === 'terms-of-service' ? 'border-less' : ''} \${dynamicNavContent || someOneContentHovered() || pageIsScrollDown || notificationsIsHovered || profileIsHovered ? 'navbar-border-bottom' : ''}" ref="navbar">
            <div class="container-nav justify-content-between">
                <div class="left-container">
                    <div class="d-flex align-items-center">
                        <cx-touch-target expanded>
                            <cds-icon
                                icon="menu"
                                variant="filled"
                                class="navbar-toggler"
                                click.trigger="handleDrawerOpenClick()"
                            >
                            </cds-icon>
                        </cx-touch-target>
                        <a
                            class="navbar-brand cursor-pointer"
                            click.delegate="navigateHome(\$event)"
                            mouseenter.trigger="handleHovered('isLogoHovered')"
                            mouseleave.trigger="handleHovered('isLogoHovered')"
                            href="\${homePageRoute.routeName}"
                        >
                            <img
                                src="\${ isLogoHovered ? '/logo/chicksx-main-logo-hover.svg' : '/logo/chicksx-main-logo.svg' }"
                                id="chicksx-nav-logo"
                                alt="logo"
                                loading="lazy"
                            >
                        </a>
                        <hr id="nav-separator" class="d-none d-lg-block">
                    </div>
                    <!-- Dropdowns -->
                    <div class="d-none d-lg-flex dropdowns">
                        <cx-dropdown-content
                            repeat.for="dropdown of dropdowns"
                            mouseover.trigger="handleContentMouseEnter(\$event)"
                            title.bind="dropdown.title | siteStrings : dropdown.fallback"
                            class="py-auto"
                            nameref.bind="'content-dropdown'"
                            name="content-dropdown"
                            content.bind="dropdown.contentName"
                            click.delegate="handleDropdownOpenClick(dropdown.contentName)"
                            open-dropdown.bind="dropdown.isOpen"
                            content-hovered.bind="dropdown.isContentHovered"
                            is-clicked.bind="dropdown.isClicked"
                            tab-active.bind="dropdown.isActive"
                        ></cx-dropdown-content>
                    </div>
                </div>
                <div id="navbar-text" class="navbar-content d-flex flex-row">
                    <cx-notification-bell if.bind="user" user.bind="user" page-loading.bind="navbarLoading" data-cy="notification-bell"></cx-notification-bell>
                    <!-- TODO: Will be remove it and added on fotter -->
                    <!-- <cx-currency-dropdown
                        class="d-none d-lg-flex"
                        currency-options.bind="currencyOptions"
                        languages.bind="languageList"
                        current-currency.from-view="currentCurrency"
                        current-language.from-view="currentLanguage"
                        ref="currencyDropdown"
                        data-cy="currency-dropdown"
                    ></cx-currency-dropdown> -->

                    <a id="parent" click.delegate="handleRedirect('')" if.bind="!isLoggedIn" class="d-flex align-items-center">
                        <cx-button
                            mouseenter.trigger="handleHovered('isSignInHovered')"
                            mouseleave.trigger="handleHovered('isSignInHovered')"
                            button-icon="person"
                            button-text="\${'CX_SIGN_IN' | siteStrings : 'Sign In'}"
                            button-icon-style.bind="isSignInHovered ? 'material-icons' : 'material-icons-outlined'"
                            button-style.bind="\`\${buttonStyling}\`"
                            data-cy="sign-in"
                        ></cx-button>
                    </a>

                    <cx-user-dropdown
                        if.bind="user"
                        user.bind="user"
                        page-loading.bind="navbarLoading"
                        class="dropdown-orientation"
                        user-class="name-width"
                        button-class="btn-padding"
                        data-cy="customer-portal-dropdown"
                    ></cx-user-dropdown>

                </div>
            </div>
            <div>
                <mdc-drawer type="modal" view-model.ref="drawer" class="drawer w-100">
                    <mdc-drawer-header>
                        <div class="mobile-navbar-header">
                            <cx-touch-target>
                                <button class="menu-container d-flex align-items-center text-primary-button-pressed"
                                    click.delegate="handleDrawerOpenClick()">
                                    <cds-icon class="cursor-pointer close-icon" icon="close" variant="filled" click.delegate="handleDrawerOpenClick()"></cds-icon>
                                    <span class="menu-button">\${ 'CX_CLOSE' | siteStrings : 'Close'}</span>
                                </button>
                            </cx-touch-target>

                            <cx-text-input
                                class="input-with-label w-100"
                                label="Search"
                                leading-icon.bind="'search'"
                                trailing-icon.bind="'close'"
                                value.two-way="filterOptionsMobile"
                                key-up-function.call="filterOptionsMobileChange(\$event)"
                                button-icon-function.call="cleanMobileSearchBar()"
                                outlined.bind="false"
                            >
                            </cx-text-input>
                        </div>
                    </mdc-drawer-header>
                    <mdc-drawer-content data-simplebar class="purple-scrollbar" id="mobile-navbar-drawer-content">
                        <cx-nav-preorder-mobile
                            crypto-list.bind="cryptoList"
                            currency-list.bind="currencyList"
                            payment-methods-list.bind="paymentMethods"
                            filter-input.two-way="filterOptionsMobile"
                            current-currency.bind="currentCurrency"
                            language-list.bind="languageList"
                            current-language.bind="currentLanguage"
                        ></cx-nav-preorder-mobile>
                    </mdc-drawer-content>
                </mdc-drawer>
            </div>
        </nav>
        <!-- Dynamic selected dropdown content -->
        <div
            mouseleave.trigger="handleContentMouseLeave()"
            class="dynamic-dropdown-content \${pageIsScrollDown || dynamicNavContent || someOneContentHovered() ? 'scrolled' : ''}"
        >
            <cx-newsletter-prompt if.bind="user && !user.optedInForEmails && !user.closedSubscriptionBanner" showing-another-banner.bind="showBanner" user.bind="user"></cx-newsletter-prompt>
    <cds-banner
        if.bind="showBanner"
        id="cds-banner"
        state.bind="stateBanner"
        state-icon="error"
        show-closing-icon=""
        bannerclosed.delegate="handleCloseBanner()"
    >
    <span class="banner-text font-light">
        \${textBanner}
        <span if.bind="clickFunctionName && clickFunctionName !== ''" class="cursor-pointer banner-text" click.delegate="clickFunction()">Click here.</span>
        <a if.bind="url && url !== ''" class="cursor-pointer banner-text" href="\${url}" target="_blank" rel="nofollow noopener noreferrer">Click here.</a>
    </span>
    </cds-banner>
            <cx-nav-preorder
                if.bind="dynamicNavContent || someOneContentHovered()"
                preoder-type-action.bind="dynamicNavContent"
                crypto-list.bind="cryptoList"
                currency-list.bind="currencyList"
                payment-methods-list.bind="paymentMethods"
                max-visible-options.bind="maxVisibleOptions"
            ></cx-nav-preorder>
        </div>
    </div>
    <div id="cx-reading-progress-bar" class="w-100" show.bind="showReadingProgress">
        <div id="reading-progress-fill"></div>
    </div>
</template>

`;
// Exports
export default code;