import './verification.scss';
import { TaskQueue, autoinject, bindable, observable, computedFrom } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { CustomerService } from 'services/customer-service';
import { ToastService } from 'services/toast-service';
import { Router } from 'aurelia-router';
import { ImageService } from 'services/image-service';
import { validateTrigger, ValidationController, ValidationRules } from 'aurelia-validation';
import { ValidationRenderer } from 'resources/validation-renderer';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { QueryParamsValueConverter } from 'resources/value-converters/query-params';
import { WebsiteService } from 'services/website-service';
import { PageContentAreaService } from 'services/page-content-area-service';
import { Helper } from 'resources/helpers/helper';
import heic2any from 'heic2any';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';
import { ToastType } from 'services/models/toast';

@autoinject()
export class Verification {
    constructor(
        private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private customerService: CustomerService,
        private toastService: ToastService,
        private router: Router,
        private imageService: ImageService,
        private validator: ValidationController,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private queryParamsValueConverter: QueryParamsValueConverter,
        private websiteService: WebsiteService,
        private pageContentAreaService: PageContentAreaService,
        private taskQueue: TaskQueue,
        private helper: Helper,
        private siteStringsValueConverter: SiteStringsValueConverter
    ) {
        this.validator.addRenderer(new ValidationRenderer());
        this.validator.validateTrigger = validateTrigger.manual;
    }

    @bindable private user;
    userSubscriber;
    veriffSubscriber;

    phoneVerification = false;
    emailVerification = false;
    idVerification = false;
    addressVerification = false;
    additionalVerification = false;

    requestedPhoneUpdate = false;
    requestedEmailUpdate = false;
    requestedAddressUpdate = false;
    requestedIdUpdate = false;
    showSupportMessage = false;
    contentLoading = true;
    viewingAsAdmin = false;

    showErrorMark;
    showCheckMark;
    showPendingMark;
    stopWatch1;
    stopWatch2;
    emailFocusInStopWatch;
    timeouts;
    loading;
    emailSent;

    isToastSend = false;

    isAddressInReview;
    selectedFiles;
    rules;
    showEmailValidationError = false;

    userVeriffData;
    userPhone;
    activeVerificationTabs = {
        'phone': false,
        'email': false,
        'id': false,
        'address': false
    };

    idVeriffViewModel;
    poaHidden = true;
    fileUploaded;
    fileName;
    addressUploading;
    dataRouter;
    urlParams;
    phoneElement;
    pages;
    params;
    customerPortalPage;
    requestingEmail = false;
    fileState;
    fileText = 'Upload a document';
    fileTextKey = 'CX_UPLOAD_DOCUMENT';
    fileUpload = false;

    filesList = [];
    fileExtensions = ['pdf', 'heic'];
    fileTypes = ['image', 'video'];
    inputAccept = '';
    dropZoneOutside;
    tempFiles = [];
    maxNumberOfFiles = 10;
    @observable filesDropLoading = false;
    isAllFilesDropLoaded = true;
    fileHolderElement;
    tempDisabled;


    activate(params) {
        this.contentLoading = true;
        this.params = params;
    }

    async attached() {
        try {
            this.helper.addLoadingComponent('verification');
            this.user = await this.sessionService.getProfile();
            if (!this.user) {
                const homePageRoute = this.pages?.find(x => x.name === 'Home')?.routeName ?? '';
                if (this.params.email && this.params.token) {
                    this.router.navigate(this.params.email && this.params.token ? `${homePageRoute}/?email=${this.params.email}&token=${this.params.token}` : homePageRoute);
                } else {
                    this.router.navigate(homePageRoute);
                }
                return;
            }
            this.userPhone = { ...this.user };
            this.urlParams = this.queryParamsValueConverter.toView(window.location.href);
            const pagesPromise = this.websiteService.getPagesByWebsiteShortcode();

            if (this.user) {
                const [
                    emailInReview,
                    userVeriffData,
                    isAddressInReview,
                    pages
                ] = await Promise.all([
                    this.sessionService.getEmailInReview(),
                    this.customerService.getVeriffUserData(this.user.id),
                    this.sessionService.getAddressVerificationInReview(this.user.id),
                    pagesPromise
                ]);

                this.user.emailInReview = emailInReview;
                if (this.user.emailInReview) {
                    this.emailSent = true;
                }

                this.userVeriffData = userVeriffData;
                this.isAddressInReview = isAddressInReview;
                this.pages = pages;

                if (this.isAddressInReview) {
                    this.fileName = 'Under review';
                    const fileInput = document.getElementById('fileHolder');
                    fileInput?.classList.add('input-password_warning');
                } else {
                    this.fileName = 'Please select document';
                }

                this.customerPortalPage = this.pages.find(x => x.name === 'Customer Portal');
                await this.pageContentAreaService.getByPageId(this.customerPortalPage?.id);
                this.dataRouter = this.router.routes.find(route => route.name === 'verification').data;
                this.setupFileInputAccept();
                this.handleEventSubscriptions();
                this.handleEmailConfirmation();
                if (this.dataRouter?.tab) {
                    this.toggleVerificationTab(this.dataRouter.tab);
                }

                this.dropZoneOutside = document.getElementById('drag-drop-additional-info');
            }
        } finally {
            this.helper.validateLoading('verification');
        }
    }

    setupFileInputAccept() {
        for (const type of this.fileTypes) {
            this.inputAccept += `${type}/*, `;
        }

        for (const extension of this.fileExtensions) {
            this.inputAccept += `.${extension}, `;
        }

        this.inputAccept = this.inputAccept.substring(0, this.inputAccept.length - 2);
    }

    async phoneVerificationOpenTab() {
        if (!this.user) return;

        const userTemp = await this.sessionService.refreshProfile();
        this.phoneVerification = !this.phoneVerification;
        if (!this.phoneVerification && this.phoneElement?.verificationStage === this.phoneElement?.stages?.ENTERING_PHONE && userTemp.phoneNumberConfirmed) {
            this.eventAggregator.publish('user-updated', { user: userTemp });
        }
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
    }

    handleEventSubscriptions() {
        const events = {
            'user-updated': payload => this.user = payload.user,
            'veriff-verification': async(payload) => {
                if (payload.user.idVerified) {
                    this.user.idVerified = payload.user.idVerified;
                } else if (payload.user.addressVerified) {
                    this.user.addressVerified = payload.user.addressVerified;
                    this.userVeriffData = await this.customerService.getVeriffUserData(this.user.id);
                }
            },
            'page-loaded': () => this.contentLoading = false,
            'admin-view-updated': payload => this.viewingAsAdmin = payload.bool
        };

        this.helper.subscribeEvents(this, events);
    }

    async handleEmailConfirmation() {
        if (!this.shouldConfirmEmail) return;
        try {
            const successful = await this.customerService.confirmEmail(this.urlParams?.email, this.urlParams?.token);
            if (successful) {
                this.toastService.showToast('Success!', `Thank you ${this.user.firstName}, your email has been verified successfully.`, 'success');
                this.user.emailConfirmed = true;
                this.eventAggregator.publish('user-updated', { user: this.user });
            } else {
                this.toastService.showToast('Error', 'Email token invalid or expired. Please ensure you open the latest email verification link or try again.', 'error');
            }
        } catch (e) {
            console.log(e);
        }
    }

    async toggleVerificationTab(tab: 'phone' | 'email' | 'address' | 'id' | 'additionalInfo', event?: PointerEvent) {
        event?.stopPropagation();
        const verifying = `${tab}Verifying`;

        if (this[verifying]) {
            return;
        }

        this[verifying] = true;
        const verification = `${tab}Verification`;

        if (tab === 'phone') {
            this.resetPhoneNumber();
            this.user.phoneNumberConfirmed = this.phoneVerification ? (await this.sessionService.refreshProfile()).phoneNumberConfirmed : null;
        }

        this[verification] = !this[verification];
        this[verifying] = false;
    }

    async handleTabClick(event, tab, condition, callback) {
        if (this[`${tab}Verification`]) {
            return;
        }

        if (!condition) {
            this.toggleVerificationTab(tab, event);
            await callback?.();
            return;
        }

        this.showMessage();
    }

    async emailValidated() {
        this.validator.reset();
        ValidationRules.ensure('email').matches(/^(\s+)?[\w-.+]+@([\w-]+\.)+[\w-]{1,4}(\s+)?$/).on(this.user);
        this.rules = await this.validator.validate();
        const matchesEmail = this.rules.results[0].valid;
        if (!matchesEmail) {
            this.showEmailValidationError = this.showErrorMark = true;
            this.loading = false;
            return;
        }
        this.loading = false;
        const response = await this.updateInformation();
        if (response) {
            this.sendConfirmationEmail();
        }
    }

    async emailUpdatedOnKeyPress(ev) {
        this.showErrorMark = this.showCheckMark = this.showPendingMark = this.loading = this.showEmailValidationError = false;
        this.timeouts = [this.stopWatch1, this.stopWatch2];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (ev?.key === 'Enter') {
            this.emailValidated();
            return;
        } else {
            if (this.user.email !== '') {
                this.stopWatch1 = setTimeout(async() => {
                    this.loading = true;
                    this.stopWatch2 = setTimeout(async() => {
                        this.emailValidated();
                    }, 2000);
                }, 2000);
            }
        }
    }

    emailUpdatedOnFocusIn() {
        this.showErrorMark = false;
        this.emailFocusInStopWatch = setTimeout(() => {
            this.emailUpdatedOnKeyPress({ key: 'Enter' });
        });
    }

    async sendConfirmationEmail() {
        if (!this.requestingEmail) {
            this.requestingEmail = true;
            try {
                const response = await this.customerService.requestEmailConfirmation();
                if (response.success) {
                    this.user.emailInReview = true;
                    this.sessionService.saveEmailInReview(this.user.emailInReview);
                    this.emailSent = true;
                    this.eventAggregator.publish('user-updated', { user: this.user });
                    this.toastService.showToast(undefined, 'A verification email has been sent to your inbox.', 'success');
                }
            } catch (e) {
                console.log(e);
            } finally {
                setTimeout(() => {
                    this.requestingEmail = false;
                }, 5000);
            }
        }
    }

    async cancelEmailVerification() {
        this.user.emailInReview = false;
        this.sessionService.saveEmailInReview(this.user.emailInReview);
        this.emailSent = false;
        this.toggleVerificationTab('email');
    }

    async updateInformation(skipToast = false) {
        let toastData = { title: '', message: '', type: '' };
        try {
            this.user.email = this.user.email.trim();
            const response = await this.customerService.updateInformation(this.user);
            if (response) {
                response.idVerificationInReview = await this.sessionService.getIdVerificationInReview(this.user.id);
                response.addressVerificationInReview = await this.sessionService.getAddressVerificationInReview(this.user.id);
                response.selfieVerificationInReview = await this.sessionService.getSelfieVerificationInReview(this.user.id);
                this.user = response;
                this.isToastSend = true;
                this.eventAggregator.publish('user-updated', { user: this.user });
                toastData = { title: 'Updated profile successfully', message: 'Updated Profile', type: 'success' };
                return response;
            } else {
                toastData = { title: 'Failed to update user profile', message: 'Please check that all fields are valid.', type: 'error' };
            }
        } catch (e) {
            console.log(e);
            toastData = { title: 'Failed to update user profile', message: 'Please check that all fields are valid.', type: 'error' };
        } finally {
            if (!skipToast) {
                this.toastService.showToast(toastData.title, toastData.message, toastData.type);
            }
        }
    }

    showMessage() {
        this.toastService.showToast(undefined, 'Please contact support@chicksgold.com to update.', ToastType.ERROR);
    }

    closedVeriff() {
        this.idVerification = false;
    }

    async handleAddressUpload(file) {
        if (this.tempDisabled || !file) return;
        const fileInput = document.getElementById('fileHolder');
        if (this.isAddressInReview) {
            this.isAddressInReview = false;
            fileInput.classList.remove('input-password_warning');
        }
        this.fileName = file[0].name;
        const formData = this.imageService.buildFormData(file);
        try {
            this.addressUploading = true;
            const response = await this.imageService.postClientDocument(formData, 2, 2);
            if (response?.id) {
                this.addressUploading = false;
                this.toastService.showToast('Uploaded successfully', 'Your file has been uploaded and was set for review', 'success');
                this.isAddressInReview = true;
                this.fileName = 'Under review';
                this.showPendingMark = true;
                fileInput.classList.add('input-password_warning');
                setTimeout(async() => {
                    this.user.addressVerificationInReview = true;
                    this.eventAggregator.publish('user-updated', { user: this.user });
                }, 1000);
            } else {
                this.addressUploading = false;
                this.toastService.showToast('Document failed to upload', 'Please review the uploaded file', 'error');
            }
        } catch (e) {
            this.addressUploading = false;
            this.toastService.showToast('Document failed to upload', 'Please review the uploaded file', 'error');
        }
    }

    catchSubmit() {
        return;
    }

    mouseOverOnTooltips() {
        this.eventAggregator.publish('tooltip-shown');
    }

    triggerDocumentSelector() {
        if (this.tempDisabled) return;
        const fileInput = document.getElementById('documentSelector');
        fileInput.click();
    }

    clearAddressVerification() {
        this.tempDisabled = true;
        this.fileName = 'Please select document';
        this.fileHolderElement.classList.remove('input-password_warning');
        this.isAddressInReview = false;
        setTimeout(() => this.tempDisabled = false, 500);
    }

    resetPhoneNumber() {
        this.user.phoneNumberConfirmed = false;
        this.user.phoneNumberInReview = false;
        this.userPhone.phoneNumberConfirmed = false;
        this.userPhone.phoneNumberInReview = false;
        this.phoneElement.clearPhoneInput();
        this.phoneElement.clearAuthCode();
        this.phoneElement.verificationStage = this.phoneElement.stages.ENTERING_PHONE;
    }

    async handleFileUpload(data) {
        this.fileUpload = true;
        const response = await this.imageService.postClientDocument(data.formData, data.verificationCategoryId, data.documentTypeId);
        if (response) {
            this.fileTextKey = 'CX_UPLOADED_SUCCESSFULLY';
            this.fileText = 'Uploaded successfully!';
            this.fileState = 'success';
            this.toastService.showToast(undefined, 'Your document has been successfully uploaded and is now being reviewed.', 'success');
            setTimeout(() => {
                this.fileUpload = false;
                this.fileTextKey = 'CX_DOCUMENT_UPLOADED';
                this.fileText = 'Document uploaded';
            }, 1000);
        } else {
            this.fileUpload = false;
            this.fileState = 'error';
            this.fileTextKey = 'CX_DOCUMENT_FAILED_TO_UPLOAD';
            this.fileText = 'Document failed to upload';
            this.toastService.showToast('Error', 'The document failed to upload. Please try again.', 'error');
        }
    }

    dragEnterHandler(ev) {
        if (this.activeVerificationTabs['additionalInfo']) {
            ev.preventDefault();
            ev.stopPropagation();
            if (ev.target.id === 'drop-zone' ||
                ev.target.className.includes('drop-zone-lg') ||
                ev.target.id === 'additional-information-tab' ||
                ev.target.parentElement.id === 'drag-drop-additional-info' ||
                ev.target.offsetParent.id === 'drag-drop-additional-info') {
                this.dropZoneOutside.classList.add('hover-drop-zone');
            }
        }
    }

    dragLeaveHandler(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        if (ev.target.id === 'drop-zone' ||
            ev.target.parentElement?.id === 'drag-drop-additional-info' ||
            ev.target.offsetParent?.id === 'drag-drop-additional-info') {
            this.dropZoneOutside.classList.remove('hover-drop-zone');
        }
    }

    async dropHandler(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        this.filesDropLoading = true;
        if (ev.dataTransfer.items) {
            [...ev.dataTransfer.items].forEach(async (item, index) => {
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    await this.processFile(file);
                }
                if (index === [...ev.dataTransfer.items].length - 1 && this.tempFiles.length === 0) {
                    this.filesDropLoading = false;
                }
            });
        } else {
            [...ev.dataTransfer.files].forEach(async (file, index) => {
                await this.processFile(file);
                if (index === [...ev.dataTransfer.files].length - 1 && this.tempFiles.length === 0) {
                    this.filesDropLoading = false;
                }
            });
        }

        if (ev.dataTransfer.items) {
            ev.dataTransfer.items.clear();
        } else {
            ev.dataTransfer.clearData();
        }
    }

    checkFileType(file) {
        if (this.fileTypes.filter(item => file.type.startsWith(item + '/')).length > 0 ||
            this.fileExtensions.filter(item => file.name.endsWith('.' + item)).length > 0) {
            return true;
        }

        this.toastService.showToast('Error', `This file type (${file.name.substring(file.name.lastIndexOf('.') + 1)}) is not allowed`, 'error');
        return false;
    }

    checkFileSize(file) {
        const maxSizePerFile = 20971520; //Equivalent to 20 MB
        const maxSizeAllFiles = 20971520; //Equivalent to 20 Mb

        if (file['size'] > maxSizePerFile) {
            this.toastService.showToast('Error', `The size of the file ${file.name}, exceeds 20MB.`, 'error');
            return false;
        }

        if (this.filesList.map(item => item.size).reduce((prev, curr) => prev + curr, 0) + file.size > maxSizeAllFiles) {
            this.toastService.showToast('Error', 'The total size of all files must not exceed 20 MB. Please try again', 'error');
            return false;
        }

        return true;
    }

    blobToFile(theBlob, fileName): File {
        const b = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return <File>theBlob;
    }

    findLastIndex(array, query) {
        const arrayTemp = [];
        array.forEach(element => arrayTemp.push(element.name));
        return arrayTemp.lastIndexOf(query);
    }

    async uploadAttachments(fileList) {
        const filesStatus = [];
        for (const file of fileList) {
            const formData = this.imageService.buildFormData([file]);
            const response = await this.imageService.postClientDocument(formData, null, null);
            filesStatus.push({ name: file.name, upload : response ? true : false });
        }
        const filesStatusFiltered = filesStatus.filter(file => file.upload === false);
        if (filesStatusFiltered.length === 0) {
            this.toastService.showToast(undefined, 'All documents have been successfully uploaded and are now being reviewed.', 'success');
        } else {
            const numFilesUpload = filesStatus.length - filesStatusFiltered.length;
            const fileExtensions = filesStatusFiltered.map(file => file.name.substring(file.name.lastIndexOf('.') + 1));
            if (numFilesUpload > 0) {
                this.toastService.showToast(undefined, `Only ${numFilesUpload} documents have been successfully uploaded and are now being reviewed.`, 'success');
            }
            this.toastService.showToast('Error', `Some file extensions (${ fileExtensions.join(', ')}) are not supported`, 'error');
        }
    }

    async filesDropLoadingChanged(newValue) {
        if (!newValue) {
            try {
                if (this.filesList.length > 0 || this.tempFiles.length > 0) {
                    await this.uploadAttachments(this.filesList);
                }
            } catch (e) {
                console.log(e);
            }
            this.filesList = [];
            this.tempFiles = [];
            this.isAllFilesDropLoaded = true;
            this.dropZoneOutside?.classList.remove('hover-drop-zone');
        }
    }

    async processFile(file) {
        this.isAllFilesDropLoaded = false;
        if (await this.checkFileType(file) && await this.checkFileSize(file)) {
            if (this.tempFiles.length >= this.maxNumberOfFiles) {
                this.toastService.showToast('Error', `Only ${this.maxNumberOfFiles} files can be attached, please try again`, 'error');
            } else {
                let newFileName = null;
                let newItem = null;
                if (file.name.includes('.heic')) {
                    newFileName = file.name.replace('.heic', '_heic.png');
                    this.tempFiles.push({ name: newFileName, isLoaded: false });
                    newItem = this.blobToFile(await heic2any({
                        blob: file,
                        toType: 'image/png',
                        quality: 1
                    }).then(result => result), newFileName);
                } else {
                    this.tempFiles.push({ name: file.name, isLoaded: false });
                }

                this.filesList.push(newItem ? newItem : file);
                this.tempFiles[this.findLastIndex(this.tempFiles, newItem ? newItem.name : file.name)].isLoaded = true;

            }

            if (this.tempFiles.every(file => file.isLoaded)) {
                setTimeout(async () => {
                    this.filesDropLoading = false;
                }, 1000);
            }
        }
    }

    @computedFrom('user.emailConfirmed', 'urlParams.email', 'urlParams.token')
    get shouldConfirmEmail() {
        return !this.user?.emailConfirmed && this.urlParams?.email && this.urlParams?.token;
    }

    getVerifiedText(data) {
        const verified = this.pageContentAreaService.displaySiteString('CG_VERIFIED', 'Verified');
        return `${verified}: ${data}`;
    }


    @computedFrom('viewingAsAdmin', 'user.phoneNumberConfirmed', 'user.phoneNumber', 'user.phoneCountryCode')
    get phoneSubtitle() {
        if (!this.user?.phoneNumberConfirmed) return;
        const phone = this.helper.phoneFormat(this.user?.phoneNumber, this.user?.phoneCountryCode);
        return this.getVerifiedText(phone);
    }

    @computedFrom('viewingAsAdmin', 'user.emailConfirmed', 'user.email')
    get emailSubtitle() {
        if (!this.user?.emailConfirmed) return;
        return this.getVerifiedText(this.user?.email);
    }

    @computedFrom('viewingAsAdmin', 'user.addressVerified', 'user.address')
    get addressSubtitle() {
        if (!this.user?.addressVerified) return;
        return this.getVerifiedText(this.address);
    }

    @computedFrom('viewingAsAdmin', 'user.idVerified', 'user.firstName', 'user.lastName', 'userVeriffData.firstName', 'userVeriffData.lastName', 'userVeriffData.dateOfBirth')
    get idSubtitle() {
        if (!this.user?.idVerified) return;
        const fullName = this.helper.userNameFormat(this.user, this.userVeriffData, true);
        return this.getVerifiedText(fullName);
    }

    checkUserBillingAddress() {
        return Boolean(this.user?.address && this.user?.city && this.user?.state && this.user?.country);
    }

    getUserBillingAddress() {
        const data = [this.user?.address, this.user?.city, this.user?.state, this.user?.country?.toUpperCase()];
        return this.helper.joinString(data, ', ');
    }

    @computedFrom('user.addressVerified', 'userVeriffData.addresses', 'user.address', 'user.city', 'user.state', 'user.country')
    get address() {
        const hasBilling = this.checkUserBillingAddress();
        const fullAddress = this.helper.toCapitalize(this.userVeriffData?.addresses?.[0]?.fullAddress, null);
        const billing = hasBilling ? this.getUserBillingAddress() : '';
        const address = this.helper.joinString([fullAddress, billing], ' & ');
        return address;
    }
}
