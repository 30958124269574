// Module
var code = `<template>
    <div id="cx-accordion-menu" ref="accordionMenu">
        <div id="previus-content"></div>
        <mdc-list id="selected-content">
            <mdc-list-item
                class="main-expandable-accordion drawer-item drawer-back-item option-header \${navItem.isCurrency || navItem.isLanguage ? 'custom-padding' : ''}"
                if.bind="selectedOptionsStack.length > 0"
                click.delegate="handleBackPreviusSelected()"
            >
                <div class="d-flex align-items-center w-100">
                    <cds-icon icon="arrow_left" variant="filled" class="arrow"></cds-icon>
                    <span class="option-title header">
                        <template if.bind="previusOption.isCurrency || previusOption.isLanguage || !previusOption.isCategory">
                            \${previusOption.name}
                        </template>
                        <template else>
                            \${previusOption.name | siteStrings:previusOption.name}
                        </template>
                    </span>
                </div>
            </mdc-list-item>
            <div class="selected-option">
                <mdc-deprecated-list>
                    <mdc-deprecated-list-item 
                        repeat.for="option of displayItems"
                        class="drawer-item drawer-list-item \${selectedOption.id === 'exchange' ? 'drawer-item-displayed' : ''} \${option.childs.length === 0 && !isAnSeparatorOption(option.id) && !isLanguageOrCurrency(option.category) ? 'no-border' : ''} \${option.id === 'all' && listHaveChilds() && isATerminalCategory(selectedOption.category) ? 'border' : ''} \${!option.isCategory ? 'filtered-background' : ''}"
                        click.delegate="handleSelectedOption(option)"
                    >
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="icon-name-option">
                                <img
                                    if.bind="option.imagePath && option.category !== 'LanguageOptions'"
                                    class="material-icons icon \${roundedIcon(option)}"
                                    src="\${option.imagePath}"
                                    alt="\${option.name}"
                                    loading="lazy"
                                />
                                <span if.bind="isLanguageOrCurrency(option.id) || (!isAnInitialOption(option.id) && !isAnExchangeOption(option.id))" class="fw-medium'">\${option.name}</span>
                                <span else class="fw-medium'">\${option.name | siteStrings}</span>
                            </div>
                            <cds-icon
                                if.bind="option.items && option.items.length"
                                icon="arrow_right"
                                variant="filled"
                                class="arrow"
                            ></cds-icon>
                            <div if.bind="option.customContent" innerHTML.bind="option.customContent"></div>
                        </div>
                    </mdc-deprecated-list-item>
                </mdc-deprecated-list>
            </div>

            <cx-no-results
                message="\${'CX_NO_RESULT_NAV_FILTER' | siteStrings:option.auxname}"
                show.bind="displayItems.length === 0"
            ></cx-no-results>
        </mdc-list>
    </div>
<div>
</template>
`;
// Exports
export default code;