// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/icons/help-icon.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/icons/lock.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/icons/close-icon.png", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/icons/green_check.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/icons/red_fail.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("/static/icons/checkmark_unfilled.svg", import.meta.url);
// Module
var code = `<template>
    <div id="vgs-form">
        <form id="collect-form">
            <cx-circular-progress if.bind="readyingForm" class="mt-5 pb-5"></cx-circular-progress>
            <div class="d-flex flex-wrap \${'mx-lg-2' |in: !isSubscriptionRoute}" show.bind="!readyingForm">
                <div class="mt-3 mt-lg-0 mb-2 col-12 col-lg-3 pe-lg-3" show.bind="!usingPreviousCard">
                    <label class="pb-2 pt-0 pt-lg-3 text-regular heading text-black">First Name</label>
                    <mdc-text-field
                        outlined
                        autocomplete="on"
                        value.two-way="firstNameOnCard & validate"
                        placeholder.bind="'First Name'"
                        id.bind="'first-name'"
                        name.bind="'first-name'"
                        class="w-100 input-password \${showGreenInputFirstNameOnCard ? 'cx-input--success' : showErrorInputFirstNameOnCard ? 'cx-input--error' : ''}"
                        keyup.delegate="firstNameOnCardOnKeyPress()"
                        focusin.delegate="firstNameOnFocusIn()"
                        focusout.delegate="checkFirstNameValidation()"
                    ></mdc-text-field>
                </div>
                <div class="mb-2 col-12 col-lg-3 px-lg-2" show.bind="!usingPreviousCard">
                    <label class="pb-2 pt-3 text-regular heading text-black">Last Name</label>
                    <mdc-text-field
                        outlined
                        autocomplete="on"
                        value.two-way="lastNameOnCard & validate"
                        placeholder.bind="'Last Name'"
                        id.bind="'last-name'"
                        name.bind="'last-name'"
                        class="w-100 input-password \${showGreenInputLastNameOnCard ? 'cx-input--success' : showErrorInputLastNameOnCard ? 'cx-input--error' : ''}"
                        keyup.delegate="lastNameOnCardOnKeyPress()"
                        focusin.delegate="lastNameOnFocusIn()"
                        focusout.delegate="checkLastNameValidation()"
                    ></mdc-text-field>
                </div>

                <div class="mb-2 col-12 col-lg-6 ps-lg-3" show.bind="!usingPreviousCard">
                    <label for="cc-number" class="pb-2 pt-3 text-regular text-black">Card Number</label>
                    <div class="form-field w-100" id="cc-number"></div>
                    <span class="helper-text" id="ccn-help">\${state ? getErrorText('card_number') : ''}</span>
                </div>

                <div class="mb-2 col-12 col-lg-3 pe-lg-3" show.bind="!usingPreviousCard">
                    <label for="cc-exp-date" class="pb-2 text-regular text-black">Exp. Date</label>
                    <div class="input-group">
                        <div class="form-field w-100" id="cc-exp-date"></div>
                    </div>
                    <span class="helper-text my-1" id="exp-help">\${state ? getErrorText('card_exp') : ''}</span>
                </div>

                <div class="mb-2 col-12 col-lg-3 px-lg-2" show.bind="!usingPreviousCard">
                    <label for="cc-cvc" class="pb-2 text-regular text-black">CVV</label>
                    <div class="input-group">
                        <div class="form-field w-100" id="cc-cvc"></div>
                        <div id="help-icon" class="input-group-addon">
                            <img id="help-icon-img" class="ms-1" src="${___HTML_LOADER_IMPORT_0___}"
                                mdc-tooltip="value: The CVV Number is a 3 digit number that can be found on the back of VISA®, MasterCard® and Discover® cards or a 4 digit numeric code that appears on the front of American Express® cards.;"
                                alt="help" loading="lazy">
                        </div>
                    </div>
                    <span class="helper-text my-1" id="cvv-help">\${state ? getErrorText('card_cvc') : ''}</span>
                </div>

                <div class="mb-2 col-12 col-lg-6 ps-lg-3" show.bind="!usingPreviousCard">
                    <div class="card-details d-flex flex-row">
                        <div class="d-flex align-items-center ps-3 pe-3 text-center">
                            <img class="lock-icon" src="${___HTML_LOADER_IMPORT_1___}" alt="lock" loading="lazy">
                        </div>
                        <div class="p-1 my-auto text-small">
                            Your card details are safe and protected by XXX and will not be shared or distributed to any
                            third party.
                        </div>
                    </div>
                </div>

                <div class="col-12 mb-2" if.bind="!usingPreviousCard && userSavedCards.length > 0">
                    <div class="text-end text-blue cursor-pointer">
                        <span click.delegate="usePreviousCard()">Use Previous Card Instead</span>
                    </div>
                </div>

                <div class="col-12" show.bind="usingPreviousCard && userSavedCards.length > 0">
                    <div class="cards-container row">
                        <div class="form-cards-title col-6 d-flex gap-small">
                            <div class="mb-2 \${isSubscriptionRoute ? 'text-large-static' : 'text-regular'} font-medium text-purple">
                                Saved Cards
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="text-end text-blue">
                                <span class="cursor-pointer \${isSubscriptionRoute ? 'text-medium-static' : 'text-regular'} text-purple" click.delegate="useNewCard()">
                                    + Add a New Credit Card
                                </span>
                            </div>
                        </div>
                        <div class="col-12 \${cardsColumns} my-2 cards-container-card \${'left' |in: \$index % 3 === 0 || \$first} \${'right' |in: (\$index + 1) % 3 === 0 && !\$first} \${'bottom' |in: \$index >= userSavedCards.length - 3}" repeat.for="card of userSavedCards">
                            <div 
                                class="previous-card d-flex flex-row rounded cursor-pointer \${selectedCard === card ? 'selected' : ''} \${(isCardExpired(card) && selectedCard !== card) ? 'expired' : ''} \${(isCardExpiringSoon(card) && selectedCard !== card) ? 'expiring-soon' : ''}" 
                                click.delegate="selectedCard = card"
                            >
                                <img class="col-3 col-md-4 col-lg-2 card-image ms-3 card-img my-auto py-2" src="\${helper.getCardImageType(card.cardType)}" alt="credit card icon" loading="lazy">
                                <div class="col-7 static-width col-md-5 col-lg-7 d-flex flex-column my-3">
                                    <div if.bind="card.cardHolder" class="card-full-name card-regular-text font-light text-gray text-truncate text-extra-small">
                                        \${card.cardHolder}
                                    </div>
                                    <div else class="card-full-name font-light text-gray text-truncate text-extra-small">
                                        \${firstNameOnCard} \${lastNameOnCard}
                                    </div>
                                    <div class="card-last-4 letter-spacing">
                                        **** **** **** \${card.lastFour}
                                    </div>
                                    <div class="card-expiry letter-spacing">\${helper.addLeadingZeroToNumber(card.expMonth)} / \${card.expYear.substring(2)}</div>
                                    <div class="card-billing-address card-regular-text font-light text-gray text-extra-small w-100" mdc-tooltip="value.bind: getCardAddress(card);">
                                        \${getCardAddress(card)}
                                    </div>
                                </div>
                                <div class="col d-flex flex-column mt-2 me-2 cards-container-rightmost">
                                    <img class="close-icon-card \${isCardExpiringSoon(card) || isCardExpired(card) ? 'close-icon-card-expired' : ''} align-self-end" src="${___HTML_LOADER_IMPORT_2___}" click.delegate="removeCreditCard(card)" alt="close" loading="lazy">
                                    <span if.bind="isCardExpiringSoon(card)" class="d-block d-md-none d-lg-block expiry-soon-text text-extra-small text-yellow">Expiring soon</span>
                                    <span if.bind="isCardExpiringSoon(card)" class="d-none d-md-block d-lg-none expiry-soon-text text-extra-small text-yellow" mdc-tooltip="value: Expiring soon;">Expiring soon</span>
                                    <span if.bind="isCardExpired(card)" class="d-block d-md-none d-lg-block expiry-text text-extra-small text-red">Card expired</span>
                                    <span if.bind="isCardExpired(card)" class="d-none d-md-block d-lg-none expiry-text text-extra-small text-red" mdc-tooltip="value: Card expired;">Card expired</span>
                                    <span if.bind="isCardExpired(card) || isCardExpiringSoon(card)" class="\${isCardExpired(card) ? 'update-expiry' : isCardExpiringSoon(card) ? 'update-expiry-v2' : ''} text-decoration-underline text-extra-small text-blue" click.delegate="updateExpiryOnClick()">Update</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div show.bind="selectedCard && usingPreviousCard && updatingCardExpiry">
                <div class="row pt-3 px-lg-1 mb-4">
                    <div class="col-12 \${isBalanceRoute ? 'col-lg-6 col-xxl-4' : 'col-md-6 col-lg-4'} position-relative">
                        <label class="pb-2 pt-3 text-regular heading">First Name</label>
                        <mdc-text-field
                            outlined
                            autocomplete="on"
                            value.two-way="firstNameExpiry & validate"
                            placeholder.bind="'First Name'"
                            class="w-100 input-password \${showGreenCheckMarkFirstNameExpiry ? 'cx-input--success' : showErrorCheckMarkFirstNameExpiry ? 'cx-input--error' : ''}"
                            keyup.delegate="checkFirstNameExpiryOnKeyPress()"
                        ></mdc-text-field>
                        <img src="${___HTML_LOADER_IMPORT_3___}" class="circle-icon-expiry" alt="green check" if.bind="showGreenCheckMarkFirstNameExpiry" loading="lazy">
                        <img src="${___HTML_LOADER_IMPORT_4___}" class="circle-icon-expiry" alt="error" if.bind="showErrorCheckMarkFirstNameExpiry" loading="lazy">
                    </div>
                    <div class="col-12 \${isBalanceRoute ? 'col-lg-6 col-xxl-4' : 'col-md-6 col-lg-4'} position-relative">
                        <label class="pb-2 pt-3 text-regular heading">Last Name</label>
                        <mdc-text-field
                            outlined
                            autocomplete="on"
                            value.two-way="lastNameExpiry & validate"
                            placeholder.bind="'Last Name'"
                            class="w-100 input-password \${showGreenCheckMarkLastNameExpiry ? 'cx-input--success' : showErrorCheckMarkLastNameExpiry ? 'cx-input--error' : ''}"
                            keyup.delegate="checkLastNameExpiryOnKeyPress()"
                        ></mdc-text-field>
                        <img src="${___HTML_LOADER_IMPORT_3___}" class="circle-icon-expiry" alt="green check" if.bind="showGreenCheckMarkLastNameExpiry" loading="lazy">
                        <img src="${___HTML_LOADER_IMPORT_4___}" class="circle-icon-expiry" alt="error" if.bind="showErrorCheckMarkLastNameExpiry" loading="lazy">
                    </div>
                    <div class="col-12 \${isBalanceRoute ? 'col-lg-6 col-xxl-2' : 'col-md-6 col-lg-2'} position-relative">
                        <label class="pb-2 pt-3 text-regular exp-date-label">Exp Month / Year</label>
                        <mdc-text-field
                            outlined
                            ref="cardExpiryDate"
                            autocomplete="off"
                            value.two-way="expiryDate & validate"
                            placeholder.bind="'MM / YY'"
                            maxlength="7"
                            onPaste="return false"
                            class="w-100 input-password \${value ? '' : 'exp-date-padding'} \${showGreenCheckMarkDateExpiry ? 'cx-input--success' : showErrorCheckMarkDateExpiry ? 'cx-input--error' : ''}"
                            keyup.delegate="checkExpiryDateOnKeyPress(\$event)"
                        ></mdc-text-field>
                        <img src="${___HTML_LOADER_IMPORT_3___}" class="circle-icon-expiry" alt="green check" if.bind="showGreenCheckMarkDateExpiry" loading="lazy">
                        <img src="${___HTML_LOADER_IMPORT_4___}" class="circle-icon-expiry" alt="error" if.bind="showErrorCheckMarkDateExpiry" loading="lazy">
                    </div>
                    <div class="col-12 \${isBalanceRoute ? 'col-lg-6 col-xxl-2' : 'col-md-6 col-lg-2'}">
                        <button mdc-button raised class="submit-button update-button button-blue-filled mdc-button--outlined w-100" type="button" click.delegate="updateCardExpiry()">
                            Update
                        </button>
                    </div>
                </div>
            </div>

            <cx-billing-address
                ref="billingAddressEl"
                loading.bind="loading"
                countries.bind="countries"
                states.bind="states"
                billing.two-way="billing"
                user.bind="user"
                show-previous-billing.bind="!isAddingStage"
                billing-address-is-valid.two-way="billingAddressIsValid"
                show.bind="!requestOnlyCvv"
                class="billing-address"
                show-fields.from-view="showFieldsAddress"
                parent-class="my-4"
            ></cx-billing-address>
        </form>

        <cx-dialog
            view-model.ref="cvvDialogModule"
            on-close-callback.bind="cvvOnClose"
            title="CVV Authentication"
        >
            <div class="d-flex flex-row position-relative">
                <mdc-text-field
                    outlined
                    ref="cvvInput"
                    name="passLabel"
                    autocomplete="cvv"
                    value.two-way="cvv & validate"
                    required.bind="true"
                    type="text"
                    class="input-password cvv-container w-100 \${cvvValid ? 'cx-input--success' : cvvInvalid ? 'cx-input--error' : ''}"
                    placeholder="CVV"
                    keyup.delegate="cvvUpdatedOnKeyPress(\$event)"
                    focusin.delegate="cvvUpdatedOnFocusIn()"
                    focusout.delegate="checkCvvValidation()"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                    paste.delegate="helper.handlePasteNumberValidation(\$event, cvvInput)"
                ></mdc-text-field>
                <button 
                    mdc-button
                    outlined
                    type="submit"
                    click.delegate="submitCvv()"
                    class="submit-button ms-4 px-4 button-purple-filled"
                >
                    Submit
                </button>
                <img src="${___HTML_LOADER_IMPORT_5___}" class="circle-icon circle-icon-cvv" alt="green check" if.bind="cvvValid" loading="lazy">
                <img src="${___HTML_LOADER_IMPORT_4___}" class="circle-icon circle-icon-cvv" alt="error" if.bind="cvvInvalid" loading="lazy">
                <mdc-circular-progress if.bind="showMiniSpinnerCvv" class="mini-spinner-icon circle-icon-cvv" size="25" stroke-width="2"></mdc-circular-progress>
            </div>
        </cx-dialog>

        <cx-dialog
            view-model.ref="dialogModule"
            html-content.bind="challengeIframe"
            trigger-circular-load="true"
            title="3DS Authentication"
        ></cx-dialog>
    </div>
</template>
`;
// Exports
export default code;