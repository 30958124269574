// Module
var code = `<template>
    <div id="get-started">
        <div class="container p-0">
            <div class="form-section">
                <cx-form-get-started></cx-form-get-started>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;