import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Helper } from 'resources/helpers/helper';
import { CurrencyService } from 'services/currency-service';
import { Currency } from 'services/models/purchase-flow/exchange';
import { PageContentAreaService } from 'services/page-content-area-service';
import { WebsiteService } from 'services/website-service';
import './cx-location-card.scss';
import { googleMapsKey } from 'environment';

@autoinject()
export class LocationComponent {
  @bindable titleKey: string;
  @bindable descriptionKey: string;
  @bindable addressKey: string;
  @bindable hoursDaysKey: string;
  @bindable contactKey: string;
  @bindable imageMapKey: string;

  @bindable address: string;
  @bindable phoneNumber: string;
  @bindable hoursAndDays: string;
  @bindable imageMap: string;

  @bindable isMapModalOpen: boolean = false;
  @bindable mapIframeUrl: string = '';

  activeCurrencies: Currency[] = [];
  public tradePageRoute: string = '/buy';

  constructor(
    private router: Router,
    private pageContentAreaService: PageContentAreaService,
    private websiteService: WebsiteService,
    private currencyService: CurrencyService,
    private helper: Helper
  ) {}


  async activate(params) {
      const { locationName, storeNumber } = params;

      if (!locationName || !storeNumber) {
          console.error('[LocationComponent] Missing locationName or storeNumber');
          this.router.navigateToRoute('404');
          return;
      }

      try {
          const pages = await this.websiteService.getPagesByWebsiteShortcode();
          const pageId = pages.find((x) => x.name === 'Locations')?.id;

          if (!pageId) {
              this.router.navigateToRoute('404');
              return;
          }

          const slug = locationName.trim().toLowerCase();
          const slugUpper = slug.toUpperCase();

          this.titleKey = `STORE_${slugUpper}_TITLE_DETAIL`;
          this.descriptionKey = `STORE_${slugUpper}_DESCRIPTION_DETAIL`;
          this.addressKey = `STORE_${slugUpper}_ADDRESS_DETAIL`;
          this.hoursDaysKey = `STORE_${slugUpper}_HOURS_DAYS_DETAIL`;
          this.contactKey = `STORE_${slugUpper}_CONTACT_DETAIL`;
          this.imageMapKey = `STORE_${slugUpper}_IMAGEMAP_DETAIL`;

          const contentAreas = await this.pageContentAreaService.getByPageId(pageId);

          const extractContent = (key: string): string => {
              const found = contentAreas.find((c) => c.key === key)?.markup;
              if (!found) {
                  console.warn(`[LocationComponent] Key "${key}" not found`);
                  return 'Not available';
              }
              const parsed = new DOMParser().parseFromString(found, 'text/html');
              return parsed.body.textContent?.trim() || 'Not available';
          };

          this.address = extractContent(this.addressKey);
          this.phoneNumber = extractContent(this.contactKey).replace(/[^0-9+]/g, '');
          this.hoursAndDays = extractContent(this.hoursDaysKey);
          this.imageMap = extractContent(this.imageMapKey);



      } catch (error) {
          console.error('[LocationComponent] Error loading detail:', error);
          this.router.navigateToRoute('404');
      }
  }

  async attached() {
      try {
          this.activeCurrencies = await this.currencyService.getActiveCurrenciesByWebsite();
      } catch (error) {
          console.error('[LocationComponent] Error retrieving active currencies:', error);
      }
  }

  extractTextFromMarkup(markup: string): string {
      try {
          const parser = new DOMParser();
          const doc = parser.parseFromString(markup, 'text/html');
          return doc.body.textContent?.trim() || '';
      } catch (error) {
          console.error('[LocationComponent] Error processing markup:', error);
          return '';
      }
  }

  openMapModal(): void {
      if (this.address) {
          const baseUrl = 'https://www.google.com/maps/embed/v1/place';
          const apiKey = googleMapsKey();
          const encodedAddress = encodeURIComponent(this.address);

          this.mapIframeUrl = `${baseUrl}?key=${apiKey}&q=${encodedAddress}`;
      } else {
          console.error('[LocationComponent] No valid address for map');
          alert('No valid address for map');
      }
      this.isMapModalOpen = true;
  }

  closeMapModal(): void {
      this.isMapModalOpen = false;
  }

  navigateTo(path: string): void {
      if (!path) {
          console.error('[LocationComponent] The route is not defined.');
          return;
      }
      this.router.navigate(path);
  }

  goToTrade(data) {
      try {
          const give = this.activeCurrencies.find((c) => c.code === data?.from);
          const receive = this.activeCurrencies.find((c) => c.code === data?.to);
          const intent = (give || receive)
              ? this.helper.getTradeIntent(give, receive)
              : data;
          this.helper.redirectWithOptionsSelected(
              this.router,
              'trade',
              data,
              intent
          );
      } catch (error) {
          console.error('[LocationComponent] Error redirecting to the store:', error);
      }
  }
}
