import './trade-currency.scss';
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import { WebsiteService } from 'services/website-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { PageContentAreaService } from 'services/page-content-area-service';
import { CurrencyChangedEvent, DesktopBreakpoint, PhoneBreakpoint, SizeChangedEvent } from 'resources/constants';
import { ISizeEvent } from 'types/events';
import { PageContentArea } from 'services/models/page/pageContentArea';
import { Router } from 'aurelia-router';
import { Helper } from 'resources/helpers/helper';
import { ProductCategoryService } from 'services/product-category-service';
import { ProductCatWithGame } from 'services/models/game/productCategory';
import { CurrencyService } from 'services/currency-service';
import { Currency } from 'services/models/purchase-flow/exchange';
import { SessionService } from 'services/session-service';
import { CoinbaseService } from 'services/coinbase-service';
import { DataCurrencyStats } from 'services/models/currency/dataStatsCurrenciesInterface';
import { CustomerService } from 'services/customer-service';
import { CurrencyCryptoPriceCard } from 'services/models/currency/currencyCryptoPriceCard';

@autoinject()
export class TradeCurrency {
    constructor(
        private helper: Helper,
        private eventAggregator: EventAggregator,
        private pageContentAreaService: PageContentAreaService,
        private productCategoryService: ProductCategoryService,
        private websiteService: WebsiteService,
        private router: Router,
        private currencyService: CurrencyService,
        private sessionService: SessionService,
        private coinbaseService: CoinbaseService,
        private customerService: CustomerService,
        private bindingEngine: BindingEngine
    ) {
        this.pageLoading = true;
    }

    pageLoading: boolean = true;
    loadingSubscription: Subscription;
    adminViewSubscription: Subscription;
    widthSubscriber: Subscription;
    tradeUrlUpdatedSubscriber: Subscription;
    skeletonSubscription: Subscription;
    listContent = [];
    infoQuadCardsData = [];
    viewingAsAdmin = false;
    pageContentArea: PageContentArea[] = [];
    deviceType: string = 'DESKTOP';
    width: number;
    key: string;
    suffix: string = 'TRADE_PAGE';
    defaultSuffix: string = 'TRADE_PAGE';
    exchangeInfo: {type: string, subtype: string, exchange: string} = { type: 'buy', subtype: null, exchange: null };
    pageLoadedSubscriber: Subscription;
    tradeCategory: ProductCatWithGame;
    availableCurrencies: Currency[];
    currencyBase: Currency;
    currencyRates: { [key: string]: number; };
    currencies: string[];
    mostPopularCurrencies: CurrencyCryptoPriceCard[] = [];
    dataStatsCurrencies: DataCurrencyStats[];
    currencyChangeSubscription: Subscription;
    timer;
    contentDropdownKey: string;
    autoRestart: boolean = true;
    faqPageRoute: string;
    pageId: number;
    allCardsLoaded: boolean = false;
    isDropdownOpen: boolean = false;
    learnMoreCards = [
        {
            title: 'TRADE_LEARN_MORE_CARD_TITLE_1',
            paragraph: 'TRADE_LEARN_MORE_CARD_TEXT_1',
            buttonCaption: 'CX_LEARN_MORE',
            redirectParam: 'TRADE_LEARN_MORE_CARD_REDIRECT_1',
            redirectRoute: '',
            ariaLabel: 'Learn more about trading'
        },
        {
            title: 'TRADE_LEARN_MORE_CARD_TITLE_2',
            paragraph: 'TRADE_LEARN_MORE_CARD_TEXT_2',
            buttonCaption: 'CX_LEARN_MORE',
            redirectParam: 'TRADE_LEARN_MORE_CARD_REDIRECT_2',
            redirectRoute: '',
            ariaLabel: 'Learn more about trading'
        },
        {
            title: 'TRADE_LEARN_MORE_CARD_TITLE_3',
            paragraph: 'TRADE_LEARN_MORE_CARD_TEXT_3',
            buttonCaption: 'CX_LEARN_MORE',
            redirectParam: 'TRADE_LEARN_MORE_CARD_REDIRECT_3',
            redirectRoute: '',
            ariaLabel: 'Learn more about trading'
        },
        {
            title: 'TRADE_LEARN_MORE_CARD_TITLE_4',
            paragraph: 'TRADE_LEARN_MORE_CARD_TEXT_4',
            buttonCaption: 'CX_LEARN_MORE',
            redirectParam: 'TRADE_LEARN_MORE_CARD_REDIRECT_4',
            redirectRoute: '',
            ariaLabel: 'Learn more about trading'
        }
    ];

    async activate(params, _routeConfig, navigationInstruction) {
        this.pageLoading = true;

        const { config, router } = navigationInstruction;
        const parts = router.baseUrl.replace(/-/g, '').split('/').filter(Boolean);
        const [type, subtype, exchange] = parts;
        this.exchangeInfo = {
            type: type === 'trade' ? 'buy' : type,
            subtype: router.baseUrl.includes('swap') ? subtype : null,
            exchange: router.baseUrl.includes('swap') ? exchange : subtype
        };

        Promise.all([
            this.websiteService.getPagesByWebsiteShortcode(),
            this.productCategoryService.getNavCategory('trade')
        ]).then(async ([pages, navCategory]) => {
            this.pageId = pages.find(x => x.name === 'Trade')?.id;
            this.tradeCategory = navCategory;
            const routeData = await this.getRouteData();
            if (routeData) {
                _routeConfig.navModel.settings.metaDescription = routeData.metaDescription;
                _routeConfig.navModel.title = routeData.title;
                this.updateMetaTags(routeData?.title, routeData?.metaDescription);
            }
        });

        if (params?.params) {
            const routeFromParams = params.params.replace(/-/g, '');
            this.key = `TRADE_${routeFromParams.toUpperCase()}`;
        }
        else if (config?.settings?.keyName) this.key = config.settings.keyName;
        else {
            this.key = `TRADE_${this.exchangeInfo.type.toUpperCase()}${this.exchangeInfo.subtype ? '_' + this.exchangeInfo.subtype.toUpperCase() : ''}`;
        }

        this.suffix = this.key ?? this.suffix;
        this.buildContent();
    }

    async attached() {
        try {
            this.helper.addLoadingComponent('trade-currency');
            this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            this.setCurrentSize();

            await Promise.all([
                this.loadInitialData(),
                this.websiteService.getManyRoutes(this, ['faq', 'trade'])
            ]).then(async () => {
                await this.loadAdditionalData();
                this.handleEventSubscriptions();
            }).finally(() => {
                this.helper.validateLoading('trade-currency');
            });
        } catch (error) {
            console.error('Error in trade-currency attached:', error);
            this.helper.validateLoading('trade-currency');
        }
    }

    async loadInitialData() {
        try {
            const [pageContentArea, availableCurrencies, currentCurrency, currencyRates] = await Promise.all([
                this.pageContentAreaService.getByPageId(this.pageId),
                this.currencyService.getActiveCurrenciesByWebsite(),
                this.sessionService.getCurrency(),
                this.coinbaseService.getExchangeRates('USD')
            ]);

            if (!currencyRates?.data?.rates) {
                console.error('Failed to fetch initial currency rates');
                return;
            }

            this.pageContentArea = pageContentArea;
            this.availableCurrencies = availableCurrencies;
            this.currencyBase = this.availableCurrencies.find(currency => currency.code === currentCurrency);
            this.currencyRates = currencyRates.data.rates;

            if (!this.currencyBase || !this.currencyRates) {
                console.error('Missing required currency data');
                return;
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async loadAdditionalData() {
        try {
            if (!this.currencyRates || !this.currencyBase) {
                console.error('Required currency data not available');
                return;
            }

            this.currencies = ['BTC', 'ETH', 'SOL', 'ADA'];

            const [mostPopularCurrencies, dataStatsCurrencies, learnMoreCardsData] = await Promise.all([
                this.currencyService.getMultipleCurrencyStatsByCode(this.currencies),
                this.currencyService.getStatsDataCurrency(this.currencies),
                Promise.all(this.learnMoreCards.map(async (card) => {
                    let cardParam = (await this.pageContentAreaService.getByKey(card.redirectParam))?.markup;
                    cardParam = this.helper.removeHtmlElements(cardParam);
                    return {
                        ...card,
                        redirectRoute: cardParam ? `${this.faqPageRoute}?card=${cardParam}` : this.faqPageRoute
                    };
                }))
            ]);

            this.mostPopularCurrencies = mostPopularCurrencies;
            this.dataStatsCurrencies = dataStatsCurrencies;
            this.learnMoreCards = learnMoreCardsData;

            this.observeCurrencies();
            if (this.mostPopularCurrencies && this.currencyRates[this.currencyBase.code]) {
                this.mostPopularCurrencies.forEach(currency => {
                    currency.priceUSD = currency.price;
                    currency.price = currency.price * this.currencyRates[this.currencyBase.code];
                    currency.base = this.currencyBase;
                    currency.redirectToRoute = this.getCurrencyRedirectToRoute(currency);
                });
            } else {
                console.error('Missing rates for currency calculations');
            }
        } catch (error) {
            console.error('Error in loadAdditionalData:', error);
            throw error;
        }
    }

    getCurrencyRedirectToRoute(currency: CurrencyCryptoPriceCard): string {
        let currencyPair = currency.code;
        if (this.exchangeInfo.type === 'buy') {
            currencyPair = `${this.currencyBase.code}-to-${currency.code}`;
        }
        if (this.exchangeInfo.type === 'sell') {
            currencyPair = `${currency.code}-to-${this.currencyBase.code}`;
        }
        return `${this.exchangeInfo.type}/${this.exchangeInfo.subtype ? 'crypto/' : ''}${currencyPair.toLowerCase()}`;
    }

    detached() {
        this.loadingSubscription?.dispose();
        this.adminViewSubscription?.dispose();
        this.tradeUrlUpdatedSubscriber?.dispose();
        this.widthSubscriber?.dispose();
        this.pageLoadedSubscriber?.dispose();
        this.currencyChangeSubscription?.dispose();
        this.skeletonSubscription?.dispose();
        this.timer?.au.controller.viewModel.stop();
    }

    bind() {
        this.buildContent();
    }

    handleEventSubscriptions() {
        this.adminViewSubscription = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
        });

        this.widthSubscriber = this.eventAggregator.subscribe(SizeChangedEvent, (payload: ISizeEvent) => {
            this.width = payload.width;
            this.setCurrentSize();
        });

        this.tradeUrlUpdatedSubscriber = this.eventAggregator.subscribe('trade-url-updated', async () => {
            this.isDropdownOpen = false;
            const path = window.location.pathname;
            const parts = path.replace(/-/g, '').split('/').filter(Boolean);
            const [type, subtype, exchange] = parts;
            this.exchangeInfo = {
                type,
                subtype: path.includes('swap') ? subtype : null,
                exchange: path.includes('swap') ? exchange : subtype
            };

            if (this.exchangeInfo.exchange) this.key = `TRADE_${this.exchangeInfo.exchange.replace(/-/g, '').toUpperCase()}`;
            else this.key = `TRADE_${this.exchangeInfo.type.toUpperCase()}${this.exchangeInfo.subtype ? '_' + this.exchangeInfo.subtype.toUpperCase() : ''}`;

            try {
                const routeData = await this.getRouteData();
                this.updateMetaTags(routeData?.title, routeData?.metaDescription);
                this.suffix = this.key ?? this.suffix;
                this.buildContent();
                this.mostPopularCurrencies.forEach(currency => currency.redirectToRoute = this.getCurrencyRedirectToRoute(currency));
            } catch (e) {
                console.log(e);
            } finally {
                this.pageLoading = false;
            }
        });

        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });

        this.currencyChangeSubscription = this.eventAggregator.subscribe(CurrencyChangedEvent, payload => {
            this.currencyBase = payload.currencySelected;
            this.timer?.au.controller.viewModel.resetCountdown();
        });
    }

    async getRouteData() {
        if (!this.tradeCategory?.gameForNav) {
            return null;
        }
        const slugToSearch = this.exchangeInfo.exchange ? this.exchangeInfo.exchange
            : this.exchangeInfo.subtype ? `${this.exchangeInfo.type}/${this.exchangeInfo.subtype}`
                : this.exchangeInfo.type;
        const routeData = this.tradeCategory.gameForNav.find(x => x.slugName === slugToSearch.replace('to', '-to-'));
        if (routeData) this.pageContentArea = await this.pageContentAreaService.getByPageIdAndGame(this.pageId, slugToSearch?.toUpperCase());
        return routeData;
    }

    @computedFrom('exchangeInfo.type', 'exchangeInfo.subtype')
    get extraCryptoPriceCardRoute() {
        return `${this.exchangeInfo.type}${this.exchangeInfo.subtype ? '/' + this.exchangeInfo.subtype : ''}`;
    }

    updateMetaTags(title: string, metaDescription: string) {
        const titleContent = this.getTitleContent(title);
        document.title = titleContent;
        this.setMetaTagAttributes([
            { selector: 'meta[property="og:title"]', attributeName: 'og:title', content: titleContent },
            { selector: 'meta[name="twitter:title"]', attributeName: 'twitter:title', content: titleContent }
        ]);

        const metaDefaultDescription = 'Buy and Sell Cryptocurrency from ChicksX, the leading cryptocurrency provider serving global markets including Canada and the United States.';
        const descriptionContent = metaDescription || metaDefaultDescription;

        this.setMetaTagAttributes([
            { selector: 'meta[name="description"]', attributeName: 'description', content: descriptionContent },
            { selector: 'meta[property="og:description"]', attributeName: 'og:description', content: descriptionContent },
            { selector: 'meta[name="twitter:description"]', attributeName: 'twitter:description', content: descriptionContent }
        ]);
    }

    setMetaTagAttributes(metaTags: { selector: string, attributeName: string, content: string }[]) {
        metaTags.forEach(({ selector, attributeName, content }) => this.updateMetaTag(selector, attributeName, content));
    }

    getTitleContent(title: string): string {
        return title ? `${title} ${this.router.parent.titleSeparator} ${this.router.parent.title}` : this.router.parent.title;
    }

    updateMetaTag(selector: string, attributeName: string, content: string) {
        let metaTag = document.querySelector(selector);
        if (!metaTag) {
            metaTag = document.createElement('meta');
            metaTag.setAttribute('name', attributeName);
            document.head.appendChild(metaTag);
        }
        metaTag.setAttribute('content', content);
    }

    buildContent() {
        this.listContent = [
            {
                iconPath: 'account_balance_wallet',
                header: `${this.suffix}_LC_FIRST_TITLE`,
                body: `${this.suffix}_LC_FIRST_PARAGRAPH`,
                alt: 'Balance wallet icon',
                alternativeHeader: `${this.defaultSuffix}_LC_FIRST_TITLE`,
                alternativeBody: `${this.defaultSuffix}_LC_FIRST_PARAGRAPH`
            },
            {
                iconPath: 'shopping_cart',
                header: `${this.suffix}_LC_SECOND_TITLE`,
                body: `${this.suffix}_LC_SECOND_PARAGRAPH`,
                alt: 'Shopping cart icon',
                alternativeHeader: `${this.defaultSuffix}_LC_SECOND_TITLE`,
                alternativeBody: `${this.defaultSuffix}_LC_SECOND_PARAGRAPH`
            },
            {
                iconPath: 'monetization_on',
                header: `${this.suffix}_LC_THIRD_TITLE`,
                body: `${this.suffix}_LC_THIRD_PARAGRAPH`,
                alt: 'Monetization icon',
                alternativeHeader: `${this.defaultSuffix}_LC_THIRD_TITLE`,
                alternativeBody: `${this.defaultSuffix}_LC_THIRD_PARAGRAPH`
            }
        ];

        this.infoQuadCardsData = [
            {
                iconPath: `${this.suffix}_QC_FIRST_IMAGE`,
                title: `${this.suffix}_QC_FIRST_TITLE`,
                text: `${this.suffix}_QC_FIRST_PARAGRAPH`,
                alternativeIconPath: `${this.defaultSuffix}_QC_FIRST_IMAGE`,
                alternativeTitle: `${this.defaultSuffix}_QC_FIRST_TITLE`,
                alternativeText: `${this.defaultSuffix}_QC_FIRST_PARAGRAPH`
            },
            {
                iconPath: `${this.suffix}_QC_SECOND_IMAGE`,
                title: `${this.suffix}_QC_SECOND_TITLE`,
                text: `${this.suffix}_QC_SECOND_PARAGRAPH`,
                alternativeIconPath: `${this.defaultSuffix}_QC_SECOND_IMAGE`,
                alternativeTitle: `${this.defaultSuffix}_QC_SECOND_TITLE`,
                alternativeText: `${this.defaultSuffix}_QC_SECOND_PARAGRAPH`
            },
            {
                iconPath: `${this.suffix}_QC_THIRD_IMAGE`,
                title: `${this.suffix}_QC_THIRD_TITLE`,
                text: `${this.suffix}_QC_THIRD_PARAGRAPH`,
                alternativeIconPath: `${this.defaultSuffix}_QC_THIRD_IMAGE`,
                alternativeTitle: `${this.defaultSuffix}_QC_THIRD_TITLE`,
                alternativeText: `${this.defaultSuffix}_QC_THIRD_PARAGRAPH`
            },
            {
                iconPath: `${this.suffix}_QC_FOURTH_IMAGE`,
                title: `${this.suffix}_QC_FOURTH_TITLE`,
                text: `${this.suffix}_QC_FOURTH_PARAGRAPH`,
                alternativeIconPath: `${this.defaultSuffix}_QC_FOURTH_IMAGE`,
                alternativeTitle: `${this.defaultSuffix}_QC_FOURTH_TITLE`,
                alternativeText: `${this.defaultSuffix}_QC_FOURTH_PARAGRAPH`
            }
        ];

        const regex = /([a-zA-Z]+)to([a-zA-Z]+)/;
        const match = (this.exchangeInfo.exchange)?.match(regex);
        if (match) {
            this.contentDropdownKey = `TRADE_${ this.exchangeInfo.exchange.toUpperCase() }_DROPDOWN_CONTENT`;
        } else {
            this.contentDropdownKey = `TRADE_${ this.exchangeInfo.type.toUpperCase() }${ this.exchangeInfo.subtype ? '_' + this.exchangeInfo.subtype.toUpperCase() : '' }_DROPDOWN_CONTENT`;
        }
    }

    shouldRenderContent(keys: string | string[]): boolean {
        keys = [keys].flat();

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const finalKey = key.includes(this.suffix) ? key : `${this.suffix}${key}`;
            const defaultKey = finalKey.replace(this.suffix, this.defaultSuffix);
            const foundContent = this.pageContentArea?.find(x => x.key === finalKey) || this.pageContentArea?.find(x => x.key === defaultKey);
            const isContentImage = (finalKey.includes('IMAGE') || defaultKey.includes('IMAGE')) && foundContent?.imagePath;

            if (!foundContent || !foundContent?.markup && !isContentImage) {
                return false;
            }
        }
        return true;
    }

    setCurrentSize = (): string => this.deviceType = this.width <= PhoneBreakpoint ? 'MOBILE' : this.width <= DesktopBreakpoint ? 'TABLET' : 'DESKTOP';

    async countdownFinished() {
        try {
            const [mostPopularCurrencies, currencyRates] = await Promise.all([
                this.currencyService.getMultipleCurrencyStatsByCode(this.currencies),
                this.coinbaseService.getExchangeRates(this.currencyBase?.code)
            ]);

            if (!currencyRates?.data?.rates) {
                console.error('Failed to fetch currency rates');
                return;
            }

            this.currencyRates = currencyRates.data.rates;
            if (this.mostPopularCurrencies && this.currencyRates && this.currencyBase) {
                this.mostPopularCurrencies.forEach(currency => {
                    const foundCurrency = mostPopularCurrencies.find((c: CurrencyCryptoPriceCard) => c.code === currency.code);
                    currency.priceUSD = foundCurrency.price;
                    currency.price = foundCurrency.price * this.currencyRates[this.currencyBase.code];
                    currency.base = this.currencyBase;
                    currency.redirectToRoute = this.getCurrencyRedirectToRoute(currency);
                });
            }
        } catch (e) {
            console.error(e);
            this.autoRestart = false;
        }
    }

    observeCurrencies() {
        this.mostPopularCurrencies.forEach(currency => {
            this.bindingEngine.propertyObserver(currency, 'ready').subscribe(() => {
                this.checkAllCardsLoaded();
            });
        });
    }

    checkAllCardsLoaded() {
        this.allCardsLoaded = !this.pageLoading && this.mostPopularCurrencies.every(currency => currency.ready);
    }

    goToLearnMore(e: string) {
        const { currentInstruction } = this.router;
        if (currentInstruction.config.name === 'home') {
            this.router.navigate(e);
            return;
        }
        this.router.parent.navigate(e);
    }

    async goToBuySell(crypto: string) {
        this.customerService.saveCurrencyPriceCode(crypto);
    }
}
