// Module
var code = `<template>
    <section id="cookies" class="legal-page policy \${pageLoading ? 'skeleton-policy' : ''}">
        <div class="header font-bold text-light tos-body">
            <div class="header-title text-start title-container title-cookies" skeleton-loading="is-loading.bind:pageLoading;">Cookies Policy</div>
        </div>
        <div class="tos-main-content text-medium">
            <div class="tos-body">
                <cx-page-content-area key.bind="'COOKIES_LAST_UPDATED'" class="text-end styles-text last-update text-content-first" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_SUMMARY_OF_COOKIES_TITLE'" class="text-title font-bold summary-title" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_SUMMARY_OF_COOKIES_CONTENT'" class="text-content summary-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_EXAMPLES_SESSION_COOKES_TITLE'" class="text-title font-bold example-title" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_SESSION_COOKIES_CONTENT'" class="text-content session-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_PREFERENCE_COOKIES_TITLE'" class="text-title font-bold preference-title" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_PREFERENCE_COOKIES_CONTENT'" class="text-content preference-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_SECURITY_COOKIES_TITLE'" class="text-title font-bold security-title" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_SECURITY_COOKIES_CONTENT'" class="text-content security-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_ADVERSITING_COOKIES_TITLE'" class="text-title font-bold adversiting-title" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
                <cx-page-content-area key.bind="'COOKIES_ADVERSITING_COOKIES_CONTENT'" class="text-content adversiting-content" skeleton-loading="is-loading.bind:pageLoading;">
                </cx-page-content-area>
            </div>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>
`;
// Exports
export default code;