// Module
var code = `<template>
    <div id="cx-payment-method-item" class="position-relative">
        <div class="payment-method-item-content \${ itemLeft ? 'payment-method-item-content--left' : itemRight ? 'payment-method-item-content--right' : '' } position-absolute w-100 d-block d-lg-none">
            <span id="\${ deleteSwipeIconElId }" show.bind="itemLeft" class="material-icons text-white payment-method-item-icon--left">
                delete
            </span>
            <span id="\${ editSwipeIconElId }" show.bind="itemRight" class="material-icons text-white payment-method-item-icon--right">
                edit
            </span>
        </div>
        <div
            id="\${ paymentMethodItemElId }"
            class="w-100 position-absolute payment-method-item \${ isDeletingPaymentMethod ? 'payment-method-item--deleting' : '' } \${ isDeletingInProgress ? 'opacity-25' : 'cursor-pointer' }"
            click.delegate="selectPaymentMethod(paymentMethod)"
            touchstart.delegate="!isDeletingPaymentMethod ? onTouchStart(\$event) : null & debounce:100"
            touchend.delegate="!isDeletingPaymentMethod ? onTouchEnd(\$event) : null & debounce:100"
            touchmove.delegate="!isDeletingPaymentMethod ? onTouchMove(\$event) : null"
        >
            <div class="\${ isLastItem ? '' : 'payment-method-item-border' } payment-method-item-content h-100">
                <!-- Payment method UI if they use cards -->
                <div if.bind="paymentMethod.paymentMethod.cardInfo.type">
                    <div show.bind="!isDeletingPaymentMethod" class="d-flex flex-row align-items-center">
                        <div class="payment-method-img justify-content-center text-center d-inline-flex align-items-center">
                            <img
                                if.bind="paymentMethod.paymentMethod.cardInfo.type"
                                src="\${ handleCardImage(paymentMethod.paymentMethod.cardInfo.type) }"
                                alt="payment method"
                                loading="lazy"
                            >
                            <span else class="material-icons-outlined text-purple">image_not_supported</span>
                        </div>
                        <div class="col ms-3">
                            <div class="d-flex flex-row align-items-center">
                                <div class="col text-regular text-black align-self-center d-flex flex-row align-items-center">
                                    <span class="text-truncate">\${ handleCardName(paymentMethod.paymentMethod.cardInfo.type) }</span>                                    
                                    <span class="opacity-75"> **** \${ paymentMethod.paymentMethod.cardInfo.lastDigits }</span>
                                </div>
                                <div class="expired-date text-tiny text-black font-light \${ isCardExpiredOrClose(paymentMethod) === 1 ? 'text-red' : '' } \${ isCardExpiredOrClose(paymentMethod) === 2 ? 'text-yellow' : '' }">
                                    \${ getformattedExpiryDate(paymentMethod) }
                                </div>
                                <div class="action-buttons-container align-items-center">
                                    <div class="action-button d-flex align-items-center justify-content-center" click.delegate="deleteCard(paymentMethod)">
                                        <span class="material-icons text-purple text-small-plus">delete</span>
                                    </div>
                                    <div class="action-button d-flex align-items-center justify-content-center" click.delegate="editCard(paymentMethod)">
                                        <span class="material-icons text-purple text-small-plus">edit</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div show.bind="isDeletingPaymentMethod" class="d-flex flex-row align-items-center">
                        <div class="payment-method-img justify-content-center text-center d-inline-flex align-items-center">
                            <img
                                if.bind="paymentMethod.paymentMethod.cardInfo.type"
                                src="\${ handleCardImage(paymentMethod.paymentMethod.cardInfo.type) }"
                                alt="payment method"
                                loading="lazy"
                            >
                            <span else class="material-icons-outlined text-purple">image_not_supported</span>
                        </div>
                        <span class="text-tiny text-darkergray font-regular me-2 text-delete ms-3">Are you sure you want to delete this payment method?</span>
                        <div class="delete-item-container d-flex flex-row">
                            <div class="action-button action-button--deleting d-flex align-items-center justify-content-center" click.delegate="confirmDeletion(paymentMethod)">
                                <span class="material-icons text-purple text-small-plus">check</span>
                            </div>
                            <div class="action-button action-button--deleting d-flex align-items-center justify-content-center" click.delegate="cancelDeletion()">
                                <span class="material-icons text-purple text-small-plus">close</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Payment method UI if they have payoutable info -->
                <div if.bind="paymentMethod.paymentMethod.payoutableInfo">
                    <div show.bind="!isDeletingPaymentMethod" class="method-container">
                        <div class="payment-method-img fixed-size justify-content-center text-center d-inline-flex align-items-center">
                            <img
                                if.bind="paymentMethod.paymentMethod.imagePath"
                                src="\${baseAwsEndpoint}\${paymentMethod.paymentMethod.imagePath}"
                                alt="\${paymentMethod.paymentMethod.name} icon"
                                loading="lazy"
                            >
                            <span else class="material-icons-outlined text-purple">image_not_supported</span>
                        </div>
                        <div class="method-info-container">
                            <p class="text-regular text-black">
                                <span class="text-regular text-black">\${paymentMethod.paymentMethod.name}</span>
                                <span class="opacity-75 text-truncate"> - \${payoutableDisplayInfo}</span>
                            </p>
                            <div class="action-buttons-container action-button-white no-hide align-items-center">
                                <div class="action-button d-flex align-items-center justify-content-center" click.delegate="deleteCard(paymentMethod)">
                                    <span class="material-icons text-white text-small-plus">delete</span>
                                </div>
                                <div class="action-button d-flex align-items-center justify-content-center" click.delegate="editCard(paymentMethod)">
                                    <span class="material-icons text-white text-small-plus">edit</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div show.bind="isDeletingPaymentMethod" class="d-flex flex-row align-items-center py-1">
                        <span class="material-icons text-darkergray text-large-plus-static me-2">delete</span>
                        <span class="text-xs text-darkergray font-light ms-1 me-2">Are you sure you want to delete?</span>
                        <div class="delete-item-container end-align d-flex flex-row">
                            <div class="action-button action-button--deleting d-flex align-items-center justify-content-center" click.delegate="confirmDeletion(paymentMethod)">
                                <span class="material-icons text-purple text-small-plus">check</span>
                            </div>
                            <div class="action-button action-button--deleting d-flex align-items-center justify-content-center" click.delegate="cancelDeletion()">
                                <span class="material-icons text-purple text-small-plus">close</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Payment method UI if they have bank account info -->
                <div if.bind="paymentMethod.paymentMethod.bankAccountInfo">
                    <div show.bind="!isDeletingPaymentMethod" class="method-container">
                        <div class="payment-method-img fixed-size justify-content-center text-center d-inline-flex align-items-center">
                            <cds-icon
                                if.bind="paymentMethod.paymentMethod.bankAccountInfo && !focusIn"
                                icon.bind="paymentMethod.paymentMethod.bankAccountInfo.bankIcon"
                                alt="payment method image"
                                static-icon.bind="paymentMethod.paymentMethod.bankAccountInfo.bankStaticIcon"
                                color="#310FA2"
                            ></cds-icon>
                            <span else class="material-icons-outlined text-purple">image_not_supported</span>
                        </div>
                        <div class="method-info-container">
                           <div class="d-flex flex-row align-items-center justify-content-between w-100">
                                <div class="col text-regular text-black align-self-center d-flex flex-row align-items-center">
                                    <span class="bank-name text-truncate">\${ paymentMethod.paymentMethod.bankAccountInfo.bankName }</span>
                                </div>
                                <div class="expired-date text-black text-regular ms-2">
                                    \${ bankAccountDisplayInfo }
                                </div>                
                                <div class="action-buttons-container align-items-center">
                                    <div class="action-button d-flex align-items-center justify-content-center" click.delegate="deleteCard(paymentMethod)">
                                        <span class="material-icons text-purple text-small-plus">delete</span>
                                    </div>
                                    <div class="action-button d-flex align-items-center justify-content-center" click.delegate="editCard(paymentMethod)">
                                        <span class="material-icons text-purple text-small-plus">edit</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div show.bind="isDeletingPaymentMethod" class="d-flex flex-row align-items-center py-1">
                        <span class="material-icons text-darkergray text-large-plus-static me-2">delete</span>
                        <span class="text-xs text-darkergray font-light ms-1 me-2">Are you sure you want to delete?</span>
                        <div class="delete-item-container end-align d-flex flex-row">
                            <div class="action-button action-button--deleting d-flex align-items-center justify-content-center" click.delegate="confirmDeletion(paymentMethod)">
                                <span class="material-icons text-purple text-small-plus">check</span>
                            </div>
                            <div class="action-button action-button--deleting d-flex align-items-center justify-content-center" click.delegate="cancelDeletion()">
                                <span class="material-icons text-purple text-small-plus">close</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Payment method UI if they DON'T use cards -->
                <div 
                    if.bind="!paymentMethod.paymentMethod.cardInfo.type && !paymentMethod.paymentMethod.payoutableInfo && !paymentMethod.paymentMethod.bankAccountInfo"
                    class="d-flex flex-row align-items-center"
                >
                    <div >
                        <div class="payment-method-img justify-content-center text-center d-inline-flex align-items-center">
                            <img
                                if.bind="paymentMethod.paymentMethod.imagePath"
                                class="\${ selectedPaymentMethod.paymentMethod.reference === 'google-pay-direct' ? 'additional-img-size' : ''}"
                                src="\${ baseAwsEndpoint }\${ paymentMethod.paymentMethod.imagePath }" alt="payment method" loading="lazy"
                            >
                            <span else class="material-icons-outlined text-purple">image_not_supported</span>
                        </div>
                    </div>
                    <div 
                        class="w-100 ms-3 text-regular align-self-center text-black text-truncate" 
                        mouseover.trigger="hoverPaymentMethod(\$event) & debounce:100"
                        mouseout.trigger="hoverOutPaymentMethod(\$event) & debounce:100"
                        >
                        <div class="w-100 animated-text text-truncate" ref="paymentMethodNameRef">
                            \${ paymentMethod.paymentMethod.name }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;