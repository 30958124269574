// Module
var code = `<template>
    <div id="cx-billing-address" ref="googleAutoCompleteContainer" class="w-100 d-flex align-items-end flex-column \${parentClass}">
        <div class="text-start align-self-start" if.bind="showBillingOption" click.delegate="updateBillingAddress()">
            <div class="billing-option text-small text-decoration-none d-flex justify-content-start">
                <span class="d-none d-sm-block my-auto cursor-pointer" if.bind="!showPlacesInput">
                    Update billing address
                </span>
                <span class="d-block d-sm-none my-auto cursor-pointer" if.bind="!showPlacesInput">
                    Update
                </span>
                <span class="my-auto cursor-pointer" if.bind="showPlacesInput && showFields">
                    Lookup Address
                </span>
                <span class="my-auto cursor-pointer" if.bind="showPlacesInput && !showFields">
                    Enter Address Manually
                </span>
            </div>
        </div>
        <form autocomplete="off" submit.delegate="\$event.preventDefault()" class="w-100">
            <div 
                show.bind="!showFields && showPlacesInput"
                class="d-flex align-items-center input-billing position-relative w-100 \${ showGreenCheckMark ? 'input-billing--success' : showErrorCheckMark ? 'input-billing--error' : '' }"
            >
                <mdc-text-field
                    outlined
                    autocomplete="on"
                    ref="billingAddressInput"
                    value.bind="autocompletedAddress"
                    label.bind="'Billing address'"
                    class="input-password using-label w-100"
                    keyup.delegate="checkBillingAddressAutocompleteIfEmpty() & debounce:300"
                    id="billing-autocompleted"
                    focusin.delegate="focusHandle(\$event)"
                    change.delegate="zipUpdatedOnKeyPress(\$event)"
                ></mdc-text-field>
                <span show.bind="showGreenCheckMark" class="circle-icon p-0 check text-large position-absolute material-icons-outlined" alt="green check">check_circle</span>
                <span show.bind="showErrorCheckMark" class="circle-icon p-0 position-absolute error text-large material-icons" alt="error">error_outline</span> 
                <div id="google-autocomplete-places-dropdown" class="position-absolute w-100"></div>
            </div>
            <div class="mb-3 text-left" show.bind="showFields">
                <div class="first-row position-relative d-flex align-items-center input-billing \${ showGreenCheckMark ? 'input-billing--success' : showErrorCheckMark ? 'input-billing--error' : '' }">
                    <mdc-text-field
                        outlined
                        autocomplete="street-address"
                        value.two-way="billing.street & validate"
                        label.bind="'Address'"
                        id="billing-street"
                        class="input-password using-label"
                        keyup.delegate="addressUpdatedOnKeyPress(\$event)"
                        change.delegate="zipUpdatedOnKeyPress(\$event)"
                    ></mdc-text-field>
                    <mdc-text-field
                        outlined
                        autocomplete="country-name"
                        value.two-way="billing.countryName & validate"
                        label.bind="'Country'"
                        id="billing-country"
                        class="input-password using-label country-input"
                        keyup.delegate="countryUpdatedOnKeyPress(\$event)"
                    ></mdc-text-field>
                </div>
                <div class="position-relative second-row d-flex align-items-center input-billing \${ showGreenCheckMark ? 'input-billing--success' : showErrorCheckMark ? 'input-billing--error' : '' }">
                    <mdc-text-field
                        outlined
                        autocomplete="on"
                        value.two-way="billing.city & validate"
                        label.bind="'City'"
                        id="billing-city"
                        class="input-password using-label"
                        keyup.delegate="zipUpdatedOnKeyPress(\$event, true)"
                    ></mdc-text-field>                        
                    <mdc-text-field
                        outlined
                        autocomplete="on"
                        value.two-way="billing.state & validate"
                        label.bind="billing.countryCode === 'CA' ? 'Province' : 'State'"
                        id="billing-state"
                        class="input-password using-label"
                        keyup.delegate="zipUpdatedOnKeyPress(\$event, true)"
                    ></mdc-text-field>
                    <mdc-text-field
                        outlined
                        autocomplete="postal-code"
                        value.two-way="billing.zip & validate"
                        label.bind="'ZIP Code'"
                        keyup.delegate="zipUpdatedOnKeyPress(\$event, true)"
                        focusout.delegate="zipUpdatedOnKeyPress(\$event)"
                        id="postal-code"
                        class="input-password using-label zip-input"
                    ></mdc-text-field>
                </div>
            </div>
        </form>
    </div>
</template>
`;
// Exports
export default code;