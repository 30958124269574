import { FrameworkConfiguration } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';

export function configure(config: FrameworkConfiguration): void {
    config.globalResources([
        PLATFORM.moduleName('./elements/cx-touch-target/cx-touch-target'),
        PLATFORM.moduleName('./elements/coming-soon/coming-soon'),
        PLATFORM.moduleName('./elements/cx-button/cx-button'),
        PLATFORM.moduleName('./elements/cx-trade-intro/cx-trade-intro'),
        PLATFORM.moduleName('./elements/cx-paragraph-title/cx-paragraph-title'),
        PLATFORM.moduleName('./elements/cx-paragraph-image/cx-paragraph-image'),
        PLATFORM.moduleName('./elements/cx-page-content-area/cx-page-content-area'),
        PLATFORM.moduleName('./elements/cx-auth-form/cx-auth-form'),
        PLATFORM.moduleName('./elements/cx-customer-portal-navigation/cx-customer-portal-navigation'),
        PLATFORM.moduleName('./elements/cx-validator-progress/cx-validator-progress'),
        PLATFORM.moduleName('./elements/footer/footer'),
        PLATFORM.moduleName('./elements/navbar/navbar'),
        PLATFORM.moduleName('./elements/cx-veriff/cx-veriff'),
        PLATFORM.moduleName('./elements/cx-user-dropdown/cx-user-dropdown'),
        PLATFORM.moduleName('./elements/cx-line/cx-line'),
        PLATFORM.moduleName('./elements/cx-phone-input/cx-phone-input'),
        PLATFORM.moduleName('./elements/cx-dialog/cx-dialog'),
        PLATFORM.moduleName('./elements/cx-faq-dialog/cx-faq-dialog'),
        PLATFORM.moduleName('./elements/cx-avatar-dialog/cx-avatar-dialog'),
        PLATFORM.moduleName('./elements/cx-status-tag/cx-status-tag'),
        PLATFORM.moduleName('./elements/cx-pseudo-table/cx-pseudo-table'),
        PLATFORM.moduleName('./elements/cx-dialog/cx-dialog'),
        PLATFORM.moduleName('./elements/get-started/get-started'),
        PLATFORM.moduleName('./elements/cx-form-get-started/cx-form-get-started'),
        PLATFORM.moduleName('./elements/cx-payment-method-selector/cx-payment-method-selector'),
        PLATFORM.moduleName('./elements/cx-progress-bar/cx-progress-bar'),
        PLATFORM.moduleName('./elements/cx-timer/cx-timer'),
        PLATFORM.moduleName('./elements/cx-currency-selector/cx-currency-selector'),
        PLATFORM.moduleName('./elements/cx-amount-currency-selector/cx-amount-currency-selector'),
        PLATFORM.moduleName('./elements/cx-currency-text-input-selector/cx-currency-text-input-selector'),
        PLATFORM.moduleName('./elements/cx-auth-purchase-flow/cx-auth-purchase-flow'),
        PLATFORM.moduleName('./elements/cx-notification-bell/cx-notification-bell'),
        PLATFORM.moduleName('./elements/cx-notification/cx-notification'),
        PLATFORM.moduleName('./elements/cx-checkbox-select/cx-checkbox-select'),
        PLATFORM.moduleName('./elements/cx-id-selfie-verification-progress/cx-id-selfie-verification-progress'),
        PLATFORM.moduleName('./elements/cx-wallet-input/cx-wallet-input'),
        PLATFORM.moduleName('./elements/cx-faq-category-card/cx-faq-category-card'),
        PLATFORM.moduleName('./elements/cx-faq-question/cx-faq-question'),
        PLATFORM.moduleName('./elements/cx-text-input/cx-text-input'),
        PLATFORM.moduleName('./elements/cx-support-issue-input/cx-support-issue-input'),
        PLATFORM.moduleName('./elements/cx-pagination/cx-pagination'),
        PLATFORM.moduleName('./elements/cx-custom-title/cx-custom-title'),
        PLATFORM.moduleName('./elements/cx-chat-view/cx-chat-view'),
        PLATFORM.moduleName('./elements/cx-chat-message/cx-chat-message'),
        PLATFORM.moduleName('./elements/cx-gallery-dialog/cx-gallery-dialog'),
        PLATFORM.moduleName('./elements/cx-pdf-viewer/cx-pdf-viewer'),
        PLATFORM.moduleName('./elements/cx-exchange-pair/cx-exchange-pair'),
        PLATFORM.moduleName('./elements/cx-cash-in-mail/cx-cash-in-mail'),
        PLATFORM.moduleName('./elements/cx-document-upload/cx-document-upload'),
        PLATFORM.moduleName('./elements/cx-payment-information/cx-payment-information'),
        PLATFORM.moduleName('./elements/cx-tracking-progress/cx-tracking-progress'),
        PLATFORM.moduleName('./elements/cx-address-input/cx-address-input'),
        PLATFORM.moduleName('./elements/cx-dropdown/cx-dropdown'),
        PLATFORM.moduleName('./elements/cx-newsletter-prompt/cx-newsletter-prompt'),
        PLATFORM.moduleName('./elements/blog/cx-sticky-post/cx-sticky-post'),
        PLATFORM.moduleName('./elements/cx-amount-payment-selector/cx-amount-payment-selector'),
        PLATFORM.moduleName('./elements/cx-delivery-selector/cx-delivery-selector'),
        PLATFORM.moduleName('./elements/cx-cash-in-person/cx-cash-in-person'),
        PLATFORM.moduleName('./elements/cx-meetup-combo/cx-meetup-combo'),
        PLATFORM.moduleName('./elements/blog/cx-posts-cards/cx-posts-cards'),
        PLATFORM.moduleName('./elements/cx-places-autocomplete-input/cx-places-autocomplete-input'),
        PLATFORM.moduleName('./elements/cx-hero/cx-hero'),
        PLATFORM.moduleName('./elements/cx-learn-more-card/cx-learn-more-card'),
        PLATFORM.moduleName('./elements/cx-crypto-price-card/cx-crypto-price-card'),
        PLATFORM.moduleName('./elements/cx-currency-dropdown/cx-currency-dropdown'),
        PLATFORM.moduleName('./elements/cx-currency-pill/cx-currency-pill'),
        PLATFORM.moduleName('./elements/cx-live-rates/cx-live-rates'),
        PLATFORM.moduleName('./elements/cx-breadcrumbs/cx-breadcrumbs'),
        PLATFORM.moduleName('./elements/cx-file-input/cx-file-input'),
        PLATFORM.moduleName('./elements/blog/cx-blog-search/cx-blog-search'),
        PLATFORM.moduleName('./elements/cx-footer-dropdown/cx-footer-dropdown'),
        PLATFORM.moduleName('./elements/cx-footer-section/cx-footer-section'),
        PLATFORM.moduleName('./elements/cx-footer-menu/cx-footer-menu'),
        PLATFORM.moduleName('./elements/cx-input-search/cx-input-search'),
        PLATFORM.moduleName('./elements/cx-user-avatar/cx-user-avatar'),
        PLATFORM.moduleName('./elements/cx-credit-card-form/cx-credit-card-form'),
        PLATFORM.moduleName('./elements/cx-credit-card-input/cx-credit-card-input'),
        PLATFORM.moduleName('./elements/cx-payment-method-item/cx-payment-method-item'),
        PLATFORM.moduleName('./elements/cx-billing-address/cx-billing-address'),
        PLATFORM.moduleName('./elements/cx-coupon-input/cx-coupon-input'),
        PLATFORM.moduleName('./elements/cx-dropdown-content/cx-dropdown-content'),
        PLATFORM.moduleName('./elements/cx-nav-preorder/cx-nav-preorder'),
        PLATFORM.moduleName('./elements/cx-cycle-list/cx-cycle-list'),
        PLATFORM.moduleName('./elements/cx-nav-preorder-mobile/cx-nav-preorder-mobile'),
        PLATFORM.moduleName('./elements/cx-page-content-image/cx-page-content-image'),
        PLATFORM.moduleName('./elements/cx-filter/cx-filter'),
        PLATFORM.moduleName('./elements/cx-filter/cx-filter'),
        PLATFORM.moduleName('./elements/cx-order-row/cx-order-row'),
        PLATFORM.moduleName('./elements/cx-circular-progress/cx-circular-progress'),
        PLATFORM.moduleName('./elements/cx-search-drawer/cx-search-drawer'),
        PLATFORM.moduleName('./elements/cx-infinite-scroll/cx-infinite-scroll'),
        PLATFORM.moduleName('./elements/cx-card/cx-card'),
        PLATFORM.moduleName('./elements/cx-veriff-progress/cx-veriff-progress'),
        PLATFORM.moduleName('./elements/cx-info-banner/cx-info-banner'),
        PLATFORM.moduleName('./elements/cx-list-item/cx-list-item'),
        PLATFORM.moduleName('./elements/cx-table/cx-table'),
        PLATFORM.moduleName('./elements/cx-rate-of-change/cx-rate-of-change'),
        PLATFORM.moduleName('./elements/cx-payoutable-form/cx-payoutable-form'),
        PLATFORM.moduleName('./elements/cx-try-again-box/cx-try-again-box'),
        PLATFORM.moduleName('./elements/cx-order-section/cx-order-section'),
        PLATFORM.moduleName('./elements/cx-referral-row/cx-referral-row'),
        PLATFORM.moduleName('./elements/cx-password-input/cx-password-input'),
        PLATFORM.moduleName('./elements/cx-confirm-dialog/cx-confirm-dialog'),
        PLATFORM.moduleName('./elements/cx-row-buttons/cx-row-buttons'),
        PLATFORM.moduleName('./elements/cx-faq-question-section/cx-faq-question-section'),
        PLATFORM.moduleName('./elements/cx-language-slider/cx-language-slider'),
        PLATFORM.moduleName('./elements/cx-lookup/cx-lookup'),
        PLATFORM.moduleName('./elements/cx-select/cx-select'),
        PLATFORM.moduleName('./elements/cx-check-container/cx-check-container'),
        PLATFORM.moduleName('./elements/cx-tooltip/cx-tooltip'),
        PLATFORM.moduleName('./elements/cx-section/cx-section'),
        PLATFORM.moduleName('./elements/cx-number-arrows/cx-number-arrows'),
        PLATFORM.moduleName('./elements/cx-seo-dropdown/cx-seo-dropdown'),
        PLATFORM.moduleName('./elements/cx-no-results/cx-no-results'),
        PLATFORM.moduleName('./elements/cx-seo-content/cx-seo-content'),
        PLATFORM.moduleName('./elements/cx-language-currency-selector/cx-language-currency-selector'),
        PLATFORM.moduleName('./elements/cx-bank-transfer-form/cx-bank-transfer-form'),
        PLATFORM.moduleName('./elements/cx-bottom-sheet-body/cx-bottom-sheet-body', 'elements'),
        PLATFORM.moduleName('./elements/cx-bottom-sheet-body/cx-tooltip-mobile-container/cx-tooltip-mobile-container', 'elements'),
        PLATFORM.moduleName('./elements/cx-accordion-menu/cx-accordion-menu'),
        PLATFORM.moduleName('./elements/vgs-form/vgs-form'),
        PLATFORM.moduleName('./elements/google-pay-checkout-form/google-pay-checkout-form'),
        PLATFORM.moduleName('./elements/apple-pay-checkout-form/apple-pay-checkout-form'),

        // Value Converters
        PLATFORM.moduleName('./value-converters/file-list-to-array'),
        PLATFORM.moduleName('./value-converters/blob-to-url'),
        PLATFORM.moduleName('./value-converters/entity-status'),
        PLATFORM.moduleName('./value-converters/text-truncate'),
        PLATFORM.moduleName('./value-converters/capitalize-text'),
        PLATFORM.moduleName('./value-converters/clearation-timeout'),
        PLATFORM.moduleName('./value-converters/number-formatter'),
        PLATFORM.moduleName('./value-converters/currency-formatter'),
        PLATFORM.moduleName('./value-converters/splice-array'),
        PLATFORM.moduleName('./value-converters/remove-word'),
        PLATFORM.moduleName('./value-converters/date-formatter'),
        PLATFORM.moduleName('./value-converters/package-status'),
        PLATFORM.moduleName('./value-converters/phone-formatter'),
        PLATFORM.moduleName('./value-converters/default-currency'),
        PLATFORM.moduleName('./value-converters/fullname-formatter'),
        PLATFORM.moduleName('./value-converters/support-ticket-status'),
        PLATFORM.moduleName('./value-converters/query-params'),
        PLATFORM.moduleName('./value-converters/text-starts-with'),
        PLATFORM.moduleName('./value-converters/camel-case'),
        PLATFORM.moduleName('./value-converters/rate-currency-formatter'),
        PLATFORM.moduleName('./value-converters/fiat-currency-formatter'),
        PLATFORM.moduleName('./value-converters/in'),
        PLATFORM.moduleName('./value-converters/rate-of-change'),
        PLATFORM.moduleName('./value-converters/date-only'),
        PLATFORM.moduleName('./value-converters/call'),
        PLATFORM.moduleName('./value-converters/decimal-to-percentage'),
        PLATFORM.moduleName('./value-converters/pascal-spacing-formatter'),
        PLATFORM.moduleName('./value-converters/site-strings'),
        PLATFORM.moduleName('./value-converters/language-route'),
        PLATFORM.moduleName('./value-converters/icon-path'),
        PLATFORM.moduleName('./value-converters/thousand-separator'),

        // Bindings
        PLATFORM.moduleName('./binding-behaviors/async-binding'),

        // Custom Attributes
        PLATFORM.moduleName('./custom-attributes/hex-to-filter'),
        PLATFORM.moduleName('./custom-attributes/skeleton-loading'),
        PLATFORM.moduleName('./custom-attributes/receive-tooltip-content')
    ]);
}
