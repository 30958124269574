export class BanksHelper {
    popularBanksValidations = new Map<string, { regex: RegExp | RegExp[], errorMessage: string }>([
        ['001', {
            regex: /^[0-9]{7}$/,
            errorMessage: 'Bank of Montreal account number must be 7 digits long.'
        }],
        ['002', {
            regex: [/^[0-9]{7}$/, /^[0-9]{12}$/],
            errorMessage: 'Scotiabank account number must be either 7 or 12 digits long.'
        }],
        ['003', {
            regex: /^[0-9]{7}$/,
            errorMessage: 'Royal Bank of Canada account number must be 7 digits long.'
        }],
        ['004', {
            regex: /^[0-9]{7}$/,
            errorMessage: 'TD Canada Trust account number must be 7 digits long.'
        }],
        ['006', {
            regex: /^[0-9]{7}$/,
            errorMessage: 'National Bank of Canada account number must be 7 digits long.'
        }],
        ['016', {
            regex: [/^10[0-9]{3}$/, /^[0-9]{9}$/, /^[0-9]{12}$/],
            errorMessage: 'HSBC Bank of Canada transit number must begin with 10XXX and account number must be either 9 or 12 digits long.'
        }],
        ['614', {
            regex: /^[0-9]{10}$/,
            errorMessage: 'Tangerine account number must be 10 digits long.'
        }],
        ['815', {
            regex: /^[0-9]{7}$/,
            errorMessage: 'Desjardins account number must be 7 digits long. The last digit on your cheque, the verification digit, is part of your 7 digit account number.'
        }],
        ['828', {
            regex: /^[0-9]{5,12}$/,
            errorMessage: 'Central 1 account number must be 5 to 12 digits long. This account number appears on your cheque after the institution number (828).'
        }]
    ]);

    validateCanadianBankAccount(institutionNumber: string, transitNumber: string, accountNumber: string): { isValid: boolean, errorMessage: string } {
        const institutionNumberRegex = /^\d{3}$/;
        const transitNumberRegex = /^\d{5}$/;
        const accountNumberRegex = /^\d{7,12}$/;

        const isInstitutionNumberValid = institutionNumberRegex.test(institutionNumber);
        const isTransitNumberValid = transitNumberRegex.test(transitNumber);

        let isAccountNumberValid = false;
        let errorMessage = '';
        if (this.popularBanksValidations.has(institutionNumber)) {
            const bankValidation = this.popularBanksValidations.get(institutionNumber);
            if (Array.isArray(bankValidation.regex)) {
                isAccountNumberValid = bankValidation.regex.some((regex) => regex.test(accountNumber));
            } else {
                isAccountNumberValid = bankValidation.regex.test(accountNumber);
            }
            errorMessage = bankValidation.errorMessage;
        } else {
            isAccountNumberValid = accountNumberRegex.test(accountNumber);
            errorMessage = 'Invalid account number.';
        }

        return { isValid: isInstitutionNumberValid && isTransitNumberValid && isAccountNumberValid, errorMessage };
    }

}

// Uncomment icons when loaded in the cds library.
export const BanksMdIcons = new Map([
    ['amex_bank_of_canada', 'amex'],
    // ['bank_of_america', 'bank_of_america'],
    // ['bank_of_america_national_association', 'bank_of_america_national_association'],
    // ['bank_of_canada', 'bank_of_canada'],
    // ['bank_of_china', 'bank_of_china'],
    // ['bank_of_montreal', 'bank_of_montreal'],
    // ['the_bank_of_new_york_mellon', 'the_bank_of_new_york_mellon'],
    // ['the_bank_of_nova_scotia', 'the_bank_of_nova_scotia'],
    // ['banque_laurentienne_du_canada', 'banque_laurentienne_du_canada'],
    // ['banque_nationale_du_canada', 'banque_nationale_du_canada'],
    // ['barclays_bank_of_canada', 'barclays_bank_of_canada'],
    // ['bnp_paribas', 'bnp_paribas'],
    // ['bank_of_china_toronto_branch', 'bank_of_china_toronto_branch'],
    // ['canadian_imperial_bank_of_commerce', 'canadian_imperial_bank_of_commerce'],
    // ['canadian_tire_bank', 'canadian_tire_bank'],
    // ['canadian_western_bank', 'canadian_western_bank'],
    // ['capital_one_bank_(canada_branch)', 'capital_one_bank_(canada_branch)'],
    // ['cidel_bank_canada', 'cidel_bank_canada'],
    // ['citibank_canada', 'citibank_canada'],
    // ['citibank,_na', 'citibank,_na'],
    // ['comerica_bank', 'comerica_bank'],
    // ['concentra_bank', 'concentra_bank'],
    // ['cs_alterna_bank', 'cs_alterna_bank'],
    // ['ctbc_bank_corp', 'ctbc_bank_corp'],
    // ['china_construction_bank_toronto_br', 'china_construction_bank_toronto_br'],
    // ['deutsche_bank_ag', 'deutsche_bank_ag'],
    // ['digital_commerce_bank', 'digital_commerce_bank'],
    ['equitable_bank', 'equitable_bank'],
    // ['first_commercial_bank', 'first_commercial_bank'],
    // ['first_nations_bank_of_canada', 'first_nations_bank_of_canada'],
    // ['habib_canadian_bank', 'habib_canadian_bank'],
    // ['haventree_bank', 'haventree_bank'],
    // ['hsbc_bank_canada', 'hsbc_bank_canada'],
    // ['home_bank', 'home_bank'],
    // ['icici_bank_canada', 'icici_bank_canada'],
    // ['industrial&commercial_bank_of_china', 'industrial&commercial_bank_of_china'],
    // ['jp_morgan_bank_canada', 'jp_morgan_bank_canada'],
    // ['jpmorgan_chase_bank,_national_assoc', 'jpmorgan_chase_bank,_national_assoc'],
    // ['keb_hana_bank_canada', 'keb_hana_bank_canada'],
    // ['manulife_bank_of_canada', 'manulife_bank_of_canada'],
    // ['mizuho_bank_ltd,_canada_branch', 'mizuho_bank_ltd,_canada_branch'],
    // ['motus_bank', 'motus_bank'],
    // ['mufg_bank,_ltd,_canada_branch', 'mufg_bank,_ltd,_canada_branch'],
    // ['mega_international_commercial_bank_c', 'mega_international_commercial_bank_c'],
    // ['peoples_bank_of_canada', 'peoples_bank_of_canada'],
    // ['pnc_bank_canada_branch', 'pnc_bank_canada_branch'],
    // ['presidents_choice_bank', 'presidents_choice_bank'],
    // ['royal_bank_of_canada', 'royal_bank_of_canada'],
    // ['sbi_canada_bank', 'sbi_canada_bank'],
    // ['shinhan_bank_canada', 'shinhan_bank_canada'],
    // ['societe_generale_(canada_branch)', 'societe_generale_(canada_branch)'],
    // ['state_street', 'state_street'],
    // ['sumitomo_mitsui_banking_corporation_can', 'sumitomo_mitsui_banking_corporation_can'],
    // ['the_toronto-dominion_bank', 'the_toronto-dominion_bank'],
    // ['tangerine_bank', 'tangerine_bank'],
    // ['ubs_bank', 'ubs_bank'],
    // ['united_overseas_bank_limited', 'united_overseas_bank_limited'],
    // ['vancity_community_investment_bank', 'vancity_community_investment_bank'],
    // ['versabank', 'versabank'],
    // ['wealth_one_bank_of_canada', 'wealth_one_bank_of_canada'],
    // ['wells_fargo_bank_na_canadian_branch', 'wells_fargo_bank_na_canadian_branch']
]);

