import './cx-bank-transfer-form.scss';
import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { Helper } from 'resources/helpers/helper';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';
import { BankCatalogService } from 'services/bank-catalog-service';
import { CdsIcon } from '@chicksgroup/web-components';
import { BankCatalogBranchResponse, BankCatalogNoBranchesResponse, BankCatalogResponse } from 'services/models/bank-catalog/bank-catalog';
import { BanksHelper, BanksMdIcons } from 'resources/helpers/banks-helper';
import { ToastService } from 'services/toast-service';
import { ToastType } from 'services/models/toast';
import { BankAccountInfo, PaymentMethodWebsite } from 'services/models/purchase-flow/exchange';
import { BankAccountChangedEvent } from 'resources/constants';
import { EventAggregator } from 'aurelia-event-aggregator';
import { CdsAutocomplete, ICdsAutocompleteItem } from '@chicksgroup/web-components/dist';
import { deepCopyObj } from 'resources/helpers/objects-helper';

@autoinject()
export class CxBankTransferForm {
    constructor(
        private bankCatalogService: BankCatalogService,
        private siteStringsValueConverter: SiteStringsValueConverter,
        private helper: Helper,
        private banksHelper: BanksHelper,
        private toastService: ToastService,
        private eventAggregator: EventAggregator
    ) { }

    @bindable showBankTransferForm: boolean;
    @bindable paymentMethod: PaymentMethodWebsite;
    @bindable banks: BankCatalogNoBranchesResponse[] = [];
    @bindable trendingBanks: BankCatalogNoBranchesResponse[] = [];
    @bindable allBanks: BankCatalogNoBranchesResponse[] = [];
    inputState: 'normal' | 'valid' | 'invalid' | 'loading' = 'normal';
    editMode: boolean = false;
    accountNumber: string = '';
    branch: BankCatalogBranchResponse = null;
    bank: BankCatalogResponse = null;
    bankAccountTimeout: NodeJS.Timeout;
    bankAccountValidTimeout: NodeJS.Timeout;
    selectBankDropdownRef: CdsAutocomplete;
    selectTransitNumberDropdownRef: CdsAutocomplete;

    async attached() {
        if (this.paymentMethod?.paymentMethod?.bankAccountInfo) {
            await this.fillBankAccountInfo(this.paymentMethod.paymentMethod.bankAccountInfo);
        } else {
            await this.setFirstBank();
        }
        this.banks = deepCopyObj(this.banks);
        this.bank = deepCopyObj(this.bank);
    }

    async fillBankAccountInfo(bankAccount: BankAccountInfo) {
        this.editMode = true;
        this.initializeBankDropdowns(bankAccount.bankName, bankAccount.bankIcon);
        const bankNoBranches = this.banks.find(bank => bank.id === bankAccount.bankId);
        if (bankNoBranches) {
            this.bank = await this.bankCatalogService.getBankById(bankNoBranches.id);
            if (this.bank) {
                this.branch = this.bank.branches?.find(branch => branch.id === bankAccount.bankBranchId) || null;
                this.accountNumber = bankAccount.accountNumber;
                this.selectTransitNumberDropdownRef.setValue(bankAccount.transitNumber);
            } else {
                console.error('Bank not found after fetching by ID');
            }
        } else {
            console.error('Bank not found in the list of banks');
        }
    }

    async setFirstBank() {
        if (!this.banks || this.banks.length === 0) return;
        const firstBank = this.banks[0];
        this.bank = await this.bankCatalogService.getBankById(firstBank.id);
        if (this.bank && this.bank.branches) {
            this.initializeBankDropdowns(this.bank.name, this.getBankIcon(this.bank)[1]);
        } else {
            console.error('Bank or bank branches not found');
        }
    }

    initializeBankDropdowns(name: string, icon: string) {
        this.selectBankDropdownRef.setValue(name);
        this.selectBankDropdownRef.setLeadingIcon(icon);
        this.selectTransitNumberDropdownRef.setValue('');
    }

    detached() {
        clearTimeout(this.bankAccountTimeout);
        this.inputState = 'normal';
        this.bank = null;
        this.branch = null;
        this.accountNumber = null;
        this.editMode = false;
        this.backToPaymentMethods();
    }

    @computedFrom('banks', 'trendingBanks', 'allBanks')
    get itemsBanks(): ICdsAutocompleteItem[] {
        const items: ICdsAutocompleteItem[] = [];

        if (this.trendingBanks?.length) {
            items.push({
                label: this.siteStringsValueConverter.toView('CX_TRENDING', 'Trending'),
                value: 'trending',
                items: this.trendingBanks.map(bank => this.createBankAutocompleteItem(bank)),
                selected: false
            });
        }

        if (this.allBanks?.length) {
            items.push({
                label: this.siteStringsValueConverter.toView('CX_ALL', 'All'),
                value: 'all',
                items: this.allBanks.map(bank => this.createBankAutocompleteItem(bank)),
                selected: false
            });
        }

        return items;
    }

    @computedFrom('bank', 'trendingTransitNumbers', 'allTransitNumbers')
    get itemsTransitNumber(): ICdsAutocompleteItem[] {
        if (!this.bank) return [];

        const items: ICdsAutocompleteItem[] = [];

        if (this.trendingTransitNumbers?.length) {
            items.push({
                label: this.siteStringsValueConverter.toView('CX_TRENDING', 'Trending'),
                value: 'trending',
                items: this.trendingTransitNumbers.map(branch => this.createBranchAutocompleteItem(branch)),
                selected: false
            });
        }

        if (this.allTransitNumbers?.length) {
            items.push({
                label: this.siteStringsValueConverter.toView('CX_ALL', 'All'),
                value: 'all',
                items: this.allTransitNumbers.map(branch => this.createBranchAutocompleteItem(branch)),
                selected: false
            });
        }

        return items;
    }

    createBankAutocompleteItem(bank: BankCatalogNoBranchesResponse): ICdsAutocompleteItem {
        const [isIconStatic, icon] = this.getBankIcon(bank);
        return {
            label: bank.name,
            value: bank.id,
            icon: icon,
            iconColor: 'rgba(49, 15, 162, 1)',
            isIconStatic: isIconStatic,
            selected: this.bank?.id === bank.id
        };
    }

    createBranchAutocompleteItem(branch: BankCatalogBranchResponse): ICdsAutocompleteItem {
        return {
            label: branch.transitNumber,
            value: branch.id,
            selected: this.branch?.id === branch.id
        };
    }

    async itemBankSelected(ev: CustomEvent) {
        this.branch = null;
        this.selectTransitNumberDropdownRef.setValue('');
        this.bank = await this.bankCatalogService.getBankById(ev.detail.value);
    }

    async itemTransitNumberSelected(ev: CustomEvent) {
        this.branch = this.bank.branches.find(branch => branch.id === ev.detail.value);

        if (this.accountNumber) this.accountNumberOnKeyPress();
    }

    getBankIcon(bank: BankCatalogNoBranchesResponse): [boolean, string] {
        const name = bank.name.toLowerCase();
        const icon = name.replace(/\s\((\w+|\w+\\-\d+)\)|\.+/g, '').replace(/\s+|\/+|\\-+/g, '_');

        return [
            BanksMdIcons.has(icon),
            BanksMdIcons.get(icon) || (CdsIcon.isIconInRegistry(icon) ? icon : 'image_not_supported')
        ];
    }

    @computedFrom('bank')
    get trendingTransitNumbers(): BankCatalogBranchResponse[] {
        return this.bank?.branches?.slice(0, 5);
    }

    @computedFrom('bank', 'trendingTransitNumbers')
    get allTransitNumbers() : BankCatalogBranchResponse[] {
        return this.bank?.branches?.filter(x => this.trendingTransitNumbers.every(y => x.id !== y.id));
    }

    backToPaymentMethods() {
        this.showBankTransferForm = false;
    }

    scrollBottomBanks(_ev) {
        //TODO: Implement scroll bottom
    }

    scrollBottomTransitNumber(_ev) {
        //TODO: Implement scroll bottom
    }

    accountNumberOnKeyPress() {
        this.inputState = 'loading';
        clearTimeout(this.bankAccountTimeout);
        this.bankAccountTimeout = setTimeout(async () => {
            clearTimeout(this.bankAccountValidTimeout);
            if (!this.bank || !this.branch || !this.accountNumber) {
                await this.toastService.showToast('Error', 'Please provide valid bank, branch, and account number.', ToastType.ERROR);
                this.inputState = 'normal';
                return;
            }
            const [isIconStatic, icon] = this.getBankIcon(this.bank);
            const bankAccount: BankAccountInfo = {
                institutionNumber: this.bank?.institutionNumber,
                transitNumber: this.branch?.transitNumber,
                accountNumber: this.accountNumber,
                bankIcon: icon,
                bankStaticIcon: isIconStatic,
                bankName: this.helper.toCapitalize(this.bank?.name, 'all'),
                bankId: this.bank?.id,
                bankBranchId: this.branch?.id
            };
            const bankAccountValid = this.banksHelper.validateCanadianBankAccount(bankAccount.institutionNumber, bankAccount.transitNumber, bankAccount.accountNumber);
            this.inputState = bankAccountValid.isValid ? 'valid' : 'invalid';
            if (!bankAccountValid.isValid) {
                await this.toastService.showToast('Error', bankAccountValid.errorMessage, ToastType.ERROR);
                return;
            }
            const paymentMethod = deepCopyObj(this.paymentMethod) ;
            paymentMethod.paymentMethod.bankAccountInfo = bankAccount;

            this.bankAccountValidTimeout = setTimeout(() => {
                if (this.editMode) {
                    this.paymentMethod.paymentMethod.bankAccountInfo = bankAccount;
                } else {
                    this.eventAggregator.publish(BankAccountChangedEvent, paymentMethod);
                }
                this.backToPaymentMethods();
            }, 2000);
        }, 2000);
    }
}
