import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { DateOnlyValueConverter } from 'resources/value-converters/date-only';
import { ProductService } from './product-service';
import { Helper } from 'resources/helpers/helper';
import moment from 'moment';
import { User } from './models/user/user';

@autoinject()
export class SubscriptionService {
    path = 'Subscription';

    constructor(private api: ApiService,
                private productService: ProductService,
                private dateOnlyValueConverter: DateOnlyValueConverter,
                private helper: Helper) {}

    getRoute = (route: string) => `${this.path}${route ? '/' : ''}${route}`;

    get = (route: string) => this.api.doGet(this.getRoute(route));

    getParams = (route: string, params: { [key: string]: string | number}) => this.get(this.helper.toParams(route, params));

    getByUserId = (userId: number) => this.getParams('GetByUserId', { userId });

    getByName = (name: string) => this.getParams('GetByName', { name });

    getChicksVIP = () => this.getByName('Chicks VIP');

    getById = (id: number) => this.get(String(id));

    getProductsBySubscriptionName = (name = 'Chicks VIP') => this.productService.getProductsWithFilter(`${name}`);

    getActiveSubscription = (user: User, checkWithCancel?) => user?.subscriptions?.find(x => {
        const isAfterRenewalDate = this.dateOnlyValueConverter.toView(x.renewalDate) > this.dateOnlyValueConverter.toView(new Date());
        const isSubscribed = x.isSubscribed;

        return checkWithCancel
            ? isAfterRenewalDate && isSubscribed
            : isAfterRenewalDate || isSubscribed;
    });

    hasChicksVipFreeTrial = (user: User) => {
        if (!user) {
            return Promise.resolve(true);
        }
        return this.get('CheckIfUserHasSubscriptionFreeTrial');
    };

    hasSubscription = (user: User, checkWithCancel?) => Boolean(this.getActiveSubscription(user, checkWithCancel));

    getAvailableChicksVipProducts = (user: User) => {
        if (this.hasSubscription(user)) {
            return Promise.resolve();
        }
        return this.getProductsBySubscriptionName()
            .then(chicksVipProducts => {
                chicksVipProducts = chicksVipProducts.filter(x => !x.name.includes('Renewal'));
                return chicksVipProducts.map(x => {
                    x.productId = x.id;
                    x.hasFreeTrial = user ? user.hasFreeTrial : true;
                    if (x.hasFreeTrial) {
                        x.priceAfterTrial = x.price;
                        x.price = 0;
                    }
                    if (x.name.includes('Monthly') || x.hasFreeTrial) {
                        x.validUntil = moment().add(30, 'days');
                    } else {
                        x.validUntil = moment().add(1, 'year');
                    }
                    x.quantity = x.tempQuantity = 1;
                    return x;
                });
            });
    };

    unsubscribeUser = (data) =>
        this.api.doPost(`${this.path}/Unsubscribe`, {
            subscriptionId: data.subscriptionId,
            userId: data.userId,
            reasons: data.reasons,
            unsubscribeOtherReason: data.unsubscribeOtherReason
        });

    updateSubscriber = (data, renewal = false) => {
        const request = {
            subscriptionId: data.subscriptionId,
            userId: data.userId,
            paymentPlan: data.paymentPlan,
            mainPaymentMethodId: data.mainPaymentMethodId,
            backupPaymentMethodId: data.backupPaymentMethodId,
            mainCardLastDigits: data.mainCardLastDigits,
            backupCardLastDigits: data.backupCardLastDigits
        };
        if (renewal) return this.api.doPost(`${this.path}/Subscribe`, request);
        return this.api.doPut(`${this.path}/UpdateSubscriber`, request);
    };

    totalCashbackEarned() {
        return this.get('TotalCashbackEarned');
    }
}
