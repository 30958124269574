import './security-two-fa-settings.scss';
import { autoinject, computedFrom } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { User } from 'services/models/user/user';
import { SignalrService } from 'services/signalr-service';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { CustomerService } from 'services/customer-service';
import { Helper } from 'resources/helpers/helper';
import {
    CashInPersonDetail,
    NuveiAddUPOCreditCardResponse,
    NuveiPaymentDataRequest, OrderDetail, OrderType,
    PaymentMethodWebsite,
    PylonPaymentDataRequest,
    RecentlySavedCC
} from 'services/models/purchase-flow/exchange';
import { observable } from 'aurelia-framework';
import { CurrencyChangedEvent, ForceCurrencyEvent } from 'resources/constants';
import { CurrencyService } from 'services/currency-service';
import { ToastService } from 'services/toast-service';
import { FiatCurrencyFormatValueConverter } from 'resources/value-converters/fiat-currency-formatter';
import { CxVeriff } from 'resources/elements/cx-veriff/cx-veriff';
import { ProductService } from 'services/product-service';
import { baseUrl, websiteShortCode } from 'environment';
import { NuveiService } from 'services/nuvei-service';
import { Order, OrderRequest } from 'services/models/order';
import { Router } from 'aurelia-router';
import { DeliveryMethod } from 'services/models/purchase-flow/delivery-method';
import { DeliveryDateConverter } from 'resources/value-converters/delivery-date';
import { IForceCurrencyEvent } from '../../../../types/events';
import qrcode from 'qrcode-generator';
import { ValidationController, ValidationRules } from 'aurelia-validation';
import { HubConnection } from '@microsoft/signalr';
import { TabletBreakpoint, PhoneBreakpoint } from 'resources/constants';
import { PageContentAreaService } from 'services/page-content-area-service';

@autoinject()
export class SecurityTwoFaSettings {
    userSubscriber;
    currentPasswordStopWatch;
    currentPasswordStopWatch2;
    confirmingToken: boolean;
    qrPlaceholder: HTMLElement;
    showGreenCheckMarkToken: boolean;
    showErrorCheckMarkToken: boolean;
    toastNewPasswordSent: boolean;
    showMiniSpinnerToken: boolean;
    toastTokenSent: boolean;
    firedTokenError: boolean;
    isRequesting: boolean;
    miniSpinnerTokenStopwatch: NodeJS.Timeout;
    successFunction: boolean;

    qrCode: string;
    sharedKey: string;

    convertedBalance:string = '';
    currencySubscription: Subscription;
    currencySymbol:string = '';
    conversionRate: number | null = null;
    usdAmount: number | null;
    smallOrderFee: number | null;
    transactionFee: number | null;
    @observable amount: number | null;
    @observable currentCurrency: string;
    @observable selectedPaymentMethod: PaymentMethodWebsite;
    ccRecentlySaved: RecentlySavedCC | null;
    veriffViewModel: CxVeriff;
    exchange = { trackingNumber: '' };
    userDocumentsList: File[] = [];
    @observable trackingNumberValid = false;

    date: string;
    @observable meetupAddressValid: boolean;
    location: string;
    time: string;
    selectedDelivery: DeliveryMethod;

    private redirectPaymentMethods = ['ideal', 'sofort', 'checkout', 'google-pay-direct', 'apple-pay-direct'];

    // screen
    width: number;
    height: number;
    phone: number;
    tablet: number;
    user: User;

    payEnabled: boolean;
    sizeChanged: Subscription;
    hasTwoFactorEnabled: boolean;
    activating2FA: boolean;
    connection: HubConnection;
    defaultTimeout = 2000;

    tokenFocusInStopWatch: NodeJS.Timeout;
    tokenStopWatch: NodeJS.Timeout;
    tokenStopWatch2: NodeJS.Timeout;
    timeouts: NodeJS.Timeout[];
    token: string;
    firedFunction: boolean;
    disablingToken;
    activated2FA: boolean = false;

    constructor(
        private sessionService: SessionService,
        private customerService: CustomerService,
        private currencyFormatValueConverter: CurrencyFormatValueConverter,
        private currencyService: CurrencyService,
        private notification: ToastService,
        private fiatCurrencyFormatValueConverter: FiatCurrencyFormatValueConverter,
        private productService: ProductService,
        private nuveiService: NuveiService,
        private router: Router,
        private deliveryDateConverter: DeliveryDateConverter,
        private eventAggregator: EventAggregator,
        private helper: Helper,
        private validator: ValidationController,
        private signalRService: SignalrService,
        private pageContentAreaService: PageContentAreaService
    ) { }

    async activate() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.user = await this.sessionService.getProfile();
        this.hasTwoFactorEnabled = await this.customerService.checkIfTwoFactorEnabled();
    }

    attached() {
        this.phone = PhoneBreakpoint;
        this.tablet = TabletBreakpoint;
        this.currencySubscription = this.eventAggregator.subscribe(CurrencyChangedEvent, async(_) => {
            await this.setCurrencySymbol();
        });
        this.width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        this.height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;
        this.handleEventSubscriptions();
        this.signalRService.getSignalRConnection().then((connection: HubConnection) => {
            if (!connection) return;
            this.connection = connection;
            this.connection.on('UserUpdated', (result) => {
                this.user.emailConfirmed = result?.user?.emailConfirmed ?? false;
                this.notification.showToast(undefined, `Thank you ${this.user.firstName}, your email has been verified successfully.`, 'success');
                this.sendTwoFactorAuthenticationRequest();
                this.eventAggregator.publish('user-updated', { user: this.user });
            });
            this.sendTwoFactorAuthenticationRequest();
        });
    }

    detached() {
        this.sizeChanged?.dispose();
    }

     @computedFrom('width')
    get twoFactorTop() {
        return this.width <= this.phone
            ? 'CX_2FA_TOP_MOBILE'
            : 'CX_2FA_TOP';
    }

     handleEventSubscriptions() {
         this.userSubscriber = this.eventAggregator.subscribe('user-updated', payload => this.user = payload.user);
         this.sizeChanged = this.eventAggregator.subscribe('size-changed', payload => this.width = payload.width);
     }

    @computedFrom('hasTwoFactorEnabled', 'width')
     get twoFactorMessage() {
         return !this.hasTwoFactorEnabled
             ? this.width <= this.phone
                 ? 'CX_2FA_MESSAGE_DISABLED_PHONE'
                 : this.width <= this.tablet
                     ? !this.user?.emailConfirmed
                         ? 'CX_2FA_MESSAGE_DISABLED_TABLET_EMAIL_NOT_CONFIRMED'
                         : 'CX_2FA_MESSAGE_DISABLED_TABLET_EMAIL_CONFIRMED'
                     : 'CX_2FA_MESSAGE_DISABLED'
             : this.width <= this.phone
                 ? 'CX_2FA_MESSAGE_ENABLED_MOBILE'
                 : 'CX_2FA_MESSAGE_ENABLED';
     }

    async setCurrencySymbol() {
        const currency = await this.sessionService.getCurrency();
        if (currency !== this.currentCurrency) this.currentCurrency = currency;
        this.currencySymbol = this.currencyFormatValueConverter.getCurrencyPrefix(this.currentCurrency);
    }

    async phoneVerificationUpdated(phoneConfirmed: boolean) {
        if (!phoneConfirmed) {
            this.user.phoneVerified = false;
        }

    }

    async autoTriggerOrder() {
        await this.sessionService.refreshProfile();
    }

    openLivechat(order: Order) {
        if (!window.Intercom) return;
        window.Intercom('showNewMessage', `Hello! I have a question about order #${order.id}`);
    }

    async validatePaymentMethod() {
        if (!this.selectedPaymentMethod?.paymentMethod) return false;

        if (this.selectedPaymentMethod.paymentMethod.usesCard && !this.selectedPaymentMethod.paymentMethod.cardInfo) {
            this.notification.showToast(
                'Payment method error',
                'Missing card information',
                'error'
            );
            return false;
        }

        if (this.usdAmount < this.selectedPaymentMethod.minimum) {
            const formattedAmount = await this.fiatCurrencyFormatValueConverter.toView(this.selectedPaymentMethod.minimum);
            this.notification.showToast(
                'Payment method error',
                `Payment Method Minimum Not Met ${formattedAmount}`,
                'error'
            );
            return false;
        }

        if (this.usdAmount > this.selectedPaymentMethod.maximum) {
            const formattedAmount = await this.fiatCurrencyFormatValueConverter.toView(this.selectedPaymentMethod.maximum);
            this.notification.showToast(
                'Payment method error',
                `Payment Maximum Exceeded ${formattedAmount}`,
                'error'
            );
            return false;
        }

        return true;
    }

    private getTotalBalance() {
        return (this.user.balance ?? 0) + (this.user.pendingBalance ?? 0);
    }

    async finish(order: Order, url: string) {
        if (this.selectedPaymentMethod.paymentMethod.reference.includes('bluesnap')) {
            await this.sessionService.refreshProfile();
        }

        if (!url) return;

        if (this.helper.includesSome(order.paymentMethod.reference, this.redirectPaymentMethods)) {
            this.router.navigate(url);
            return;
        }

        const checkOutWindow = window.open(url);

        if (!checkOutWindow || checkOutWindow.closed || typeof checkOutWindow.closed === 'undefined') {
            this.eventAggregator.publish('banner-updated', { successful: 'warning', text: 'Looks like you have AdBlock enabled. Some functionality may be affected by AdBlock' });
        }
    }

    async buildOrder(): Promise<OrderRequest | null> {
        const productsResponse = await this.productService.getProductsWithFilter('Balance');

        if (!productsResponse?.length) return;

        const product = productsResponse[0];
        let pylonPaymentData: PylonPaymentDataRequest;
        let nuveiPaymentData: NuveiPaymentDataRequest;
        let inPersonDetail: CashInPersonDetail;

        switch (this.selectedPaymentMethod.paymentMethod.reference) {
            case 'pylon': {
                pylonPaymentData = {
                    cardReferenceID: null,
                    baseUrl: baseUrl(),
                };
                break;
            }
            case 'nuvei': {
                let nuveiCardSaved: NuveiAddUPOCreditCardResponse | null = null;
                if (this.ccRecentlySaved) nuveiCardSaved = await this.nuveiService.addCreditCard(this.ccRecentlySaved);

                nuveiPaymentData = {
                    cvv: this.ccRecentlySaved?.cvv,
                    userPaymentOptionId: nuveiCardSaved ? nuveiCardSaved.userPaymentOptionId : parseInt(this.selectedPaymentMethod.paymentMethod.cardInfo.cardId ?? '0')
                };
                break;
            }
            case 'cash-in-person': {
                inPersonDetail = {
                    date: this.date,
                    location: this.location,
                    time: this.time,
                    meetingType: this.getDeliveryDate(this.selectedDelivery)
                };
                break;
            }
        }

        const orderDetail: OrderDetail = {
            cashInPersonDetail: inPersonDetail
        };

        return {
            websiteShortCode: websiteShortCode(),
            totalPrice: this.usdAmount,
            products: [{
                productId: product.id,
                quantity: 1,
                price: this.usdAmount
            }],
            orderDetail,
            easyPostTrackingId: this.exchange.trackingNumber,
            currencyUsed: this.currentCurrency,
            paymentMethodId: this.selectedPaymentMethod.paymentMethodId,
            status: `${this.selectedPaymentMethod.paymentMethod.reference}:created`,
            address: this.ccRecentlySaved?.billingAddress?.address,
            city: this.ccRecentlySaved?.billingAddress?.city,
            state: this.ccRecentlySaved?.billingAddress?.state,
            country: this.ccRecentlySaved?.billingAddress?.country,
            zip: this.ccRecentlySaved?.billingAddress?.zip,
            referralLinkUrl: this.sessionService.getReferralLink(),
            firstName: this.user?.firstName,
            lastName: this.user?.lastName,
            cardLastFour: this.selectedPaymentMethod.paymentMethod.cardInfo?.lastDigits,
            cardType: this.selectedPaymentMethod.paymentMethod.cardInfo?.type,
            pylonPaymentData,
            nuveiPaymentData,
            cardCvv: this.ccRecentlySaved?.cvv,
            orderTypeId: OrderType.TopUp
        };
    }

    getDeliveryDate(value: DeliveryMethod) {
        return this.deliveryDateConverter.toView(value);
    }

    setTransactionFee() {
        if (!this.selectedPaymentMethod) {
            this.transactionFee = this.smallOrderFee = 0;
            return;
        }

        let remainingAmount = this.usdAmount;
        this.smallOrderFee = 0;

        if (remainingAmount > 0) {
            if (remainingAmount < this.selectedPaymentMethod.smallOrderMinimumAmount) this.smallOrderFee = this.selectedPaymentMethod.smallOrderFee;
            remainingAmount += this.smallOrderFee;
            this.transactionFee = parseFloat(((remainingAmount * (this.selectedPaymentMethod.fee / 100)) + this.selectedPaymentMethod.additionalInternalFee).toFixed(2));
            return;
        }
        this.transactionFee = 0;
    }

    async selectedPaymentMethodChanged(newValue: PaymentMethodWebsite) {
        const eventData: IForceCurrencyEvent = {
            currentPaymentMethodSelected: newValue,
            currency: await this.sessionService.getCurrency(),
            forceSelector: true
        };

        this.eventAggregator.publish(ForceCurrencyEvent, eventData);
        this.setTransactionFee();
    }

    async currentCurrencyChanged(newValue: string | null) {
        if (!newValue || newValue === 'USD') this.conversionRate = 1;
        else this.conversionRate = await this.currencyService.getConvertionRateToUSD(newValue);

        if (this.amount) this.usdAmount = this.amount * this.conversionRate;
        if (this.selectedPaymentMethod) this.setTransactionFee();
    }

    async amountChanged(newValue: number | null) {
        if (newValue) this.usdAmount = newValue * this.conversionRate;
        else this.usdAmount = null;

        if (this.selectedPaymentMethod) this.setTransactionFee();
    }

    get tooltipText() {
        if (!this.user) return '';
        return `All balances are stored as USD, so the amount in other currencies will fluctuate. The balance available, which includes the pending balance converted to USD is $${this.getTotalBalance()}.`;
    }

    get balancePendingTooltipText() {
        if (!this.user) return '';
        return `All balances are stored as USD, so the amount in other currencies will fluctuate. $${this.user.pendingBalance} USD can only be withdrawn immediately through PayPal due to the sale of an account(s). After six months your pending balance will retroactively be added to your regular cash balance where you can withdraw in any method. For users who have a profile spend of 10k or more with level 3 verification, this restriction is removed.`;
    }

    @computedFrom('hasTwoFactorEnabled')
    get twoFactorIcon() {
        return !this.user?.emailConfirmed ? 'mail' : this.hasTwoFactorEnabled ? 'lock' : 'content_copy';
    }

    async sendConfirmationEmail() {
        try {
            const sent = await this.customerService.requestEmailConfirmation();
            if (sent) {
                this.user.emailInReview = true;
                await this.sessionService.saveEmailInReview(this.user.emailInReview);
                this.eventAggregator.publish('user-updated', { user: this.user });
                this.notification.showToast(undefined, 'Confirmation email sent.', 'success');
            }
        } catch (e) {
            console.error(e);
        }
    }

    async sendTwoFactorAuthenticationRequest() {
        if (this.activated2FA || !this.user?.emailConfirmed) return;
        try {
            this.activating2FA = true;
            const response = await this.customerService.requestTwoFactorAuthentication();
            this.qrCode = response.authenticatorUri;
            this.sharedKey = response.sharedKey;
            const qr = qrcode(0, 'L');
            qr.addData(this.qrCode);
            qr.make();
            this.qrPlaceholder.innerHTML = qr.createImgTag(2, 2);
            this.handleConfirmingToken();
        } catch (e) {
            console.log(e);
            this.notification.showToast('Error', 'Error trying to activate 2fa. Contact live chat for assistance.', 'error');
        } finally {
            this.activating2FA = false;
            this.activated2FA = true;
        }
    }

    copyQrCode = () => navigator.clipboard.writeText(this.sharedKey);

    async confirmTwoFactorActivation() {
        try {
            const response = await this.customerService.confirmTwoFactorActivation(this.token);
            if (response.success === true) {
                this.successFunction = true;
                this.token = undefined;
                this.confirmingToken = false;
                this.hasTwoFactorEnabled = true;
                this.showMiniSpinnerToken = this.showErrorCheckMarkToken = false;
                this.isRequesting = false;
                this.validator.reset();
                this.notification.showToast('Two-factor authentication is activated.', 'You added an extra layer of security to your account.', 'success');
            } else {
                this.showGreenCheckMarkToken = this.showMiniSpinnerToken = false;
                this.showErrorCheckMarkToken = true;
                this.successFunction = false;
                this.firedTokenError = true;
                this.notification.showToast('Error confirming token.', 'Please check that the token is correct, then try again or contact live chat for assistance.', 'error');
                this.isRequesting = false;
            }
        } catch (e) {
            this.notification.showToast('Error trying to activate 2fa.', 'Contact livechat for assistance.', 'error');
        }
    }

    async removeTwoFactorAuthentication() {
        const response = await this.customerService.removeTwoFactorAuthentication(this.token);
        if (response?.success === true) {
            this.successFunction = true;
            this.token = undefined;
            this.disablingToken = this.hasTwoFactorEnabled = this.showMiniSpinnerToken = this.showErrorCheckMarkToken = this.showGreenCheckMarkToken = this.isRequesting = false;
            this.validator.reset();
            this.notification.showToast(undefined, 'You have disabled your 2FA.', 'success');
            this.handleConfirmingToken();
            return;
        }
        this.showGreenCheckMarkToken = this.showMiniSpinnerToken = this.successFunction = this.isRequesting = false;
        this.showErrorCheckMarkToken = this.firedTokenError = true;
        this.notification.showToast('Error', 'Error Removing 2FA. Please check that the token is correct, then try again or contact live chat for assistance.', 'error');
    }

    async execute2FA() {
        if (!this.disablingToken) await this.confirmTwoFactorActivation();
        else await this.removeTwoFactorAuthentication();
    }

    async checkTokenValidation() {
        this.showMiniSpinnerToken = false;
        this.timeouts = [this.miniSpinnerTokenStopwatch, this.tokenStopWatch, this.tokenStopWatch2, this.tokenFocusInStopWatch];
        this.helper.clearTimeouts(this.timeouts);
        if (this.resetIfNoToken()) return;
        ValidationRules.ensure('token').required().when(_ => this.confirmingToken || this.disablingToken).minLength(6).maxLength(6).on(this);
        const rules = await this.validator.validate();

        if (rules.valid && !this.firedFunction) await this.execute2FA();

        if (this.successFunction) return;
        this.showErrorCheckMarkToken = true;
        if (this.toastTokenSent || this.firedTokenError) return;
        this.notification.showToast('Error', 'Please enter a valid token.', 'error');
    }

    tokenOnFocusIn() {
        this.showGreenCheckMarkToken = this.showErrorCheckMarkToken = this.showMiniSpinnerToken = this.firedFunction = this.firedTokenError = this.toastTokenSent = false;
        this.validator.reset();

        this.tokenFocusInStopWatch = setTimeout(() => {
            if (this.token === undefined) return;
            this.tokenUpdatedOnKeyPress();
        });
    }

    async tokenUpdatedOnKeyPress() {
        this.showGreenCheckMarkToken = this.showErrorCheckMarkToken = this.showMiniSpinnerToken = this.toastTokenSent = false;
        this.timeouts = [this.miniSpinnerTokenStopwatch, this.tokenStopWatch, this.tokenStopWatch2, this.tokenFocusInStopWatch];
        this.helper.clearTimeouts(this.timeouts);
        if (this.resetIfNoToken()) return;
        await this.validator.reset();
        if (this.token === undefined) return;
        this.miniSpinnerTokenStopwatch = setTimeout(() => this.showMiniSpinnerToken = true, 1000);
        this.tokenStopWatch = setTimeout(async () => {
            ValidationRules.ensure('token').required().when(_ => this.confirmingToken || this.disablingToken).minLength(6).maxLength(6).on(this);
            const rules = await this.validator.validate();
            if (rules.valid) {
                this.firedFunction = true;
                await this.execute2FA();
                return;
            }
            await this.validator.reset();
            this.tokenStopWatch2 = setTimeout(async () => {
                ValidationRules.ensure('token').required().when(_ => this.confirmingToken || this.disablingToken).minLength(6).maxLength(6).on(this);
                const rules2 = await this.validator.validate();
                this.showErrorCheckMarkToken = !rules2.valid;
                this.showMiniSpinnerToken = false;
                this.toastTokenSent = true;
                this.notification.showToast('Error', 'Please enter a valid token.', 'error');
            }, this.defaultTimeout);
        }, this.defaultTimeout);
    }

    resetIfNoToken() {
        if (!this.token) this.showMiniSpinnerToken = this.showGreenCheckMarkToken = this.showErrorCheckMarkToken = false;
        return !this.token;
    }

    toggleDisabling = () => this.disablingToken = !this.disablingToken;

    deactivateTwoFactorAuthentication() {
        this.showGreenCheckMarkToken = this.showErrorCheckMarkToken = this.showMiniSpinnerToken = false;
        this.disablingToken = true;
    }

    handleConfirmingToken() {
        this.showGreenCheckMarkToken = this.showErrorCheckMarkToken = this.showMiniSpinnerToken = false;
        this.confirmingToken = true && !this.hasTwoFactorEnabled;
    }
}

