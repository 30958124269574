// Module
var code = `<template>
    <div id="cx-bank-transfer-form">        
        <cx-card class.bind="'card-form'">
            <div class="card-title link-styling cursor-pointer" click.delegate="backToPaymentMethods()">                
                <cds-icon icon="arrow_left"></cds-icon>
                <span>Add bank transfer account</span>
            </div>
            <cds-autocomplete
                ref="selectBankDropdownRef" 
                label.bind="'CX_SELECT_BANK' | siteStrings:'Select bank'"
                trailing-icon="arrow_drop_down"
                data-cy="select-bank-dropdown"
                dropdown-y-offset="4"
                items.bind="itemsBanks"
                toggle-actions-on-hover
                leading-icon-color="rgba(49, 15, 162, 1)"
                input-indicator="background"
                suffixclick.trigger="seeAllBanks(\$event)"
                itemselected.trigger="itemBankSelected(\$event)"
                scrollbottom.delegate="scrollBottomBanks(\$event)"
                disabled.bind="editMode"
                no-vertical-flip
                class="\${ editMode ? 'no-outline' : '' }"
            >
                <cx-no-results slot="no-results-content"
                    message="\${'CX_NO_ENTRIES_TO_SHOW' | siteStrings}"
                ></cx-no-results>
            </cds-autocomplete>
            <cds-autocomplete
                ref="selectTransitNumberDropdownRef" 
                label.bind="'CX_SELECT_TRANSIT_NUMBER' | siteStrings:'Transit number'"
                trailing-icon="arrow_drop_down"
                data-cy="select-transit-number-dropdown"
                dropdown-y-offset="4"
                items.bind="itemsTransitNumber"
                toggle-actions-on-hover
                leading-icon-color="rgba(49, 15, 162, 1)"
                input-indicator="background"
                suffixclick.trigger="seeAllTransitNumber(\$event)"
                itemselected.trigger="itemTransitNumberSelected(\$event)"
                scrollbottom.delegate="scrollBottomTransitNumber(\$event)"
                no-vertical-flip
            >           
                <cx-no-results slot="no-results-content"
                    message="\${'CX_NO_ENTRIES_TO_SHOW' | siteStrings}"
                ></cx-no-results>
            </cds-autocomplete>
            <cds-text-field
                variant="filled"
                type="text"
                label="Account number"
                indicator="border"
                state.two-way="inputState"
                value.two-way="accountNumber"
                label.bind="'CX_BANK_ACCOUNT_NUMBER' | siteStrings:'Account number'"
                keyup.trigger="accountNumberOnKeyPress() & debounce:1000"
            ></cds-text-field>
        </cx-card>
    </div>
</template>
`;
// Exports
export default code;