// Module
var code = `<template>
    <cx-try-again-box
        id="rate-limit"
        use-keys.bind="true"
        main="RATE_LIMITED_TITLE"
        subtitle="RATE_LIMITED_SUBTITLE"
        main-fallback="You're being rate limited"
        subtitle-fallback="You’re going too fast and sending many requests. Please try again in 1 minute. If you are still unable to connect, send us an email to support@chicksgold.com and we’ll get back you in a few minutes."
        button-text="Try again"
        page="Rate Limited"
        button-text="Try again"
        icon="error_outline"
        variant="outlined"
        href.bind="redirectTo"
    >
    </cx-try-again-box>
</template>
`;
// Exports
export default code;