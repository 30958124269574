import './cx-notification-bell.scss';
import { ToastService } from 'services/toast-service';
import { NotificationService } from 'services/notification-service';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { SignalrService } from 'services/signalr-service';
import { Router } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { SimplebarOverride } from 'resources/simplebar_override';
import { OpenedVeriffDialogEvent } from 'resources/constants';
import { FadingFunctionality } from 'resources/extensions/fading_functionality';

@autoinject()
export class CxNotificationBell extends FadingFunctionality {
    private user;
    @bindable pageLoading: boolean = false;
    @bindable selectOptions = [
        {
            selection: 'all',
            active: false
        },
        {
            selection: 'read',
            active: false
        },
        {
            selection: 'unread',
            active: false
        }
    ];

    unreadNotifications;
    notificationsHidden = false;
    clickedOption = [{
        selection: 'all',
        active: true
    }];

    notificationScrollbar : HTMLElement;
    notifications = [];
    unreadNotificationsCount = 0;
    filteredNotifications = [];
    loading: boolean;
    anyIsChecked = false;
    isAnUnread = true;
    notificationSubscriber;
    checkboxSelect;
    checkboxSelectDesktop;
    checkboxSelectMobile;
    isHoverOnBell = false;
    @observable openDropdown = false;
    windowWidth: number;
    sizeSubscription;
    search = '';
    newNotifications = [];
    previousNotifications = [];
    toastSearchSent = false;
    changeSearchWatch;
    timeouts = [];
    simpleBarElement;
    simpleBar;
    clearingSearch = false;
    clickEvent = false;
    showNew = true;
    showOld = true;
    anyNewChecked = false;
    anyOldChecked = false;
    anyNewNotifications = false;
    anyOldNotifications = false;
    noResult = false;
    clickOutside;
    profileClickedSubscription;
    profileIsHovered = false;

    constructor(
        private notificationService: NotificationService,
        private signalrService: SignalrService,
        private toastService: ToastService,
        private router: Router,
        private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter
    ) {
        super();
    }

    async attached() {
        this.user = await this.sessionService.getProfile();
        this.simpleBarElement = document.getElementById('notificationList');
        this.simpleBar = new SimplebarOverride(this.simpleBarElement);
        this.getNotifications();
        this.setupNotificationListener();
        this.handleEventSubscriptions();
        this.setScrollContainer(this.notificationScrollbar);
        this.reinstanceScrollbar();
        this.createScrollEvent();
        document.addEventListener('click', this.handleClickOutside.bind(this));
    }

    detached() {
        this.notificationSubscriber?.dispose();
        this.sizeSubscription?.dispose();
        this.profileClickedSubscription?.dispose();
        document.removeEventListener('click', this.handleClickOutside.bind(this));
    }

    handleEventSubscriptions() {
        this.notificationSubscriber = this.eventAggregator.subscribe('get-unread-notifications', () => {
            this.getNotifications();
        });
        this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.sizeSubscription = this.eventAggregator.subscribe('size-changed', payload => {
            this.windowWidth = payload.width;
        });
        this.profileClickedSubscription = this.eventAggregator.subscribe('rigth-element-is-hovered', payload => {
            if (!Object.keys(payload).includes('profileIsHovered')) return;
            this.profileIsHovered = payload.profileIsHovered;
        });
    }

    handleClickOutside(event) {
        if (this.openDropdown && !this.notificationScrollbar.contains(event.target) && !event.target.closest('#cx-notification-bell')) {
            this.openDropdown = false;
            this.clickEvent = false;
            this.clearSearch();
            this.showFade();
            this.isHoverOnBell = false;
        }
    }

    setActiveOption(clickedOption) {
        this.showNew = this.showOld = false;
        if (clickedOption.length > 0) {
            this.filteredNotifications.forEach(notification => {
                switch (clickedOption[0]?.selection) {
                    case 'all':
                        this.selectOptions.forEach(x => x.active = true);
                        notification.checked = true;
                        notification.show = true;
                        break;
                    case 'read':
                        notification.checked = notification.show = Boolean(notification.isRead);
                        this.showOld = true;
                        break;
                    case 'unread':
                        notification.checked = notification.show = Boolean(!notification.isRead);
                        this.showNew = true;
                        break;
                    case 'none':
                        notification.checked = false;
                        notification.show = false;
                        break;
                    default:
                        notification.show = true;
                        break;
                }
            });
        } else {
            this.filteredNotifications.forEach(notification => {
                notification.show = true;
                notification.checked = false;
            });
        }
        this.setShowElements();
        this.showFade();
    }

    async getUnreadNotifications() {
        this.unreadNotificationsCount = await this.notificationService.getAllUnreadCount(this.user.id);
        this.filteredNotifications = this.notifications;
        this.groupingNotifications();
    }

    async getNotifications() {
        this.loading = true;
        this.notifications = this.user ? await this.notificationService.getLatestNotifications(this.user.id, 30, false) : [];
        this.notifications.forEach(notification => {
            notification.show = true;
        });
        this.filteredNotifications.forEach(notification => {notification.checked = false;});
        this.anyIsChecked = false;
        await this.getUnreadNotifications();
        this.groupingNotifications();
        this.setActiveOption(this.selectOptions.filter(option => option.active));
        this.reinstanceScrollbar();
        this.loading = false;
    }

    async markNotificationsAsRead() {
        if (!this.anyIsChecked) return;
        this.filteredNotifications.forEach(x => x.checked ? x.isRead = this.isAnUnread : null);
        const notificationsId = this.filteredNotifications.filter(item => item.checked).map(x => x.id);
        await this.notificationService.markAsRead(notificationsId, this.isAnUnread);
        this.isAnUnread = true;
        this.eventAggregator.publish('update-all-notifications');
        this.clearOptions();
        await this.getNotifications();
    }

    async markNotificationsAsReadById(id) {
        const currentNotification = this.notifications.find(x => x.id === id);
        const notificationsToMark = this.notifications.filter(x => x.orderId === currentNotification.orderId && x.orderId !== id).map(x => x.id);
        await this.notificationService.markAsRead(notificationsToMark, true);
        this.eventAggregator.publish('update-all-notifications');
        this.clearOptions();
        await this.getNotifications();
    }

    async markNotificationsAsHidden() {
        if (!this.anyIsChecked) return;
        this.notifications.forEach(x => x.isHidden = x.checked);
        const notificationsId = this.filteredNotifications.filter(item => item.checked).map(x => x.id);
        await this.notificationService.markAsHidden(notificationsId);
        this.clearOptions();
        await this.getNotifications();
    }

    async setupNotificationListener() {
        const connection = await this.signalrService.getSignalRConnection();
        connection.on('ShowNotification', (notification) => {
            this.notifications.unshift(notification);
            this.getUnreadNotifications();
            this.toastService.showToast(notification.title, notification.description, 'info');
            this.groupingNotifications();
            this.eventAggregator.publish('update-all-notifications');
        });
    }

    functionChecked(id) {
        this.selectOptions.forEach(x => x.active = false);
        this.filteredNotifications.forEach(notification => {
            if (notification.id === id) {
                notification.checked = !notification.checked;
                if (!notification.checked) {
                    this.checkboxSelectDesktop.au.controller.viewModel.clearExternalCheckbox();
                    this.checkboxSelectMobile.au.controller.viewModel.clearExternalCheckbox();
                }
            }
        });
        this.setShowElements();
        if (!this.anyIsChecked) {
            this.filteredNotifications.forEach(notification => {
                notification.show = true;
                notification.checked = false;
            });
            this.groupingNotifications();
        }
    }

    mouseEventsHandler(event) {
        switch (event.type) {
            case 'click':
                if (this.clickEvent || this.profileIsHovered) {
                    this.openDropdown = !this.openDropdown;
                }
                this.clickEvent = true;
                break;
            case 'mouseenter':
                if (this.pageLoading) return;
                if (!this.openDropdown) {
                    this.clickEvent = false;
                }
                if (this.windowWidth > 996 && !this.clickEvent && !this.profileIsHovered) {
                    this.openDropdown = true;
                }
                break;
            case 'mouseleave':
                if (!this.clickEvent) {
                    this.openDropdown = false;
                }
                break;
            default:
                break;
        }
        if (this.windowWidth <= 996) {
            this.eventAggregator.publish(OpenedVeriffDialogEvent, { openedVeriffDialog: this.openDropdown, triggerDeviceStyling: true });
        }
        this.isHoverOnBell = this.openDropdown;
        if (!this.openDropdown) {
            this.clearSearch();
        }
        this.showFade();
    }

    clearSearch() {
        this.clearingSearch = true;
        this.search = '';
        this.filteredNotifications = this.notifications;
        this.clearOptions();
        this.groupingNotifications();
    }

    groupingNotifications() {
        this.newNotifications = this.filteredNotifications.filter(notification => !this.notificationsHidden && !notification.isRead);
        this.previousNotifications = this.filteredNotifications.filter(notification => !this.notificationsHidden && notification.isRead);
        this.setShowElements();
        this.showFade();
    }

    keyUpHandler(ev) {
        this.toastSearchSent = false;
        this.timeouts = [this.changeSearchWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (ev.key === 'Enter') {
            this.searchNotification();
            return;
        } else {
            this.changeSearchWatch = setTimeout(() => {
                if (this.search) {
                    this.searchNotification();
                } else {
                    this.filteredNotifications = this.notifications;
                    this.clearOptions();
                    this.groupingNotifications();
                }
            }, 1500);
        }

        return true;
    }

    async searchNotification() {
        if (!this.search) {
            this.setActiveOption(this.selectOptions.filter(option => option.active));
            return;
        }

        this.timeouts = [this.changeSearchWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.filteredNotifications = this.notifications;

        const relevantNotifications = this.filteredNotifications.filter(notification => {
            return notification.title?.toLowerCase().includes(this.search.toLowerCase()) || notification.description?.toLowerCase().includes(this.search.toLowerCase());
        });

        if (relevantNotifications.length === 0) {
            if (this.toastSearchSent === false) {
                await this.toastService.showToast('Couldn\'t find anything', 'Try again', 'info');
                this.toastSearchSent = true;
            }
        }

        this.filteredNotifications = relevantNotifications;
        this.groupingNotifications();
    }

    async deleteNotifications() {
        if (!this.anyIsChecked) return;
        const notificationsId = this.filteredNotifications.filter(item => item.checked).map(x => x.id);
        if (notificationsId.length > 0) {
            await this.notificationService.delete(notificationsId);
            this.isAnUnread = true;
            await this.getNotifications();
            this.filteredNotifications.forEach(item => item.checked = false);
            this.eventAggregator.publish('get-unread-notifications');
        }
        this.clearOptions();
        this.groupingNotifications();
    }

    setShowElements() {
        this.anyIsChecked = this.anyChecked(this.filteredNotifications);
        this.anyNewChecked = this.anyChecked(this.newNotifications);
        this.anyOldChecked = this.anyChecked(this.previousNotifications);
        this.isAnUnread = !this.anyIsChecked || this.filteredNotifications.some(notification => !notification.isRead && notification.checked);
        this.anyNewNotifications = this.anyShow(this.newNotifications);
        this.anyOldNotifications = this.anyShow(this.previousNotifications);
        this.noResult = !this.anyShow(this.filteredNotifications);
    }

    anyChecked = (notifications) => notifications.some(n => n.checked);

    anyShow = (notifications) => notifications.some(n => n.show);

    clearOptions() {
        this.checkboxSelectDesktop.au.controller.viewModel.clearOptionsSelection();
        this.checkboxSelectMobile.au.controller.viewModel.clearOptionsSelection();
    }

    showFade() {
        setTimeout(() => {
            const addFade = this.notificationScrollbar?.querySelector('.simplebar-content').clientHeight > 560;
            this.notificationScrollbar.classList.toggle('touching-bottom', !addFade);
        }, 100);
    }

    async scrollBottomReached() {
        this.notificationScrollbar.classList.toggle('touching-bottom', false);
    }

    openDropdownChanged(newValue) {
        this.eventAggregator.publish('rigth-element-is-hovered', { notificationsIsHovered: newValue });
    }
}
