// Module
var code = `<template>
    <div id="blogs">
        <div>
            <div class="container title-page main-container">
                <h1 class="blogs-title" skeleton-loading="is-loading.bind:pageLoading;">Blogs</h1>
            </div>
            <div if.bind="width > desktop" class="d-flex main-container container breadcrumbs flex-column flex-sm-row navigation" skeleton-loading="is-loading.bind:pageLoading;">
                <cx-breadcrumbs breadcrumbs.bind="breadcrumbs"></cx-breadcrumbs>
            </div>
            <div else class="container main-container"></div>
                <div class="back" skeleton-loading="is-loading.bind:pageLoading;"
                >
                <a
                    class="return text-start text-purple font-medium d-inline-flex align-items-center cursor-pointer"
                    href.bind="breadcrumbs[breadcrumbs.length - 2].href"
                >
                    <span class="material-icons back-button rounded-circle me-1">
                        chevron_left
                    </span>
                    \${ 'CX_BACK' | siteStrings }
                </a>
            </div>
                </div>
            <div class="d-flex blogs-input-search container main-container flex-column flex-sm-row" skeleton-loading="is-loading.bind:pageLoading;">
                <cx-blog-search
                    key-up-function.call="keyUpHandler(\$event)"
                    search-value.two-way="searchBlogs"
                    change-select-handler.call="changeSelectHandler(\$event)"
                    class="w-100"
                    selected-index.bind="optionSelected"
                    option-value.bind="options"
                    button-handler.call="clearSearch()"
                ></cx-blog-search>
            </div>

                <div class="d-flex justify-content-center sticky-post-container main-container container" if.bind="!searchBlogs" >
                    <cx-sticky-post class="post-skeleton" blogs.bind="stickyPost" blog-route.bind="blogRoute" skeleton-loading="is-loading.bind:pageLoading;"></cx-sticky-post>
                </div>
        
            <div class="text-center line container main-container">
                <hr class="w-100 main text-skeleton" skeleton-loading="is-loading.bind:pageLoading;" if.bind="!searchBlogs">
            </div>
            <div class="filter-tags flex-column position-relative container main-container" if.bind="blogsResults">
                <ul class="tags-list ps-0 w-100 d-flex justify-content-lg-between blogs" skeleton-loading="is-loading.bind:pageLoading;">
                    <li
                        repeat.for="tag of tagsOptions"
                        class="tag-option cursor-pointer font-bold text-medium text-uppercase tags \${ tag.active ? 'active' : ''}"
                        show.bind="showTagLabel(tag.name)"
                        click.delegate="tagClickHandler(tag)"
                    >\${ tag.keyName | siteStrings:tag.name }</li>
                </ul>
            </div>
            <div class="container d-flex justify-content-center post-cards flex-column breadcrumb-skeleton" skeleton-loading="is-loading.bind:pageLoading;">
                <span if.bind="!blogsResults && searchBlogs" class="no-results text-center">
                    <cx-page-content-area key.bind="CX_BLOG_NO_RESULTS"></cx-page-content-area>
                </span>
                <span if.bind="!blogsResults && searchBlogs" class="instead text-center">
                    <cx-page-content-area key.bind="CX_BLOG_CHECK_THIS"></cx-page-content-area>
                </span>
                <cx-posts-cards blogs.bind="filteredBlogs" blog-route.bind="blogRoute"></cx-posts-cards>
            </div>
            <div class="container text-center pagination-container" >
                <cx-pagination count.two-way="count" page.two-way="page" class="pagination-skeleton" skeleton-loading="is-loading.bind:pageLoading;"
                ></cx-pagination>
            </div>
        </div>
    </div>
</template>`;
// Exports
export default code;