export class ThousandSeparatorValueConverter {
    toView(value, fractionDigits = 2) {
        if (value !== '' && value !== undefined && value !== 0 && value !== '0' && value !== null && parseFloat(value) >= 1) {
            return new Intl.NumberFormat('en-US', { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }).format(value);
        } else {
            return value;
        }
    }

    fromView(value): number {
        if (typeof value !== 'string' || value.trim() === '') {
            return;
        }
        // Remove commas and parse the number
        const numericValue = parseFloat(value.replace(/,/g, ''));
        return isNaN(numericValue) ? NaN : numericValue;
    }
}
