import './cx-accordion-menu.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Helper } from 'resources/helpers/helper';
import { SessionService } from 'services/session-service';

@autoinject()
export class CxAccordionMenu {
    constructor(
        private eventAggregator: EventAggregator,
        private helper: Helper,
        private sessionService: SessionService) { }

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    @bindable navigationItems;
    @bindable clickHandler;
    @bindable viewingAsAdmin;
    menuElement;
    drawer = [];
    gameSearch;
    displayItems = [];
    selectedOptionsStack = [];
    searchText = '';
    resetStackSelectionSubscriber;
    languageExpandable;
    switchElementAttempts = 0;
    parent;
    searchSubscriber;
    accordionMenu;
    switchElementInterval: NodeJS.Timeout;

    attached() {
        this.overrideMdc();
        this.handleEventSubscriptions();
        const viewAdminOption = this.navigationItems?.find(x => x?.name === 'View as Admin');
        if (viewAdminOption && viewAdminOption?.viewingAsAdmin) this.viewAsAdmin(true);
    }

    detached() {
        this.searchSubscriber?.dispose();
    }

    navigationItemsChanged() {
        this.displayItems = JSON.parse(JSON.stringify([...this.navigationItems]));
    }

    handleEventSubscriptions() {
        this.searchSubscriber = this.eventAggregator.subscribe('search-updated', payload => {
            this.searchText = payload.search;
            if (payload.search) {
                this.handleGlobalFilter(payload.search);
            } else {
                this.clearSearch();
            }
        });

        this.resetStackSelectionSubscriber = this.eventAggregator.subscribe('reset-selection-stack', () => {
            this.selectedOptionsStack = [];
        });
    }

    clearSearch() {
        this.gameSearch = null;
        this.displayItems = this.lastSelectedOption()?.items ?? [...this.navigationItems];
    }

    handleDrawerNavigationClick(categoryName, gameShortName) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const drawerToOpen = (document.getElementById(`drawer-${categoryName}-${gameShortName}`) as any).au.controller.viewModel;
        drawerToOpen.open = !drawerToOpen.open;
        const menuContainer = document.querySelector('.menu-container');
        drawerToOpen.mdcFocusTrap.root.classList.contains('mdc-drawer--closing') ? menuContainer.classList.remove('d-none') : menuContainer.classList.add('d-none');
    }

    overrideMdc() {
        const selectArrows = this.accordionMenu.querySelectorAll('.mdc-expandable__header:not(.expandable-nested) .mdc-expandable__dropdown-icon');
        if (selectArrows) {
            selectArrows.forEach((el) => {
                el.innerHTML = '<img class="global-arrow-icon" src="/icons/arrow-white.svg" alt="arrow icon" loading="lazy">';
            });
        }
    }

    handleSelectedOption(option) {
        if (option.customContent && option.name === 'View as Admin') {
            this.viewAsAdmin();
            return;
        }

        if (['CurrencyOptions', 'LanguageOptions'].includes(option.category) || (!option.items || option.link)) {
            this.clickHandler(option);
            this.resetAccordionMenu();
            return;
        }

        this.selectedOptionsStack.push(option);

        this.displayItems = [...option.items];

        if (!this.searchText) return;

        this.handleGlobalFilter(this.searchText);
    }

    handleBackPreviusSelected() {
        this.selectedOptionsStack.pop();
        this.displayItems = this.lastSelectedOption()?.items ?? [...this.navigationItems];

        if (this.searchText) {
            this.handleGlobalFilter(this.searchText);
        }
    }

    lastSelectedOption() {
        if (this.selectedOptionsStack.length === 0) return;
        return this.selectedOptionsStack[this.selectedOptionsStack.length - 1];
    }

    get previusOption() {
        return this.lastSelectedOption();
    }

    resetAccordionMenu() {
        this.selectedOptionsStack = [];
        this.displayItems = [...this.navigationItems];
    }

    isTermialItem(navItem) {
        return typeof navItem.slug === 'object' && navItem.slug;
    }

    optionSelectedHasSeparatos() {
        return this.lastSelectedOption()?.items?.some(x => x.isSeparator);
    }

    handleGlobalFilter(filter) {
        this.displayItems = [];
        const lowerCaseFilter = filter.toLowerCase();
        const lastSelectedName = this.lastSelectedOption()?.name;

        let navigationItemsCloned = this.navigationItems.slice();

        const preselectedCategory = lastSelectedName ? navigationItemsCloned.find(cat => cat.name === lastSelectedName) : null;

        if (preselectedCategory) {
            navigationItemsCloned = new Set([preselectedCategory, ...navigationItemsCloned]);
        }

        for (const category of navigationItemsCloned) {
            const filteredItems = [];
            for (const item of category.items || []) {
                const lowerCaseName = item.name.toLowerCase();
                if (lowerCaseName.startsWith(lowerCaseFilter) && !item.isSeparator) {
                    if (!item.isCategory) {
                        filteredItems.push(item);
                    } else {
                        const subFilteredItems = item.items.filter(subitem =>
                            subitem.name.toLowerCase().startsWith(lowerCaseFilter) && !subitem.isSeparator);
                        filteredItems.push(...subFilteredItems);
                    }
                }
            }

            if (category.name !== lastSelectedName && filteredItems.length > 0) {
                this.displayItems.push(category);
            }

            if (category.isProfile && category.name?.toLowerCase()?.startsWith(lowerCaseFilter)) {
                filteredItems.push(category);
            }

            this.displayItems.push(...filteredItems);
        }
    }

    isSubCategory() {
        return ['Sell', 'More'].includes(this.lastSelectedOption()?.name);
    }

    selectLanguage(language) {
        this.eventAggregator.publish('selected-language', { language, element: this.languageExpandable });
    }

    viewAsAdmin(withInterval = false) {
        if (withInterval) {
            this.switchElementInterval = setInterval(() => {
                const element = document.getElementById('view-switch-responsive') as HTMLInputElement;
                if (!element) return;
                element.checked = !element.checked;
                this.viewingAsAdmin = element.checked;
                clearInterval(this.switchElementInterval);
            }, 250);
            return;
        }
        const element = document.getElementById('view-switch-responsive') as HTMLInputElement;
        if (!element) return;
        element.checked = !element.checked;
        this.viewingAsAdmin = element.checked;
    }

    viewingAsAdminChanged() {
        this.sessionService.saveAdminView(this.viewingAsAdmin);
    }
}
