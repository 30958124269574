import './cx-dialog.scss';
import { bindable, autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { ToastService } from 'services/toast-service';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';

@autoinject()
export class CxDialog {
    bind(bindingContext) {
        this.parent = bindingContext;
    }

    constructor(private router: Router, private sessionService: SessionService, private toastService: ToastService, private clearationTimeoutValueConverter: ClearationTimeoutValueConverter) {
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    @bindable dialog;
    @bindable title: string;
    @bindable contentText: string;
    @bindable buttons: boolean;
    @bindable confirmText: string;
    @bindable showCheckMark;
    @bindable showErrorMark;
    @bindable handleConfirm;
    @bindable sideIcon = '/chicks/chick_looking.svg';
    @bindable sideIconAlt = 'chick icon';
    @bindable textClass: string = 'font-light text-black';
    @bindable htmlContent;
    @bindable onCloseCallback = () => { };
    @bindable triggerCircularLoad = false;

    loading;
    user;
    parent;
    stopWatch1;
    stopWatch2;
    timeouts;
    step = 1;

    async attached() {
        this.user = await this.sessionService.refreshProfile();
    }

    open() {
        this.dialog.open = !this.dialog.open;
    }

    confirm() {
        if (this.handleConfirm) {
            this.handleConfirm();
            return;
        }
        this.handleClosing();
        this.dialog.close();
    }

    handleClosing() {
        this.step = 1;
        this.showErrorMark = this.showCheckMark = false;
        this.onCloseCallback();
    }

    catchSubmit() {
        return;
    }

    @computedFrom('width', 'step', 'title')
    get titleText() {
        return this.title;
    }

    @computedFrom('width', 'step', 'title')
    get displayedContentText() {
        return this.contentText;
    }
}
