import './cx-learn-more-card.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class CxLearnMoreCard {
    @bindable icon;
    @bindable iconAlt;
    @bindable title;
    @bindable paragraph;
    @bindable buttonCaption;
    @bindable href;
    @bindable textToAriaLabel: string;
    @bindable pageLoading;
    adminViewSubscriber;
    viewingAsAdmin;

    constructor(private eventAggregator: EventAggregator) {}

    attached() {
        this.adminViewSubscriber = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
        });
    }

    detached() {
        this.adminViewSubscriber?.dispose();
    }
}
