import './cx-hero.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { WebsiteService } from 'services/website-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Helper } from 'resources/helpers/helper';
import { PageByWebsite } from 'services/models/page/pageByWebsite';
import { PageContentArea } from 'services/models/page/pageContentArea';
import { SessionService } from 'services/session-service';
import { Subscription as RxjsSubscription } from 'rxjs';

@autoinject()
export class CxHero {
    @bindable pages: PageByWebsite[];
    @bindable homePageContentArea: PageContentArea;
    @bindable licenses: string;
    @bindable firstText: string;
    @bindable secondText: string;
    faqRoute: string;
    tradeRoute: string;
    trustPilotStarRating: string;
    amountOfStars: number;
    halfStar: boolean;
    semiSesquiStar: boolean;
    viewingAsAdmin: boolean;
    adminViewSubscriber: Subscription;
    pageLoadedSubscriber: Subscription;
    siteStringsSubscriber: RxjsSubscription;
    isLoading = true;

    constructor(
        private router: Router,
        private websiteService: WebsiteService,
        private eventAggregator: EventAggregator,
        private helper: Helper,
        private sessionService: SessionService
    ) { }

    async attached() {
        try {
            this.handleEventSubscriptions();
            this.helper.addLoadingComponent('cx-hero');
            const pages = await this.websiteService.getPagesByWebsiteShortcode();
            this.faqRoute = pages.find(x => x.name === 'Faq')?.routeName ?? 'faq';
            this.tradeRoute = pages.find(x => x.name === 'Trade')?.routeName ?? 'trade';
        } finally {
            this.helper.validateLoading('cx-hero');
            this.siteStringsSubscriber = this.sessionService.siteStringsSubject.subscribe((pageContent) => {
                if (pageContent?.length) {
                    this.helper.getTrustPilotStarRatingVariables(this, pageContent);
                }
            });
        }
    }

    detached() {
        this.adminViewSubscriber?.dispose();
        this.pageLoadedSubscriber?.dispose();
        this.siteStringsSubscriber?.unsubscribe();
    }

    handleEventSubscriptions() {
        this.adminViewSubscriber = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
        });

        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.isLoading = false;
        });
    }

    navigateToTrade() {
        this.eventAggregator.publish('page-loaded', false);
        this.router.navigate(this.tradeRoute);
    }
}
