import { LanguageRouteValueConverter } from './resources/value-converters/language-route';
import './app.scss';
import { LanguageService } from './services/language-service';
import 'simplebar/dist/simplebar.css';
import { HubConnection } from '@microsoft/signalr';
import { autoinject } from 'aurelia-dependency-injection';
import { bindable, computedFrom, observable } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { PLATFORM } from 'aurelia-pal';
import {
    Router,
    activationStrategy,
    RouterConfiguration,
} from 'aurelia-router';
import { baseUrl } from 'environment';
import isOnline from 'is-online';
import { AuthorizeStep } from 'resources/pipelines/authorize-step';
import { PreActivateStep } from 'resources/pipelines/preactivate-step';
import { IntercomStep } from 'resources/pipelines/intercom-step';
import { MetadataStep } from 'resources/pipelines/metadata-step';
import { ScrollPageStep } from 'resources/pipelines/scroll-page-step';
import { SeoStep } from 'resources/pipelines/seo-step';
import { CapitalizeTextValueConverter } from 'resources/value-converters/capitalize-text';
import { CurrencyService } from 'services/currency-service';
import { HealthService } from 'services/health-service';
import { PageByWebsite } from 'services/models/page/pageByWebsite';
import { Toast } from 'services/models/toast';
import { User } from 'services/models/user/user';
import { SessionService } from 'services/session-service';
import { SignalrService } from 'services/signalr-service';
import { WebsiteService } from 'services/website-service';
import { ToastService } from 'services/toast-service';
import { CustomerService } from 'services/customer-service';
import { SimplebarOverride } from 'resources/simplebar_override';
import { Helper } from 'resources/helpers/helper';
import { TypeExtensions } from 'resources/extensions/type_extensions';
import { IpAddressService } from 'services/ip-address-service';
import { DesktopBreakpoint, OpenedVeriffDialogEvent, SizeChangedEvent } from 'resources/constants';
import { IOpenedVeriffDialogEvent } from 'types/events';
import NotificationManger, { ToastPosition, NotificationType } from '@chicksgroup/notifications';
import { SignalRTrackingService } from 'services/signalr-tracking-service';
import { AuthenticationExtension } from 'resources/extensions/sso_extension';
import { GameForNav, ProductCatWithGame } from 'services/models/game/productCategory';
import { ProductCategoryService } from 'services/product-category-service';
import { BlogService } from 'services/blog-service';
import '@stripe/stripe-js';

const Notifications = new NotificationManger({
    toast: {
        preventDuplicates: true,
        maxAmount: 3,
        timeout: 3000,
        position: ToastPosition.TopRight,
        xMargin: 20,
        yMargin: 40,
        separation: 30
    },
    transformToasts: true,
    snackbar: {
        timeout: 3000
    }
});
import { LangHelper } from 'resources/helpers/lang-helper';
import { Language } from 'services/models/language/language';
import { TradePreactivateStep } from 'resources/pipelines/trade-preactivate-step';
import { PageContentArea } from 'services/models/page/pageContentArea';
import { PageContentAreaService } from 'services/page-content-area-service';
import { Currency } from './services/models/purchase-flow/exchange';
import { ErrorPagesRedirection } from 'resources/extensions/error_redirection_functionality';

@autoinject()
export class App extends AuthenticationExtension {
    @bindable() user: User;
    @observable() activeCurrencies : Currency [] = [];
    private router: Router;
    private healthTimeout: NodeJS.Timeout;
    userSubscriber: Subscription;
    veriffSubscriber: Subscription;
    triggeredConnection: boolean;
    connection: HubConnection;
    width: number;
    height: number;
    routerSubscriber: Subscription;
    hideIntercomIcon: boolean;
    triggeredMonitoring: boolean;
    triggeredBanner: boolean;
    validateRender = false;
    devHelpContainer = false;
    showCopied = false;
    tooltipSubscriber: Subscription;

    firstTimeBreadcrumbCall: boolean;
    untrackUserConnectionSubscriber: Subscription;
    handlePopUpBottomSubscriber: Subscription;
    currencyOptions = [];

    urlParams: URLSearchParams;
    property;
    private scrollbar;
    buttonStyling = 'primary navbar-btn';
    navbarLogo: HTMLElement;
    observeSubscriber: Subscription;
    observing = ['#main-page-host'];
    resizeObserver: ResizeObserver;
    @observable() languages: Language[];
    initialRedirect = false;
    tradeRoutes = ['buy', 'sell', 'swap/fiat', 'swap/crypto'];
    tradesForNav: GameForNav[];
    blogRoute: string;
    homePageContentArea: PageContentArea[];
    pages: PageByWebsite[];
    activeSnackbar;
    isDetached: boolean;

    navbarLoading = true;
    apiHealth: boolean;

    bottomSheet;
    buttonSheetBody;
    isBottomSheetOpen = false;

    constructor(
        private eventAggregator: EventAggregator,
        private pageContentAreaService: PageContentAreaService,
        sessionService: SessionService,
        private signalRService: SignalrService,
        private healthService: HealthService,
        private websiteService: WebsiteService,
        private capitalizeTextValueConverter: CapitalizeTextValueConverter,
        private currencyService: CurrencyService,
        private customerService: CustomerService,
        toastService: ToastService,
        private helper: Helper,
        private typeExtensions: TypeExtensions,
        private ipAddressService: IpAddressService,
        private langHelper: LangHelper,
        private languageService: LanguageService,
        private signalRTrackingService: SignalRTrackingService,
        private languageRouteValueConverter: LanguageRouteValueConverter,
        private productCategoryService: ProductCategoryService,
        private blogService: BlogService,
        private errorPagesRedirection: ErrorPagesRedirection
    ) {
        super(toastService, sessionService);

        eventAggregator.subscribe('toast', (toast: Toast) => {
            // @ts-expect-error Function is private but needs to be called here.
            Notifications._snackbarDetached();
            this.onToastReceived(toast);
        });
        this.typeExtensions.implement(helper);
    }

    handleSiteDown() {
        this.waitForRouter()
            .then(() => {
                if (window.location.pathname === '/rate-limit') {
                    return;
                }
                if (!this.apiHealth) {
                    if (this.sessionService.getSiteDownToastFired()) {
                        setTimeout(() => {
                            return this.redirectToSiteDown();
                        }, 300);
                    }
                    if (document.visibilityState === 'visible') {
                        if (this.sessionService.getSiteDownToastFired()) {
                            this.triggeredBanner = true;
                            return this.redirectToSiteDown();
                        }
                        this.triggeredBanner = true;
                        this.sessionService.setSiteDownToastFired(true);
                        setTimeout(() => {
                            this.redirectToSiteDown();
                        }, 5000);
                    } else {
                        setTimeout(() => {
                            this.redirectToSiteDown();
                        }, 300);
                    }
                } else {
                    this.sessionService.removeSiteDownToastFired();
                }
            });
    }

    async activate() {
        this.handleSiteDown();
        await this.handleSSO();

        this.width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        this.height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

        this.languages = this.sessionService.getDefaultLanguages();

        this.urlParams = new URLSearchParams(window.location.search);

        if (this.sessionService.isLocalStorageLanguage()) {
            await this.loadLanguageFromURL();
        } else {
            this.initialRedirect = true;
        }
        this.pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.sessionService.setPages(this.pages);
        this.blogRoute = this.getRouteNameForPage(this.pages, 'Blog') ?? 'blog';
    }

    async attached() {
        this.user = await this.sessionService.getProfile(true);

        this.handleEventSubscriptions();
        const mainPageHost = document.getElementById('main-page-host');
        this.scrollbar = new SimplebarOverride(mainPageHost);
        this.navbarLogo = document.getElementById('chicksx-nav-logo');
        let tradeNavCategories: ProductCatWithGame;

        await this.pageContentAreaService.getSiteStringsPage(
            this.pages.find((x) => (x.routeName === 'site-strings'))?.id
        );

        await this.pageContentAreaService.getEnglishSiteStrings(
            this.pages.find((x) => (x.routeName === 'site-strings'))?.id
        );

        await this.pageContentAreaService.getByPageId(
            this.pages.find((x) => (x.name = 'Home'))?.id
        );

        [this.languages, tradeNavCategories] = await Promise.all([
            this.languageService.getLanguages(),
            this.productCategoryService.getNavCategory('trade'),
            this.sessionService.getIpInformation()
        ]);

        this.navbarLoading = false;

        this.tradesForNav = tradeNavCategories.gameForNav;

        this.activeCurrencies = await this.currencyService.getActiveCurrenciesByWebsite();

        this.currencyOptions = this.activeCurrencies.filter(
            (e) => e.type === 'F'
        );

        this.handleWindowResizement();
        this.handleSignalRConnection();
        this.handleReferralAndReferrerLinks();

        //Styling & Google Snippet calls
        this.firstTimeBreadcrumbCall = true;
        this.handleBreadcrumbList();
        this.handleReviewSchema();
        this.interComDeviceStyling();
        await this.handleScrollBar();

        //Events & Script calls
        this.handleMonitoringWebsite();
        this.handleGoogleTagManager(this.user);
        this.sessionService.initializeLiveChat();
        this.helper.handlePrerender404(this.router);
        this.handleLinksWithinContent();

        if (
            window.location.href.includes('localhost') ||
            window.location.href.includes('staging.') ||
            window.location.href.includes('dev.')
        ) {
            this.validateRender = true;
        }
        setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).prerenderReady = true;
        }, 10000);

        //Favicon on load
        this.setFavicon();

        //Change favicon when user changes color mode
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => this.setFavicon());

        this.checkPendingNotifications(true);
        if (this.initialRedirect) {
            this.loadURLFromLanguage();
        }

        const hreflangList = this.languages.filter(lang => lang.hrefLang).map(lang => lang.hrefLang);
        const parsedUrl = new URL(window.location.href);
        this.langHelper.populateLinksHref(parsedUrl.pathname, hreflangList);
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
    }

    async loadLanguageFromURL() {
        this.languages = this.sessionService.getDefaultLanguages();

        const hreflangList = this.languages.filter(lang => lang.hrefLang).map(lang => lang.hrefLang);

        const parsedUrl = new URL(window.location.href);
        const pathSegments = parsedUrl.pathname.split('/').filter(Boolean);
        const languageFromURL = pathSegments.length > 0 ? pathSegments[0] : '';

        const currentLanguage = this.sessionService.getLanguage();
        const languageToSet = languageFromURL && hreflangList.includes(languageFromURL) ?
            this.languages?.find(e => e.hrefLang === languageFromURL) :
            this.languages?.find(e => e.hrefLang === 'en');
        if (currentLanguage.hrefLang !== languageToSet.hrefLang) this.sessionService.setLanguage(languageToSet);
    }

    async loadURLFromLanguage() {
        const currentLanguage = await this.sessionService.getLanguage();

        const parsedUrl = new URL(window.location.href);
        const pathSegments = parsedUrl.pathname.split('/').filter(Boolean);
        if (!['en', 'en-us'].includes(currentLanguage.hrefLang)) pathSegments.unshift(currentLanguage.hrefLang);
        this.router?.navigate(pathSegments.join('/'));
    }

    handleLinksWithinContent() {
        setTimeout(() => {
            const links = Array.from(document.getElementsByTagName('a'));
            links.forEach(link => {
                if (link.href.includes(baseUrl())) {
                    link.removeAttribute('rel');
                }
            });
        }, 500);
    }

    setFavicon(): void {
        if (baseUrl().includes('localhost')) return;
        const link = document.querySelector('link[rel="icon"]') || document.createElement('link');
        (link as HTMLLinkElement).rel = 'icon';
        (link as HTMLLinkElement).type = 'image/x-icon';
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            (link as HTMLLinkElement).href = '/favicons/favicon-dark.ico';
        } else {
            (link as HTMLLinkElement).href = '/favicons/favicon.ico';
        }
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    handleEventSubscriptions() {
        this.routerSubscriber = this.eventAggregator.subscribe(
            'router:navigation:success',
            async () => {
                const hreflangList = this.languages.filter(lang => lang.hrefLang).map(lang => lang.hrefLang);
                this.langHelper.populateLinksHref(this.router.currentInstruction.fragment, hreflangList);
                if (this.triggeredBanner) this.router.navigateToRoute('site-down');
                if (!document.querySelector('cds-snackbar')) this.interComDeviceStyling();
                this.handleLinksWithinContent();
                await this.handleScrollBar();
                if (!this.firstTimeBreadcrumbCall) {
                    this.handleBreadcrumbList();
                }
                if (this.user) {
                    this.property = await this.customerService.checkIfUserDeleted();
                    if (this.property?.isDeleted) {
                        this.toastService.showToast('Info', 'Account deleted. As a result, you have been logged out. Sign-up again to log back in, or contact support.', 'info');
                        await this.sessionService.logout();
                    }
                    if (await this.sessionService.verifyIsUserInBlackList(this.user.email, null)) {
                        await this.sessionService.logout();
                    }
                }
            }
        );

        this.observeSubscriber = this.eventAggregator.subscribe('observe-element', async payload => {
            this.observing ??= ['#main-page-host'];
            const element = this.observing?.findIndex(x => x === payload.selector);
            if (element === -1) this.observing?.push(payload.selector);
            this.handleWindowResizement();
        });

        this.userSubscriber = this.eventAggregator.subscribe(
            'user-updated',
            async (payload) => {
                this.user = payload.user;
                if (this.user) {
                    this.ipAddressService.postIp();
                    if (!this.navbarLogo) this.navbarLogo = document.getElementById('chicksx-nav-logo');
                    this.handleGoogleTagManager(this.user);
                    this.handleSignalRConnection();
                    await this.signalRService.trackUserConnection();
                } else if (payload.logout) {
                    this.router.navigateToRoute(await this.languageRouteValueConverter.toView('home', true));
                }
            }
        );

        this.tooltipSubscriber = this.eventAggregator.subscribe(
            'tooltip-shown',
            () => {
                const tooltipEl = document.querySelector(
                    '.mdc-tooltip.mdc-tooltip--shown'
                );
                const tooltipContent = tooltipEl?.querySelector(
                    '.mdc-tooltip__surface.mdc-tooltip__surface-animation'
                );
                const style = tooltipEl?.getAttribute('style');
                if (tooltipEl?.clientHeight > 80 && this.width > 576) {
                    let left = parseFloat(
                        style?.substring(
                            style.indexOf('left:') + 6,
                            style.indexOf('px;', style.indexOf('left:'))
                        )
                    );
                    const maxWidth = 270;
                    left = left - (maxWidth - tooltipEl.clientWidth) / 2;
                    tooltipEl.setAttribute(
                        'style',
                        `${style.substring(
                            0,
                            style.indexOf('left:')
                        )}left: ${left}px; max-width: ${maxWidth}px;`
                    );
                    tooltipContent.setAttribute(
                        'style',
                        `${style.substring(
                            0,
                            style.indexOf('left:')
                        )}left: ${left}px; max-width: ${maxWidth}px;`
                    );
                }
            }
        );

        this.veriffSubscriber = this.eventAggregator.subscribe(
            OpenedVeriffDialogEvent,
            (payload: IOpenedVeriffDialogEvent) => {
                this.hideIntercomIcon = payload.openedVeriffDialog;
                this.hideIntercomBubble(this.hideIntercomIcon, payload.triggerDeviceStyling);
            }
        );

        this.untrackUserConnectionSubscriber = this.eventAggregator.subscribe(
            'untrack-user',
            async () => {
                await this.signalRService.untrackUserConnection();
            }
        );

        this.handlePopUpBottomSubscriber = this.eventAggregator.subscribe('bottom-popup', payload => {
            if (this.width > DesktopBreakpoint) return;
            this.eventAggregator.publish(OpenedVeriffDialogEvent, { openedVeriffDialog: true, triggerDeviceStyling: true });
            this.bottomSheet.open();
            this.buttonSheetBody.setTooltipSheet(payload);
            this.isBottomSheetOpen = true;
        });

        this.eventAggregator.subscribe('size-changed', payload => {
            if (this.isBottomSheetOpen && this.width > DesktopBreakpoint) this.closeBottomSheet();
            setTimeout(() => {
                if (!this.activeSnackbar) return;
                if (payload.width >= DesktopBreakpoint) {
                    // @ts-expect-error Function is private but needs to be called here.
                    Notifications._snackbarDetached();
                    if (this.activeSnackbar.screenTime === 0) {
                        this.eventAggregator.publish('banner-updated', {
                            stateBanner: this.activeSnackbar.toastType,
                            text: this.activeSnackbar.message,
                            clickFunctionName: this.activeSnackbar.clickHereActive,
                            url: this.activeSnackbar.url,
                            actionLabel: this.activeSnackbar.actionLabel
                        });
                        this.activeSnackbar = null;
                    } else {
                        this.onToastReceived(this.activeSnackbar);
                    }
                }
            }, 100);
        });

        this.eventAggregator.subscribe('close-toast', () => {
            this.activeSnackbar = null;
        });

        this.bottomSheet.addEventListener('dismissed', () => this.closeBottomSheet());
    }

    closeBottomSheet() {
        this.isBottomSheetOpen = false;
        this.bottomSheet.dismiss();
        this.eventAggregator.publish(OpenedVeriffDialogEvent, { openedVeriffDialog: false, triggerDeviceStyling: true });
    }

    hideIntercomBubble = (hideIntercomIcon, triggerDeviceStyling) => {
        let intercom = document.getElementById('intercom-style');
        const inactiveIntercom = !intercom ? document.getElementById('intercom-facade-btn') : null;

        if (inactiveIntercom) inactiveIntercom.style.display = 'none';
        if (hideIntercomIcon) {
            intercom = this.helper.createOrSelectElement('style#intercom-style', document.head);
            intercom.innerHTML = '.intercom-launcher-frame { display: none !important; } .intercom-lightweight-app-launcher { display: none !important; } .intercom-messenger-frame { display: none !important; } #intercom-facade-btn { display: none !important; }';
            document.head.append(intercom);
        } else {
            intercom?.remove();
            if (triggerDeviceStyling) this.interComDeviceStyling();
        }
    };

    async handleReferralAndReferrerLinks() {
        const referralLink = this.urlParams.get('ref');
        const platformLink = this.urlParams.get('gclid') ?? this.urlParams.get('msclkid') ?? this.urlParams.get('rdt_cid');

        if (referralLink) {
            this.sessionService.saveReferralLink(referralLink);
            this.urlParams.delete('ref');
        }

        if (platformLink) {
            let platform;
            this.urlParams.has('gclid') ? platform = 'gclid' : this.urlParams.has('rdt_cid') ? platform = 'rdt_cid' : platform = 'msclkid';
            this.sessionService.setPlatformLinkCookie(platform, platformLink, 3);
        }

        const referrerLink = document.referrer;
        if (
            referrerLink &&
            referrerLink !== '' &&
            !referralLink &&
            !referrerLink.includes('chicksx') &&
            !referrerLink.includes('localhost') &&
            !referrerLink.includes('user.chicksgroup')
        ) {
            this.sessionService.saveReferrerLink(referrerLink);
        }
    }

    handleWindowResizement() {
        if (!window.ResizeObserver) return;
        this.observing ??= ['#main-page-host'];
        this.observing = this.observing.filter(x => document.querySelector(x));
        this.resizeObserver?.disconnect();
        this.resizeObserver = new ResizeObserver(async (entries) => {
            for (const entry of entries) {
                const width = entry?.contentRect.width;
                const height = entry?.contentRect.height;
                const isHost = entry.target.id === 'main-page-host';
                if (isHost) {
                    this.width = width;
                    this.height = height;
                    this.interComDeviceStyling();
                }
                const payload = {
                    width,
                    height,
                    target: entry.target
                };
                let event = SizeChangedEvent;
                if (!isHost) event += `-${entry.target.id}`;
                this.eventAggregator.publish(event, payload);
            }
        });
        this.observing.forEach(x => {
            if (x) this.resizeObserver.observe(document.querySelector(x));
        });
    }

    redirectToSiteDown() {
        if (window.location.pathname.includes('/rate-limit')) return;

        const params = new URLSearchParams(window.location.search);
        const redirectToParam = params.get('redirectTo');

        const currentUrl = window.location.href;
        const encodedUrl = encodeURIComponent(currentUrl);

        if (window.location.pathname.includes('/site-down')) {
            this.router.navigateToRoute('site-down', { redirectTo: redirectToParam ? redirectToParam : '/' });
            throw new Error('Chicks Gold is currently down. To check status, please open https://status.chicksgold.com/');
        }
        this.router.navigateToRoute('site-down', { redirectTo: encodedUrl ? encodedUrl : '/' });
        throw new Error('Chicks Gold is currently down. To check status, please open https://status.chicksgold.com/');
    }

    interComDeviceStyling() {
        if (document.querySelector('cds-snackbar') && !this.isDetached) return;
        let intercom = document.getElementById('intercom-style');
        if (
            this.router.currentInstruction.config.name === 'customer-portal' || this.router.currentInstruction.config.name === 'trade' &&
            this.width < 993
        ) {
            if (!intercom) {
                intercom = document.createElement('style');
                intercom.setAttribute('id', 'intercom-style');
            }

            let bottomSize = '0';
            if (this.width < 993) {
                bottomSize = '98';
            }

            intercom.innerHTML = `.intercom-launcher-frame, .intercom-app > div:nth-child(2)  { bottom: ${bottomSize}px !important; } .intercom-lightweight-app-launcher { bottom: ${bottomSize}px !important; } .intercom-messenger-frame { bottom: ${parseInt(bottomSize) * 2
            }px !important; }`;
            document.head.append(intercom);
        } else {
            if (intercom) {
                intercom.remove();
            }
        }
    }

    onToastReceived(toast: Toast) {
        this.isDetached = false;
        if (this.width <= DesktopBreakpoint) {
            setTimeout(() => {
                this.eventAggregator.publish(OpenedVeriffDialogEvent, { openedVeriffDialog: true, triggerDeviceStyling: true });
                this.validateRender = false;
                Notifications.add(
                    {
                        type: NotificationType.Snackbar,
                        message: toast.message,
                        severity: this.getToastType(toast.toastType),
                        extraActionLabel: toast.urlName,
                        onDetached: () => this.openVeriff(),
                        timeout: toast.screenTime,
                        actionLabel: toast.actionLabel,
                        onAction: toast.actionLabel ? () => this.executeLinkAction() : null
                    });
                if (
                    window.location.href.includes('localhost') ||
                    window.location.href.includes('staging.') ||
                    window.location.href.includes('dev.')
                ) {
                    this.validateRender = true;
                }

                setTimeout(() => {
                    if (toast.screenTime > 0) this.openVeriff();
                }, 3500);
            }, 500);
            this.activeSnackbar = toast;
            return;
        }

        Notifications.add({ type: NotificationType.Toast, title: toast.title, message: toast.message, severity: this.getToastType(toast.toastType) });
    }

    getToastType(toast: string) {
        return toast === 'error' ? 'error' : toast === 'warning' ? 'warning' : toast === 'success' ? 'success' : 'info';
    }

    openVeriff = () => {
        this.isDetached = true;
        this.eventAggregator.publish(OpenedVeriffDialogEvent, { openedVeriffDialog: false, triggerDeviceStyling: true });
        this.activeSnackbar = null;
    };

    executeLinkAction = () => {
        if (this.activeSnackbar?.url) {
            window.open(this.activeSnackbar.url);
        } else {
            this.eventAggregator.publish('toast-action');
        }
        this.activeSnackbar = null;
    };

    async handleSignalRConnection() {
        if (!this.triggeredConnection) {
            this.triggeredConnection = true;
            this.connection =
                await this.signalRService.setupSignalRConnection();
            await this.signalRService.start(this.connection);

            this.connection.on('ApiRedeployment', async (response) => {
                if (response) {
                    this.eventAggregator.publish('banner-updated', {
                        successful: 'warning',
                        text: 'ChicksX was updated. To refresh, please',
                        clickFunctionName: 'refresh',
                        actionLabel: 'Refresh'
                    });
                }
            });

            this.connection.on('Logout', this.sessionService.logout);

            this.connection.on('BlacklistResolution', async(blackList) => {
                if (blackList.blackListed) {
                    if (blackList.blackListLevel > 2) {
                        this.sessionService.closeLivechat();
                    }
                    if (blackList.blackListLevel > 3) {
                        await this.sessionService.logout();
                    }
                } else {
                    this.sessionService.initializeLiveChat();
                }
            });

            if (this.user) {
                await this.signalRService.trackUserConnection();
                this.connection.on('ReceiveTracker', async() => await this.checkPendingNotifications(true));
            }
        }
    }

    handleGoogleTagManager(user: User) {
        const scriptElement = document.getElementById('google-tag-manager');
        if (!scriptElement) {
            const s = document.createElement('script');
            s.setAttribute('id', 'google-tag-manager');
            s.innerHTML = this.getGoogleTagManagerScript(user);
            document.head.appendChild(s);
        } else {
            scriptElement.innerHTML = this.getGoogleTagManagerScript(user);
        }
    }

    async checkPendingNotifications(sendNotification = false) {
        if (!this.user) return;
        await this.signalRTrackingService.processSignalRTracking(sendNotification);
        this.eventAggregator.publish('get-unread-notifications');
    }

    async handleBreadcrumbList() {
        const configName = this.router?.currentInstruction?.config?.name;
        let configRoute = '';
        if (
            this.router?.currentInstruction.config.navModel.config.route.includes(
                '/'
            )
        ) {
            configRoute = (
                this.router?.currentInstruction.config.navModel.config
                    .route as string
            ).split('/')[0];
        }
        let breadCrumbSchema = `{
                            "@type": "BreadcrumbList",
                            "@id": "${baseUrl()}#/schema/BreadcrumbList/1",
                            "itemListElement": [{ 
                                "@type": "ListItem",
                                "position": 1,
                                "name": "${this.capitalizeTextValueConverter.toView(configName === 'blog-post' ? 'Blogs' : configName.toString().replaceAll('-', ' '), 'sentence')}",
                                "item": "${`${baseUrl()}${configRoute}`}"`;

        if (Object.keys(this.router.currentInstruction.params).length !== 0) {
            let blog;
            if (Object.keys(this.router?.currentInstruction.params).includes('blog')) {
                blog = await this.blogService.getBlogBySlug(Object.values(this.router.currentInstruction.params)[0]);
            }
            if (blog) {
                breadCrumbSchema += `}, {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "${blog.blogTag?.name}",
                                "item": "${`${baseUrl().slice(0, -1)}/${configRoute}/category/${blog.blogTag?.name.toLowerCase().replace(' ', '-')}`}"`;
                breadCrumbSchema += `}, {
                                "@type": "ListItem",
                                "position": 3,
                                "name": "${blog.title}",
                                "item": "${`${baseUrl().slice(0, -1)}${this.router?.currentInstruction.fragment}`}"
                                }]
                        }`;
            } else {
                breadCrumbSchema += `}, {
                            "@type": "ListItem",
                            "position": 2,
                            "name": "${this.capitalizeTextValueConverter.toView(Object.values(this.router?.currentInstruction.params)[0].toString().replace(/\d+/g, '').replaceAll('-', ' ').replaceAll('/', ' ').trim(), 'sentence')}",
                            "item": "${`${baseUrl().slice(0, -1)}${this.router?.currentInstruction.fragment}`}"
                        }]
                        }`;
            }
        } else {
            breadCrumbSchema += '}] }';
        }
        this.helper.combineApplicationLdJsonSchemasIntoOne(breadCrumbSchema, this.router);
        this.firstTimeBreadcrumbCall = false;
    }

    getGoogleTagManagerScript(user: User) {
        return user
            ? `window.dataLayer = window.dataLayer || [];
            
            window.dataLayer.push({
                'user_id': '${user.id}',
                'email': '${user.email}'
            });

            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WCVCX52');`
            : `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WCVCX52');`;
    }

    handleMonitoringWebsite() {
        this.healthTimeout = setInterval(async () => {
            if (!this.triggeredMonitoring) {
                try {
                    this.triggeredBanner = false;
                    this.triggeredMonitoring = true;
                    const response = await this.healthService.checkApiHealth();
                    if ((await isOnline() && !response) && !this.sessionService.getSiteDownToastFired()) {
                        this.triggeredBanner = true;
                        this.errorPagesRedirection.handleSiteDownBanner();

                        throw new Error(
                            'ChicksX is currently down. To check status, please open https://status.chicksx.com/'
                        );
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    this.triggeredMonitoring = false;
                }
            }
        }, 60000);
    }

    triggerDevHelp() {
        this.devHelpContainer = !this.devHelpContainer;
    }

    triggerCopy(option) {
        this.showCopied = true;
        if (option === 'ETH') {
            navigator.clipboard.writeText(
                '0x71C7656EC7ab88b098defB751B7401B5f6d8976F'
            );
        } else if (option === 'BTC') {
            navigator.clipboard.writeText('1BoatSLRHtKNngkdXEeobR76b53LETtpyT');
        } else if (option === 'Tracking') {
            navigator.clipboard.writeText('EZ1000000001');
        }
        setTimeout(() => {
            this.showCopied = false;
        }, 1500);
    }

    async handleScrollBar() {
        const readingProgress = document.getElementById('reading-progress-fill');

        const addNavbarBorderBottom = () => {
            this.eventAggregator.publish('page-is-scroll-donw', { isScroll: true });
        };

        const removeNavbarBorderBottom = () => {
            this.eventAggregator.publish('page-is-scroll-donw', { isScroll: false });
        };

        if (!this.scrollbar) return;

        const offset = this.scrollbar.getScrollElement();
        if (!offset) return;

        offset.addEventListener('scroll', (e: Event) => {
            if ((e.target as HTMLElement).scrollTop > 30) addNavbarBorderBottom();
            else removeNavbarBorderBottom();

            if (!this.helper.includesSome(this.router.currentInstruction.config.name, ['terms-of-service', 'cookies', 'privacy-policy', 'copyright-policy'])) return;
            const w = ((e.target as HTMLElement).scrollTop) / ((e.target as HTMLElement).scrollHeight - (e.target as HTMLElement).clientHeight) * 100;
            readingProgress.style.width = w + '%';
        });
    }

    async configureRouter(config: RouterConfiguration, router: Router) {
        this.apiHealth = await this.healthService.checkApiHealth();


        config.options.pushState = true;
        config.title = 'ChicksX';
        config.titleSeparator = ' - ';
        config.addAuthorizeStep(AuthorizeStep);
        config.addPreActivateStep(PreActivateStep);
        config.addPreRenderStep(MetadataStep);
        config.addPreRenderStep(SeoStep);
        config.addPostRenderStep(IntercomStep);
        config.addPostRenderStep(ScrollPageStep);
        config.addPreActivateStep(TradePreactivateStep);

        const hreflangList = this.languages.filter(lang => lang.hrefLang).map(lang => {
            return {
                hreflang: lang.hrefLang,
                route: lang.hrefLang.split('-').join('').toLowerCase()
            };
        });

        config.map([
            {
                route: [
                    this.getRouteNameForPage(this.pages, 'Home', '') ?? '',
                    'r/:referralLink',
                ],
                name: 'home',
                moduleId: PLATFORM.moduleName('pages/home/home'),
                title: this.getTitleForPage(this.pages, 'Home'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Home'
                    ),
                },
            },
            {
                route: ['sign-up', 'r/:referralLink'],
                name: 'sign-up',
                moduleId: PLATFORM.moduleName('pages/auth/auth'),
                title: 'Sign Up',
            },
            {
                route: 'sign-in',
                name: 'sign-in',
                moduleId: PLATFORM.moduleName('pages/auth/auth'),
                title: 'Sign In',
            },
            {
                route: 'sign-in/reset-password',
                name: 'reset-password',
                moduleId: PLATFORM.moduleName('pages/auth/auth'),
                title: 'Reset Password',
            },
            {
                route:
                    this.getRouteNameForPage(this.pages, 'Terms Of Service') ??
                    'terms-of-service',
                name: 'terms-of-service',
                moduleId: PLATFORM.moduleName('pages/tos/tos'),
                title: this.getTitleForPage(this.pages, 'Terms Of Service'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Terms Of Service'
                    ),
                },
            },
            {
                route:
                    [ this.getRouteNameForPage(this.pages, 'Bug Bounty'), 'bug-bounty' ],
                name: 'bug-bounty',
                moduleId: PLATFORM.moduleName('pages/bug-bounty/bug-bounty'),
                title: this.getTitleForPage(this.pages, 'Bug Bounty'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Bug Bounty'
                    ),
                },
            },
            {
                route:
                    this.getRouteNameForPage(this.pages, 'Privacy Policy') ??
                    'privacy-policy',
                name: 'privacy-policy',
                moduleId: PLATFORM.moduleName(
                    'pages/privacy-policy/privacy-policy'
                ),
                title: this.getTitleForPage(this.pages, 'Privacy Policy'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Privacy Policy'
                    ),
                },
            },
            {
                route:
                    [ this.getRouteNameForPage(this.pages, 'Cookies'), 'cookies' ],
                name: 'cookies',
                moduleId: PLATFORM.moduleName('pages/cookies/cookies'),
                title: this.getTitleForPage(this.pages, 'Cookies'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Cookies'
                    ),
                },
            },
            {
                route:
                    this.getRouteNameForPage(this.pages, 'Copyright Policy') ??
                    'copyright-policy',
                name: 'copyright-policy',
                moduleId: PLATFORM.moduleName(
                    'pages/copyright-policy/copyright-policy'
                ),
                title: this.getTitleForPage(this.pages, 'Copyright Policy'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Copyright Policy'
                    ),
                },
            },
            {
                route: this.getRouteNameForPage(this.pages, '404') ?? '404',
                name: '404',
                moduleId: PLATFORM.moduleName('pages/404/404'),
                title: this.getTitleForPage(this.pages, '404'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        '404'
                    ),
                },
            },
            {
                route: this.getRouteNameForPage(this.pages, 'Locations') ?? 'locations',
                name: 'locations',
                moduleId: PLATFORM.moduleName('pages/locations/locations'),
                title: this.getTitleForPage(this.pages, 'Locations'),
                hasFinancialServiceSchema: true,
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Locations'
                    ),
                },
            },
            {
                route: 'location-detail/:locationName/:storeNumber',
                name: 'location-detail',
                moduleId: PLATFORM.moduleName('./resources/elements/cx-location-card/cx-location-card'),
                title: 'Location Detail',
            },

            {
                route:
                    this.getRouteNameForPage(this.pages, 'Careers') ??
                    'careers',
                name: 'careers',
                moduleId: PLATFORM.moduleName('pages/careers/careers'),
                title: this.getTitleForPage(this.pages, 'Careers'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Careers'
                    ),
                },
            },
            {
                route: 'expired-link',
                name: 'expired-link',
                moduleId: PLATFORM.moduleName(
                    'pages/expired-link/expired-link'
                ),
                title: 'Expired Link',
            },
            {
                route:
                    [this.getRouteNameForPage(this.pages, 'Customer Portal') ?? 'customer-portal',
                        'customer-portal'],
                name: 'customer-portal',
                moduleId: PLATFORM.moduleName(
                    'pages/customer-portal/customer-portal'
                ),
                title: this.getTitleForPage(this.pages, 'Customer Portal'),
                settings: {
                    auth: true,
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Customer Portal'
                    ),
                }
            },
            {
                route:
                    [this.getRouteNameForPage(this.pages, 'Contact Us', 'contact'),
                        'contact',],
                name: 'contact',
                moduleId: PLATFORM.moduleName('pages/contact/contact'),
                title: this.getTitleForPage(this.pages, 'Contact Us'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Contact Us'
                    ),
                },
            },
            {
                route: 'cart',
                name: 'purchase-flow',
                moduleId: PLATFORM.moduleName('pages/purchase-flow/purchase-flow'),
                title: 'Purchase',
                settings: { auth: true }
            },
            {
                route: 'order-completed/:id',
                name: 'order-completed',
                moduleId: PLATFORM.moduleName(
                    'pages/order-completed/order-completed'
                ),
                title: 'Order Completed',
                activationStrategy: activationStrategy.replace
            },
            {
                route:
                    this.getRouteNameForPage(this.pages, 'Notifications') ??
                    'notifications',
                name: 'notifications',
                moduleId: PLATFORM.moduleName(
                    'pages/notifications/notifications'
                ),
                title: this.getTitleForPage(this.pages, 'Notifications'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Notifications'
                    ),
                },
            },
            {
                route:
                    this.getRouteNameForPage(this.pages, 'Sitemap') ??
                    'sitemap',
                name: 'sitemap',
                moduleId: PLATFORM.moduleName('pages/sitemap/sitemap'),
                title: this.getTitleForPage(this.pages, 'Sitemap'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Sitemap'
                    ),
                },
            },
            {
                route: `${this.getRouteNameForPage(this.pages, 'Sitemap') ?? 'sitemap'}/:category`,
                name: 'sitemap-category',
                moduleId: PLATFORM.moduleName('pages/sitemap/sitemap'),
                title: this.getTitleForPage(this.pages, 'Sitemap'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Sitemap'
                    )
                }
            },
            {
                route: this.getRouteNameForPage(this.pages, 'Faq') ?? 'faq',
                name: 'faq',
                moduleId: PLATFORM.moduleName('pages/faq/faq'),
                title: this.getTitleForPage(this.pages, 'Faq'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Faq'
                    ),
                },
            },
            {
                route:
                    this.getRouteNameForPage(this.pages, 'Locations') ??
                    'locations',
                name: 'locations',
                moduleId: PLATFORM.moduleName('pages/locations/locations'),
                title: this.getTitleForPage(this.pages, 'Locations'),
                hasFinancialServiceSchema: true,
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Locations'
                    ),
                },
            },
            {
                route: this.blogRoute,
                name: 'blog',
                moduleId: PLATFORM.moduleName('pages/blogs/blogs'),
                title: this.getTitleForPage(this.pages, 'Blog'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Blog'
                    ),
                },
            },
            {
                route: `${this.blogRoute}/page/:page`,
                name: 'blog-page',
                moduleId: PLATFORM.moduleName('pages/blogs/blogs'),
                title: this.getTitleForPage(this.pages, 'Blog'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Blog'
                    ),
                },
            },
            {
                route: `${this.blogRoute}/category/:category/page/:page`,
                name: 'blog-category-page',
                moduleId: PLATFORM.moduleName('pages/blogs/blogs'),
                title: this.getTitleForPage(this.pages, 'Blog'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Blog'
                    ),
                },
            },
            {
                route: `${this.blogRoute}/category/:category`,
                name: 'blog-category',
                moduleId: PLATFORM.moduleName('pages/blogs/blogs'),
                title: this.getTitleForPage(this.pages, 'Blog'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Blog'
                    ),
                },
            },
            {
                route: `${this.blogRoute}/:blog`,
                name: 'blog-post',
                moduleId: PLATFORM.moduleName(
                    'pages/blogs/blog-post/blog-post'
                ),
                title: this.getTitleForPage(this.pages, 'Blog'),
                hasBlogPostSchema: true,
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Blog'
                    ),
                },
                activationStrategy: activationStrategy.replace,
            },
            {
                route: `${this.blogRoute}/author/:alias`,
                name: 'author',
                moduleId: PLATFORM.moduleName('pages/blogs/author/author'),
                title: this.getTitleForPage(this.pages, 'Blog'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Blog'
                    ),
                },
                activationStrategy: activationStrategy.replace,
            },
            {
                route: `${this.getRouteNameForPage(this.pages, 'Trade') ?? 'trade'}/:value`,
                name: 'trade-currency',
                moduleId: PLATFORM.moduleName('pages/trade/trade'),
                title: 'Trade'
            },
            {
                route: `${this.getRouteNameForPage(this.pages, 'Trade') ?? 'trade'}/:value/page/:page`,
                name: 'trade-currency-page',
                moduleId: PLATFORM.moduleName('pages/trade/trade'),
                title: 'Trade'
            },
            {
                route: [
                    `${this.getRouteNameForPage(this.pages, 'Trade') ?? 'trade'}`,
                    ':params'
                ],
                name: 'trade',
                moduleId: PLATFORM.moduleName('pages/trade/trade'),
                title: this.getTitleForPage(this.pages, 'Trade'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Trade'
                    ),
                },
                data: { from: null, to: null, type: null }
            },
            {
                route: this.getRouteNameForPage(this.pages, 'Site Down') ?? 'site-down',
                name: 'site-down',
                moduleId: PLATFORM.moduleName('pages/site-down/site-down'),
                title: this.getTitleForPage(this.pages, 'Site Down'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Site Down'
                    ),
                }
            },
            {
                route: this.getRouteNameForPage(this.pages, 'Rate Limit') ?? 'rate-limit',
                name: 'rate-limit',
                moduleId: PLATFORM.moduleName('pages/rate-limit/rate-limit'),
                title: this.getTitleForPage(this.pages, 'Rate Limit'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Rate Limit'
                    ),
                }
            },
            {
                route:
                    this.getRouteNameForPage(this.pages, 'Crypto Asset Statements') ??
                    'crypto-asset-statements',
                name: 'crypto-asset-statements',
                moduleId: PLATFORM.moduleName(
                    'pages/crypto-asset-statements/crypto-asset-statements'
                ),
                title: this.getTitleForPage(this.pages, 'Crypto Asset Statements'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Crypto Asset Statements'
                    ),
                },
            },
            {
                route:
                    this.getRouteNameForPage(this.pages, 'Editorial Policy') ??
                    'editorial-policy',
                name: 'editorial-policy',
                moduleId: PLATFORM.moduleName(
                    'pages/editorial-policy/editorial-policy'
                ),
                title: this.getTitleForPage(this.pages, 'Editorial Policy'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Editorial Policy'
                    ),
                },
            },
            {
                route:
                    this.getRouteNameForPage(this.pages, 'Modern Slavery Statement'),
                name: 'modern-slavery-statement',
                moduleId: PLATFORM.moduleName(
                    'pages/modern-slavery-statement/modern-slavery-statement'
                ),
                title: this.getTitleForPage(this.pages, 'Modern Slavery Statement'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Modern Slavery Statement'
                    ),
                },
            },
            {
                route:
                    this.getRouteNameForPage(this.pages, 'Code of Ethics'),
                name: 'code-of-ethics',
                moduleId: PLATFORM.moduleName(
                    'pages/code-of-ethics/code-of-ethics'
                ),
                title: this.getTitleForPage(this.pages, 'Code of Ethics'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Code of Ethics'
                    ),
                },
            },
            {
                route:
                    this.getRouteNameForPage(this.pages, 'Complaints Policy') ??
                    'complaints-policy',
                name: 'complaints-policy',
                moduleId: PLATFORM.moduleName(
                    'pages/complaints-policy/complaints-policy'
                ),
                title: this.getTitleForPage(this.pages, 'Complaints Policy'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Complaints Policy'
                    ),
                },
            },
            {
                route:
                    this.getRouteNameForPage(this.pages, 'Accessibility') ??
                    'accessibility',
                name: 'accessibility',
                moduleId: PLATFORM.moduleName(
                    'pages/accessibility/accessibility'
                ),
                title: this.getTitleForPage(this.pages, 'Accessibility'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Accessibility'
                    ),
                },
            },
            {
                route:
                    this.getRouteNameForPage(this.pages, 'Disclaimer') ??
                    'disclaimer',
                name: 'disclaimer',
                moduleId: PLATFORM.moduleName(
                    'pages/disclaimer/disclaimer'
                ),
                title: this.getTitleForPage(this.pages, 'Disclaimer'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Disclaimer'
                    ),
                },
            },
            ...hreflangList.flatMap(hreflang => ([
                {
                    route: [hreflang.hreflang, `${hreflang.hreflang}/r/:referralLink`],
                    name: `home-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/home/home'),
                    title: this.getTitleForPage(this.pages, 'Home'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Home'
                        ),
                    }
                },
                {
                    route: [`${hreflang.hreflang}/sign-up`, `${hreflang.hreflang}/r/:referralLink`],
                    name: `sign-up-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/auth/auth'),
                    title: 'Sign Up',
                },
                {
                    route: `${hreflang.hreflang}/sign-in`,
                    name: `sign-in-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/auth/auth'),
                    title: 'Sign In',
                },
                {
                    route: `${hreflang.hreflang}/sign-in/reset-password`,
                    name: `reset-password-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/auth/auth'),
                    title: 'Reset Password',
                },
                {
                    route:
                        `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Terms Of Service') ?? 'terms-of-service'}`,
                    name: `terms-of-service-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/tos/tos'),
                    title: this.getTitleForPage(this.pages, 'Terms Of Service'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Terms Of Service'
                        ),
                    },
                },
                {
                    route:
                        [ `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Bug Bounty')}`, `${hreflang.hreflang}/bug-bounty` ],
                    name: `bug-bounty-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/bug-bounty/bug-bounty'),
                    title: this.getTitleForPage(this.pages, 'Bug Bounty'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Bug Bounty'
                        ),
                    },
                },
                {
                    route:
                        `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Privacy Policy') ?? 'privacy-policy'}`,
                    name: `privacy-policy-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName(
                        'pages/privacy-policy/privacy-policy'
                    ),
                    title: this.getTitleForPage(this.pages, 'Privacy Policy'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Privacy Policy'
                        ),
                    },
                },
                {
                    route:
                        [ `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Cookies')}`, `${hreflang.hreflang}/cookies` ],
                    name: `cookies-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/cookies/cookies'),
                    title: this.getTitleForPage(this.pages, 'Cookies'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Cookies'
                        ),
                    },
                },
                {
                    route:
                        `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Copyright Policy') ?? 'copyright-policy'}`,
                    name: `copyright-policy-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName(
                        'pages/copyright-policy/copyright-policy'
                    ),
                    title: this.getTitleForPage(this.pages, 'Copyright Policy'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Copyright Policy'
                        ),
                    },
                },
                {
                    route:
                        `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Careers') ?? 'careers'}`,
                    name: `careers-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/careers/careers'),
                    title: this.getTitleForPage(this.pages, 'Careers'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Careers'
                        ),
                    },
                },
                {
                    route: `${hreflang.hreflang}/expired-link`,
                    name: `expired-link-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName(
                        'pages/expired-link/expired-link'
                    ),
                    title: 'Expired Link',
                },
                {
                    route:
                        [`${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Customer Portal') ?? 'customer-portal'}`,
                            `${hreflang.hreflang}/customer-portal`],
                    name: `customer-portal-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName(
                        'pages/customer-portal/customer-portal'
                    ),
                    title: this.getTitleForPage(this.pages, 'Customer Portal'),
                    settings: {
                        auth: true,
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Customer Portal'
                        ),
                    }
                },
                {
                    route:
                        [`${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Contact Us', 'contact')}`,
                            `${hreflang.hreflang}/contact`,],
                    name: `contact-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/contact/contact'),
                    title: this.getTitleForPage(this.pages, 'Contact Us'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Contact Us'
                        ),
                    },
                },
                {
                    route: `${hreflang.hreflang}/cart`,
                    name: `purchase-flow-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName(
                        'pages/purchase-flow/purchase-flow'
                    ),
                    title: 'Purchase',
                },
                {
                    route: `${hreflang.hreflang}/order-completed/:id`,
                    name: `order-completed-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName(
                        'pages/order-completed/order-completed'
                    ),
                    title: 'Order Completed',
                },
                {
                    route:
                        `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Notifications') ?? 'notifications'}`,
                    name: `notifications-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName(
                        'pages/notifications/notifications'
                    ),
                    title: this.getTitleForPage(this.pages, 'Notifications'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Notifications'
                        ),
                    },
                },
                {
                    route:
                        `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Sitemap') ?? 'sitemap'}`,
                    name: `sitemap-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/sitemap/sitemap'),
                    title: this.getTitleForPage(this.pages, 'Sitemap'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Sitemap'
                        ),
                    },
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Sitemap') ?? 'sitemap'}/:category`,
                    name: `sitemap-category-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/sitemap/sitemap'),
                    title: this.getTitleForPage(this.pages, 'Sitemap'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Sitemap'
                        )
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Faq') ?? 'faq'}`,
                    name: `faq-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/faq/faq'),
                    title: this.getTitleForPage(this.pages, 'Faq'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Faq'
                        ),
                    },
                },
                {
                    route:
                        `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Locations') ?? 'locations'}`,
                    name: `locations-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/locations/locations'),
                    title: this.getTitleForPage(this.pages, 'Locations'),
                    hasFinancialServiceSchema: true,
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Locations'
                        ),
                    },
                },
                {
                    route: `${hreflang}/${this.blogRoute}`,
                    name: `blog-${hreflang}`,
                    moduleId: PLATFORM.moduleName('pages/blogs/blogs'),
                    title: this.getTitleForPage(this.pages, 'Blog'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Blog'
                        ),
                    },
                },
                {
                    route: `${hreflang}/${this.blogRoute}/page/:page`,
                    name: `blog-page-${hreflang}`,
                    moduleId: PLATFORM.moduleName('pages/blogs/blogs'),
                    title: this.getTitleForPage(this.pages, 'Blog'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Blog'
                        ),
                    },
                },
                {
                    route: `${hreflang}/${this.blogRoute}/category/:category/page/:page`,
                    name: `blog-category-page-${hreflang}`,
                    moduleId: PLATFORM.moduleName('pages/blogs/blogs'),
                    title: this.getTitleForPage(this.pages, 'Blog'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Blog'
                        ),
                    },
                },
                {
                    route: `${hreflang}/${this.blogRoute}/category/:category`,
                    name: `blog-category-${hreflang}`,
                    moduleId: PLATFORM.moduleName('pages/blogs/blogs'),
                    title: this.getTitleForPage(this.pages, 'Blog'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Blog'
                        ),
                    },
                },
                {
                    route: `${hreflang}/${this.blogRoute}/:blog`,
                    name: `blog-post-${hreflang}`,
                    moduleId: PLATFORM.moduleName(
                        'pages/blogs/blog-post/blog-post'
                    ),
                    title: this.getTitleForPage(this.pages, 'Blog'),
                    hasBlogPostSchema: true,
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Blog'
                        ),
                    },
                    activationStrategy: activationStrategy.replace,
                },
                {
                    route: `${hreflang}/${this.blogRoute}/author/:alias`,
                    name: `author-${hreflang}`,
                    moduleId: PLATFORM.moduleName('pages/blogs/author/author'),
                    title: this.getTitleForPage(this.pages, 'Blog'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Blog'
                        ),
                    },
                    activationStrategy: activationStrategy.replace,
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Trade') ?? 'trade'}/:value`,
                    name: `trade-currency-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/trade/trade'),
                    title: 'Trade',
                    data: { language: hreflang.hreflang }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Trade') ?? 'trade'}/:value/page/:page`,
                    name: `trade-currency-page-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/trade/trade'),
                    title: 'Trade',
                    data: { language: hreflang.hreflang }
                },
                {
                    route: [
                        `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Trade') ?? 'trade'}`,
                        `${hreflang.hreflang}/buy`,
                        `${hreflang.hreflang}/buy/:params`,
                        `${hreflang.hreflang}/sell`,
                        `${hreflang.hreflang}/sell/:params`,
                        `${hreflang.hreflang}/swap/fiat`,
                        `${hreflang.hreflang}/swap/fiat/:params`,
                        `${hreflang.hreflang}/swap/crypto`,
                        `${hreflang.hreflang}/swap/crypto/:params`,
                        `${hreflang.hreflang}/:params`
                    ],
                    name: `trade-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/trade/trade'),
                    title: this.getTitleForPage(this.pages, 'Trade'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Trade'
                        ),
                    },
                    data: { from: null, to: null, type: null, language: hreflang.hreflang }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Site Down') ?? 'site-down'}`,
                    name: `site-down-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/site-down/site-down'),
                    title: this.getTitleForPage(this.pages, 'Site Down'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Site Down'
                        ),
                    }
                },
                {
                    route: `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Rate Limit') ?? 'rate-limit'}`,
                    name: `rate-limit-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName('pages/rate-limit/rate-limit'),
                    title: this.getTitleForPage(this.pages, 'Rate Limit'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Rate Limit'
                        ),
                    }
                },
                {
                    route:
                        `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Crypto Asset Statements') ?? 'crypto-asset-statements'}`,
                    name: `crypto-asset-statements-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName(
                        'pages/crypto-asset-statements/crypto-asset-statements'
                    ),
                    title: this.getTitleForPage(this.pages, 'Crypto Asset Statements'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Crypto Asset Statements'
                        ),
                    },
                },
                {
                    route:
                        `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Editorial Policy') ?? 'editorial-policy'}`,
                    name: `editorial-policy-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName(
                        'pages/editorial-policy/editorial-policy'
                    ),
                    title: this.getTitleForPage(this.pages, 'Editorial Policy'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Editorial Policy'
                        ),
                    },
                },
                {
                    route:
                        `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Modern Slavery Statement') ?? 'modern-slavery-statement'}`,
                    name: `modern-slavery-statement-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName(
                        'pages/modern-slavery-statement/modern-slavery-statement'
                    ),
                    title: this.getTitleForPage(this.pages, 'Modern Slavery Statement'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Modern Slavery Statement'
                        ),
                    },
                },
                {
                    route:
                        `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Code of Ethics') ?? 'code-of-ethics'}`,
                    name: `code-of-ethics-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName(
                        'pages/code-of-ethics/code-of-ethics'
                    ),
                    title: this.getTitleForPage(this.pages, 'Code of Ethics'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Code of Ethics'
                        ),
                    },
                },
                {
                    route:
                        `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Complaints Policy') ?? 'complaints-policy'}`,
                    name: `complaints-policy-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName(
                        'pages/complaints-policy/complaints-policy'
                    ),
                    title: this.getTitleForPage(this.pages, 'Complaints Policy'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Complaints Policy'
                        ),
                    },
                },
                {
                    route:
                        `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Accessibility') ?? 'accessibility'}`,
                    name: `accessibility-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName(
                        'pages/accessibility/accessibility'
                    ),
                    title: this.getTitleForPage(this.pages, 'Accessibility'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Accessibility'
                        ),
                    },
                },
                {
                    route:
                        `${hreflang.hreflang}/${this.getRouteNameForPage(this.pages, 'Disclaimer') ?? 'disclaimer'}`,
                    name: `disclaimer-${hreflang.route}`,
                    moduleId: PLATFORM.moduleName(
                        'pages/disclaimer/disclaimer'
                    ),
                    title: this.getTitleForPage(this.pages, 'Disclaimer'),
                    settings: {
                        metaDescription: this.getMetaDescriptionForPage(
                            this.pages,
                            'Disclaimer'
                        ),
                    },
                },
            ])),
            ...this.tradeRoutes.flatMap(tradeRoute => ([
                {
                    route: [tradeRoute, `${tradeRoute}/:params`],
                    name: 'trade',
                    moduleId: PLATFORM.moduleName('pages/trade/trade'),
                    title: this.getMetaTitleForTradePage(tradeRoute),
                    settings: {
                        metaDescription: this.getMetaDescriptionForTradePage(tradeRoute),
                    },
                    data: { from: null, to: null, type: null }
                }
            ]))
        ]);

        config.mapUnknownRoutes(() => {
            return {
                redirect: `${this.getRouteNameForPage(this.pages, '404') ?? '404'}`
            };
        });
        this.router = router;
    }

    // Site Down Redirection Logic
    waitForRouter() {
        return new Promise((resolve) => {
            if (this.router) {
                resolve(this.router);
            } else {
                const intervalId = setInterval(() => {
                    if (this.router) {
                        clearInterval(intervalId);
                        resolve(this.router);
                    }
                }, 200);
            }
        });
    }

    getTitleForPage(pages: PageByWebsite[], pageName: string) {
        return pages?.find((x) => x.name === pageName)?.title;
    }

    getMetaDescriptionForPage(
        pages: PageByWebsite[],
        pageName: string
    ) {
        return pages?.find((x) => x.name === pageName)?.metaDescription;
    }

    getMetaDescriptionForTradePage(tradeRoute: string) {
        const tradeForNav = this.tradesForNav?.find(x => x.slug[0] === tradeRoute);
        return tradeForNav?.metaDescription;
    }

    getMetaTitleForTradePage(tradeRoute: string) {
        const tradeForNav = this.tradesForNav?.find(x => x.slug[0] === tradeRoute);
        return tradeForNav?.title;
    }

    getRouteNameForPage(pages: PageByWebsite[], pageName: string, def = null) {
        return pages
            ?.find((x) => x.name === pageName)
            ?.routeName?.toLowerCase() ?? (def !== null ? def : this.helper.toRoute(pageName));
    }

    handleReviewSchema() {
        const reviewSchema = `{
                            "@type": "Organization",
                            "@id": "${baseUrl()}#/schema/Organization/ChicksX",
                            "url": "${baseUrl()}",
                            "logo": {
                                "@id": "${baseUrl()}#/schema/ImageObject/Logo/ChicksX"
                            },
                            "name": "ChicksX Inc.",
                            "telephone": "(1) 416-244-2579",
                            "email": "support@chicksx.com",
                            "address" : {
                                "@id": "${baseUrl()}#/schema/PostalAddress/ChicksX"
                            }
                        },
                        {
                            "@type": "PostalAddress",
                            "@id": "${baseUrl()}#/schema/PostalAddress/ChicksX",
                            "streetAddress": "2275 Britannia Rd W Unit 3",
                            "addressLocality": "Mississauga",
                            "addressRegion": "ON",
                            "postalCode": "L5M 2G6",
                            "addressCountry": "CA"
                        },
                        {
                            "@type": "ImageObject",
                            "@id": "${baseUrl()}#/schema/ImageObject/Logo/ChicksX",
                            "url": "${baseUrl()}static/logo/chicksx-logo-purple.svg",
                            "contentUrl": "${baseUrl()}static/logo/chicksx-logo-purple.svg",
                            "width": {
                                "@type": "QuantitativeValue",
                                "value": 512,
                                "unitCode": "E37",
                                "unitText": "pixel"
                            },
                            "height": {
                                "@type": "QuantitativeValue",
                                "value": 512,
                                "unitCode": "E37",
                                "unitText": "pixel"
                            },
                            "caption": "ChicksX Logo",
                            "name": "ChicksX"
                        }`;
        this.helper.combineApplicationLdJsonSchemasIntoOne(reviewSchema);
    }

    @computedFrom('router.currentInstruction.config.route')
    get isMainPage() {
        return this.helper.excludeAll(this.router?.currentInstruction?.config?.route, ['sign-up', 'r/:referralLink', 'sign-in', 'sign-in/reset-password']);
    }
}
