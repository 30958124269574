// Module
var code = `<template>
    <section id="trade">
        <div class="position-relative">
            <cx-trade-intro>
                <div class="get-started-container d-flex justify-content-center align-items-center position-relative">
                    <get-started
                        class="get-started"
                        header-text.bind="titleKeyContent"
                        description-text.bind="descriptionKeyContent"
                        banner.bind="bannerKeyContent"
                    ></get-started>
                </div>
            </cx-trade-intro>
            <div class="secondary-container position-absolute w-100 bottom-0"></div>
        </div>
        <div class="mt-standard">
            <router-view></router-view>
        </div>
    </section>
</template>
`;
// Exports
export default code;