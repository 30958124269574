// Module
var code = `<template>
    <section id="disclaimer" class="legal-page policy \${pageLoading ? 'skeleton-policy' : ''}">
        <div class="header font-bold cx-background text-light">
            <div class="header-title text-start">
                <cx-page-content-area key.bind="'DISCLAIMER_TITLE'" class="title-1" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
            </div>
        </div>
        <div class="tos-main-content text-medium">
            <div class="tos-body">
                <cx-page-content-area key.bind="'DISCLAIMER_LAST_UPDATED'" class="text-end last-update text-1" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_NO_FINANCIAL_ADVICE_TITLE'" class="text-title font-bold title-1-1" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_NO_FINANCIAL_ADVICE_CONTENT'" class="text-content text-content-1" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_RISK_WARNING_TITLE'" class="text-title font-bold title-1-1" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_RISK_WARNING_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_ACCURARCY_OF_INFORMATION_TITLE'" class="text-title font-bold title-1-1" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_ACCURARCY_OF_INFORMATION_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_SERVICE_INTERRUPTIONS_TITLE'" class="text-title font-bold title-1-1" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_SERVICE_INTERRUPTIONS_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_USER_RESPONSABILITY_TITLE'" class="text-title font-bold title-1-1" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_USER_RESPONSABILITY_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_COMPILANCE_TITLE'" class="text-title font-bold title-1-1" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_COMPILANCE_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_LIMITATION_OF_LIABILITY_TITLE'" class="text-title font-bold title-1-1" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_LIMITATION_OF_LIABILITY_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_CHANGES_TO_TERMS_TITLE'" class="text-title font-bold title-1-1" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_CHANGES_TO_TERMS_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_CONTACT_US_TITLE'" class="text-title font-bold title-1-1" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area key.bind="'DISCLAIMER_CONTACT_US_CONTENT'" class="text-content" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
            </div>
        </div>
        <div class="divider-bottom"></div>
    </section>
</template>
`;
// Exports
export default code;