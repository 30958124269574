import { BaseEntity } from './baseEntity';
import { GameNameResponse } from './game/gameNameResponse';
import { OrderChat } from './order-chat';
import {
    CashInMailDetail, Currency,
    NuveiPaymentDataRequest,
    OrderCryptoExchanges,
    PaymentMethod,
    PaymentMethodWebsite,
    PylonPaymentDataRequest,
    StripeRadarSession,
} from './purchase-flow/exchange';
import { OrderDetail } from './purchase-flow/exchange';
import { IpAddress } from './user/ipAddress';
import { User } from './user/user';
import { Website } from './website/website';

export class CashInMailOrderDetail extends BaseEntity {
    address: string;
    country: string;
    state: string;
    city: string;
    zipCode: string;
    deliveryType: string;
}

export class OrderService {
    id: string;
    orderId: string;
    quantity: number;
    price?: number;
    convertedPrice?: number;
    totalPrice?: number;
    convertedTotalPrice?: number;
    character: string;
    fulfilledAmount: number;
    toFulfillAmount: number;
    fulfillerId?: string;
    fulfiller: User;
    assignerId?: string;
    assigner: User;
    name: string;
    productCategoryId?: string;
    productCategory;
    gameId?: string;
    game;
    gameModeId?: string;
    gameMode;
    difficultyId?: string;
}

export interface IOrderType {
    id: number;
    name: string;
    description: string;
}

export class Order extends BaseEntity {
    orderCryptoExchanges: OrderCryptoExchanges[];
    orderDetail: OrderDetail;
    fulfilled: boolean;
    ip: string;
    ipAddressId: number;
    ipAddress: [];
    userId: number;
    websiteShortCode: string;
    referralLinkUrl: string;
    firstName: string;
    lastName: string;
    cardLastFour: string;
    cardType: string;
    status: string;
    paysafeCardId: string;
    paymentMethodId: number;
    paymentMethod: PaymentMethod;
    receivingMethod: PaymentMethod;
    paymentMethodWebsite: PaymentMethodWebsite;
    cashInMailOrderDetailId: number;
    cashInMailOrderDetail: CashInMailOrderDetail;
    paysafeBillingAddressId: string;
    cardCvv: string;
    userDocumentIds: [];
    websiteId: number;
    website: Website;
    user: User;
    easyPostTrackingId: string;
    hostedUrl: string;
    totalPrice: number;
    description: string;
    coinbaseChargeCode: string;
    coinbaseChargeId: string;
    trackingNumber: string;
    g2aToken: string;
    g2aTransactionId: string;
    feePrice?: number;
    deliveryFee?: number;
    hstValue?: number;
    smallOrderFee?: number;
    paymentFee?: number;
    couponDiscount?: number;
    paypalDirectId: string;
    currencyUsed: string;
    convertedCurrencyTotal?: number;
    currencyRateUsed?: number;
    payopToken: string;
    payopState: string;
    payopTransactionId: string;
    gameId?: number;
    game: GameNameResponse;
    gameConvertedTotal: string;
    gameCurrencyRateUsed: string;
    receivingMethodId?: number;
    receiverId?: number;
    couponCode;
    receiver;
    referralLinks;
    products;
    services: OrderService[];
    paypalOrderAggregate;
    coinpaymentsOrderAggregate;
    bTCPayOrderAggregate;
    cardLast4: string;
    balanceAmount?: number;
    balanceFee?: number;
    agents;
    intercomLink: string;
    blueSnapTransactionId: string;
    blueSnapUsTransaction?: boolean;
    paysafeSettlementId: string;
    paysafeAuthorizationId: string;
    paysafeMerchantRefNum: string;
    bluesnapCheckoutTransactionId: string;
    checkoutTransactionId: string;
    subscriptionId?: number;
    subscriptionCashback?: number;
    orderType?: IOrderType;
    orderTypeId?: number;
    transactionType?: number;
    externalTransactionDetailType?: ExternalTransactionDetailType;
    externalTransactionId: string;

    static isExchangeOrder(order: Order) {
        return order.orderCryptoExchanges?.length > 0;
    }

    static getOrderPaymentMethod(order: Order, isExchangeOrder: boolean | null = null) {
        isExchangeOrder ??= Order.isExchangeOrder(order);
        return isExchangeOrder ? order.receivingMethod.name : order.paymentMethod.name;
    }

    static getOrderExchangeRate(order: Order, isExchangeOrder: boolean | null = null) {
        isExchangeOrder ??= Order.isExchangeOrder(order);
        return isExchangeOrder ? order.orderCryptoExchanges[0].rate : order.currencyRateUsed;
    }

    static getOrderCoinFrom(order: Order, isExchangeOrder: boolean | null = null) {
        isExchangeOrder ??= Order.isExchangeOrder(order);
        return isExchangeOrder ? order.orderCryptoExchanges[0].currencyBase : '';
    }

    static getOrderBaseCurrency(order: Order, currencies: Currency[], isExchangeOrder: boolean | null = null) {
        isExchangeOrder ??= Order.isExchangeOrder(order);
        return isExchangeOrder ? order.orderCryptoExchanges[0].baseCurrency : currencies.find(c => c.originalCode === order.currencyUsed);
    }

    static getOrderCoinFromSymbol(order: Order, currencies: Currency[], isExchangeOrder: boolean | null = null) {
        isExchangeOrder ??= Order.isExchangeOrder(order);
        return isExchangeOrder ? currencies.find(c => c.originalCode === order.orderCryptoExchanges[0].currencyBase)?.symbol : '';
    }

    static getOrderCoinTo(order: Order, isExchangeOrder: boolean | null = null) {
        isExchangeOrder ??= Order.isExchangeOrder(order);
        return isExchangeOrder ? order.orderCryptoExchanges[0].currencyTarget : '';
    }

    static getOrderTargetCurrency(order: Order, isExchangeOrder: boolean | null = null) {
        isExchangeOrder ??= Order.isExchangeOrder(order);
        return isExchangeOrder ? order.orderCryptoExchanges[0].targetCurrency : null;
    }

    static getOrderCoinToSymbol(order: Order, currencies: Currency[], isExchangeOrder: boolean | null = null) {
        isExchangeOrder ??= Order.isExchangeOrder(order);
        return isExchangeOrder ? currencies.find(c => c.originalCode === order.orderCryptoExchanges[0].currencyTarget)?.symbol : '';
    }

    static getOrderFee(order: Order, isExchangeOrder: boolean | null = null) {
        isExchangeOrder ??= Order.isExchangeOrder(order);
        return isExchangeOrder ? order.orderCryptoExchanges[0].paymentFee : (order.feePrice ?? 0);
    }

    static getOrderPrice(order: Order, isExchangeOrder: boolean | null = null) {
        isExchangeOrder ??= Order.isExchangeOrder(order);
        return isExchangeOrder ? order.orderCryptoExchanges[0].amountBase : order.convertedCurrencyTotal;
    }

    static getOrderQuantity(order: Order, isExchangeOrder: boolean | null = null) {
        isExchangeOrder ??= Order.isExchangeOrder(order);
        return isExchangeOrder ? order.orderCryptoExchanges[0].amountTarget : ((order.totalPrice - order.feePrice) / order.currencyRateUsed);
    }
}

export class OrderRequest {
    idealBank?: string;
    status?: string;
    fulfilled?: string;
    trackingNumber?: string;
    feePrice?: number;
    deliveryFee?: number;
    hstValue?: number;
    paymentFee?: number;
    currencyUsed?: string;
    paymentMethodId?: number;
    gameId?: number;
    gameConvertedTotal?: string;
    gameCurrencyRateUsed?: string;
    receivingMethodId?: number;
    receiverId?: number;
    cashInMailOrderDetail?: CashInMailDetail;
    orderDetail?: OrderDetail;
    paymentToken?: string;
    products?;
    ip?: string;
    ipAddressId?: number;
    ipAddress?: IpAddress;
    services?;
    userId?: number;
    userAgent?: string;
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    zip?: string;
    couponCodeString?: string;
    websiteShortCode?: string;
    referralLinkUrl?: string;
    cardLast4?: string;
    referrerLinkUrl?: string;
    blueSnapPfToken?: string;
    blueSnapThreeDResult?: string;
    blueSnapThreeDSecureReferenceId?: string;
    blueSnapFraudSessionId?: string;
    firstName?: string;
    lastName?: string;
    cardLastFour?: string;
    cardType?: string;
    paysafeCardId?: string;
    paysafeBillingAddressId?: string;
    paysafeAuthenticationId?: string;
    cardCvv?: string;
    payWithBalance?: boolean;
    checkoutInstrumentId?: string;
    userDocumentIds?: number[];
    orderAdClicks?;
    updatingUserId?: number;
    websites?;
    orderCryptoExchanges?: OrderCryptoExchanges[];
    googlePayPaymentData?;
    easyPostTrackingId?: string;
    googleAnalyticsClientId?: string;
    facebookClickId?: string;
    facebookBrowserId?: string;
    redditUuid?: string;
    screenWidth?: string;
    screenHeight?: string;
    subscriptionId?: number;
    useCashback?: boolean;
    pylonPaymentData?: PylonPaymentDataRequest;
    nuveiPaymentData?: NuveiPaymentDataRequest;
    totalPrice?: number;
    orderTypeId?: number;
    isWithdraw?: boolean;
    usdExchangeRate?: number;
    currentWithdrawPassword?: string;
    transactionType?: OrderTransactionType;
    paymentAddress?: string;
    stripeRadarSession?: StripeRadarSession;
    vgsData?;
    threeDsData?;
    applePayPaymentData?;
}

export enum OrderTransactionType {
    Purchased,
    Sold,
    Swap
}

export enum OrderOperationType {
    Buy,
    Sell,
    Exchange
}

export enum OrderStatus {
    All = 'all',
    Completed = 'completed',
    Rejected = 'rejected',
    Refunded = 'refunded',
    Created = 'created',
    Pending = 'pending'
}

export class AdvancedOrderSearchRequest {
    transactionType?: OrderTransactionType;
    query?: string;
    websiteShortCode?: 'CX' | 'CG';
    status: string = OrderStatus.All;
    page: number = 0;
    pageSize: number = 5;
    balanceOrders: boolean = true;
}

export class OrderPaginationRequest {
    recordQuantity?: number;
    page?: number;
    status: string;
    search?: string;
    orderOperationType?: OrderOperationType;
    isCashback?: boolean;
    websiteShortCode: string = 'CX';
}

export class AdvancedOrderSearchOrder {
    id: number;
    total: number;
    type: string;
    status: string;
    date: string;
    fulfilled: boolean;
    currencyUsed?: string;
    websiteName: string;
    websiteCode: string;
}

export class AdvancedOrderSearchResponse {
    count: number;
    orders: AdvancedOrderSearchOrder[];
}

export class SmallOrder {
    id: number;
    coinFrom: string;
    coinFromSymbol: string | undefined;
    coinTo: string;
    targetCurrency?: Currency;
    baseCurrency?: Currency;
    coinToSymbol: string | undefined;
    fee: number;
    quantity: number;
    price: number;
    rate: number;
    status: string;
    fulfilled: boolean;
    isExchangeOrder: boolean;
    orderChat?: OrderChat;
    products?;
    websiteShortCode?: string;
    subscriptionCashback?: number;
    transactionType?: OrderTransactionType;
}

export enum ExternalTransactionDetailType {
    NotApplicable = 0,
    CoinbaseCharge = 1,
    G2aTransaction = 2,
    PaypalDirect = 3,
    PayopTransaction = 4,
    IdealOrder = 5,
    SofortOrder = 6,
    SofortTransaction = 7,
    IdealTransaction = 8,
    BluesnapCheckoutTransaction = 9,
    PaysafeSettlement = 10,
    PaysafeAuthorization = 11,
    PaysafeRefund = 12,
    MonzoTransaction = 13,
    BlueSnapTransaction = 14,
    SkrillTransaction = 15,
    CheckoutTransaction = 16,
    CashInMailOrderDetail = 17,
    CoinpaymentsOrderAggregate = 18,
    BTCPayOrderAggregate = 19,
    BitCartOrderAggregate = 20,
    VGSCard = 21,
    SolidgateTransaction = 22,
    NMITransaction = 23,
    PaymentLyncTransaction = 24,
    PaymentLyncRefundTransaction = 25,
    Pateno = 26
}

export type OrderSectionType = 'Purchased' | 'Sold' | 'Exchange' | 'Cashback';

export class OrderServiceOrderTotalInfoResponse {
    totalPrice: number;
    count: number;
    transactionType?: OrderTransactionType;
    operationType?: OrderOperationType;
}
