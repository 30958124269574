import { autoinject, bindable } from 'aurelia-framework';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class SiteDown {
    @bindable redirectTo: string;
    constructor(private websiteService: WebsiteService) {}

    activate() {
        const params = new URLSearchParams(window.location.search);
        const redirectTo = params.get('redirectTo');

        if (redirectTo) {
            this.redirectTo = decodeURIComponent(redirectTo);
            return;
        }
        this.redirectTo = '/';
    }
}
