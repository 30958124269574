import './cx-billing-address.scss';
import { ScriptService } from 'services/script-service';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { BillingAddress } from 'services/models/user/billingAddress';
import { User } from 'services/models/user/user';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import { Country } from 'country-state-city';
import { Helper } from 'resources/helpers/helper';
import { ToastService } from 'services/toast-service';

@autoinject()
export class CxBillingAddress {
    constructor(
        private scriptService:ScriptService,
        private validator: ValidationController,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private helper: Helper,
        private toastService: ToastService) {
        this.scriptService.injectGoogleScript();
    }

    parent;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    @bindable showFields: boolean = false;
    @bindable @observable loading = true;
    @bindable billing : BillingAddress;
    @bindable user: User;
    @observable pacContainer: HTMLElement;
    @bindable showPreviousBilling = false;
    @bindable billingAddressIsValid = false;
    @bindable parentClass;

    showPlacesInput = false;
    autocompletedAddress: string;
    locationAutoComplete: google.maps.places.Autocomplete;
    billingAddressInput: HTMLElement;
    showGreenCheckMark = false;
    showErrorCheckMark = false;
    manualBillingAddress: google.maps.places.AutocompleteService;
    googleMapsPlace: google.maps.places.PlacesService;
    showBillingOption = false;
    timeouts: NodeJS.Timeout[];
    countryTimeout: NodeJS.Timeout;
    addressTimeout: NodeJS.Timeout;
    zipTimeout: NodeJS.Timeout;
    focusInStopwatch: NodeJS.Timeout;

    async attached() {
        this.showPlacesInput = true;
        if (this.user && this.showPreviousBilling && !this.billing) {
            const { city, state, country, zip, address } = this.user;
            const countryName = Country.getCountryByCode(country)?.name;
            this.billing = {
                city: city,
                state: state,
                countryCode: country,
                countryName: countryName,
                zip: zip,
                street: address
            };
        }
    }

    async loadingChanged(newValue) {
        if (!newValue) {
            await this.loadGooglePlacesInput();
        }
    }

    billingChanged() {
        if (!this.billing) {
            this.showBillingOption = this.showFields = false;
            if (this.billingAddressInput?.querySelector('input')) {
                this.billingAddressInput.querySelector('input').value = '';
            }
            this.autocompletedAddress = '';
            this.showGreenCheckMark = this.showErrorCheckMark = false;
            return;
        }
        const { city, state, zip, street, countryCode } = this.billing;
        if (Object.values(this.billing).some(t => t)) {
            const strBilling = `${street ?? ''}, ${city ?? ''} ${zip ?? ''}, ${state ?? ''}, ${countryCode ?? ''}`;
            this.autocompletedAddress = strBilling;
            if (this.billingAddressInput?.querySelector('input')) {
                this.billingAddressInput.querySelector('input').value = strBilling;
            }
            this.zipUpdatedOnKeyPress();
        }
    }

    async loadGooglePlacesInput() {
        setTimeout(() => {
            this.locationAutoComplete = new google.maps.places.Autocomplete(this.billingAddressInput?.getElementsByClassName('mdc-text-field__input')[0] as HTMLInputElement, {});

            setTimeout(() => {
                const googlePlaces = document.querySelectorAll('.pac-container');
                const googleAutocompleteDiv = document.getElementById('google-autocomplete-places-dropdown');
                googlePlaces.forEach((el) => googleAutocompleteDiv?.replaceChildren(el));
            }, 2000);

            if (this.locationAutoComplete) {
                google.maps.event.addListener(this.locationAutoComplete, 'place_changed', () => {
                    const addressData = this.locationAutoComplete.getPlace();

                    this.billing = {
                        city: addressData.address_components?.find(x => x.types.find(y => y === 'locality'))?.long_name ?? addressData.address_components?.find(x => x.types.find(y => y === 'administrative_area_level_1'))?.long_name,
                        state: addressData.address_components?.find(x => x.types.find(y => y === 'administrative_area_level_1'))?.short_name,
                        countryCode: addressData.address_components?.find(x => x.types.find(y => y === 'country'))?.short_name,
                        countryName: addressData.address_components?.find(x => x.types.find(y => y === 'country'))?.long_name,
                        zip: addressData.address_components?.find(x => x.types.find(y => y === 'postal_code'))?.short_name,
                        street: addressData.name
                    };
                    this.autocompletedAddress = (this.billingAddressInput.getElementsByClassName('mdc-text-field__input')[0] as HTMLInputElement).value;
                    this.zipUpdatedOnKeyPress();
                });
            }
        }, 1000);
    }

    async zipUpdatedOnKeyPress(event?, withDelay = false) {
        const addressAutofill = document.querySelector('#billing-street input:-webkit-autofill') || document.querySelector('#billing-autocompleted input:-webkit-autofill');
        if (event?.type === 'change' && addressAutofill) {
            await this.handleCountryAutoComplete(event);
        }
        this.clearationTimeoutValueConverter.toView([this.zipTimeout]);
        this.zipTimeout = setTimeout(async () => {
            if (addressAutofill) {
                setTimeout(async() => {
                    await this.handleBillingValidation();
                }, 100);
            } else {
                await this.handleBillingValidation();
            }
            if (this.billing) {
                const hasNullValue = Object.entries(this.billing).some(([key, value]) => key !== 'state' && !value);
                if (hasNullValue) {
                    this.showFields = this.showBillingOption = true;
                }
            }
        }, withDelay ? 1000 : 1);
    }

    async handleCountryAutoComplete(ev) {
        this.manualBillingAddress = new google.maps.places.AutocompleteService();
        this.manualBillingAddress.getPlacePredictions({
            input: ev?.target?.value,
            types: ['address']
        },
        (placePrediction) => {
            this.googleMapsPlace = new google.maps.places.PlacesService(document.createElement('div'));
            this.googleMapsPlace.getDetails({ placeId: placePrediction[0].place_id }, (placeDetails) => {
                this.billing = {
                    city: this.helper.getAddressComponent(placeDetails, 'locality') || this.helper.getAddressComponent(placeDetails, 'administrative_area_level_1'),
                    state: this.helper.getAddressShortName(placeDetails, 'administrative_area_level_1'),
                    countryCode: this.helper.getAddressShortName(placeDetails, 'country'),
                    countryName: this.helper.getAddressComponent(placeDetails, 'country'),
                    zip: this.helper.getAddressShortName(placeDetails, 'postal_code'),
                    street: placeDetails.name
                };
            });
        });
    }

    async updateBillingAddress() {
        this.showFields = !this.showFields;
        if (this.showFields) {
            this.loadGooglePlacesInput();
        } else {
            this.validator.reset();
            this.checkBillingAddressAutocompleteIfEmpty();
        }
    }

    checkBillingAddressAutocompleteIfEmpty() {
        if (this.billingAddressInput.querySelector('input').value === '') {
            this.billing = null;
            this.showGreenCheckMark = false;
            this.showErrorCheckMark = false;
            this.billingAddressIsValid = false;
        }
    }

    async countryUpdatedOnKeyPress() {
        this.timeouts = [this.countryTimeout];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.countryTimeout = setTimeout(async () => {
            if (!this.billing?.countryName) {
                this.billing.countryCode = null;
            }

            const countries = Country.getAllCountries();
            this.billing.countryCode = countries.find(country => country.name.toLowerCase() === this.billing?.countryName?.toLowerCase())?.isoCode;

            await this.handleBillingValidation();
            if (this.showGreenCheckMark) {
                this.showFields = this.showBillingOption = false;
            }
        }, 1000);
    }

    async addressUpdatedOnKeyPress() {
        this.timeouts = [this.addressTimeout];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.addressTimeout = setTimeout(async () => {
            await this.handleBillingValidation();
            if (this.showGreenCheckMark) {
                this.showFields = this.showBillingOption = false;
            }
        }, 1000);
    }

    async focusHandle(ev) {
        const { type } = ev;
        this.timeouts = [this.focusInStopwatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);

        if (type === 'focusin') {
            this.showBillingOption = true;
            this.billingAddressIsValid = false;
            await this.loadGooglePlacesInput();
        }
    }

    async handleBillingValidation() {
        if (this.billing?.zip !== undefined && this.billing?.zip !== null) {
            ValidationRules
                .ensure('city').required()
                .ensure('street').required()
                .ensure('zip').required()
                .satisfies((zip) => {
                    if (this.billing.countryCode && !postcodeValidatorExistsForCountry(this.billing.countryCode) || zip && this.billing.countryCode && postcodeValidator(zip, this.billing.countryCode) === true) {
                        return true;
                    } else {
                        return false;
                    }
                }).on(this.billing);
            const rules = await this.validator.validate();
            this.billingAddressIsValid = rules.valid;
            this.showGreenCheckMark = this.billingAddressIsValid;
            this.showErrorCheckMark = !this.billingAddressIsValid;
            if (!this.autocompletedAddress) {
                this.autocompletedAddress = this.billing.street;
            }
            if (this.billingAddressIsValid && this.billingAddressInput) {
                const { city, state, zip, street, countryCode } = this.billing;
                const strBilling = `${street ?? ''}, ${city ?? ''} ${zip ?? ''}, ${state ?? ''}, ${countryCode ?? ''}`;
                this.billingAddressInput.querySelector('input').value = this.autocompletedAddress = strBilling;
                this.billingAddressInput.querySelector('mdc-floating-label').classList.add('mdc-floating-label--float-above');
                this.showFields = false;
            } else {
                this.toastService.showToast('Error', 'Billing address does not match ZIP/Postal code. Please check your information again.', 'error');
            }
        }
    }
}
