import env from '../config/environment.json';

const apiEndpoint = (): string => env.apiEndpoint;

const signalrEndpoint = () => env.signalrEndpoint;

const apiPdfEndpoint = () => env.apiPdfEndpoint;

const websiteShortCode = (): string => env.websiteShortCode;

const getAWSBucketEndpoint = (endpoint: string): string => env.amazonAwsUrl.replace('dynamic', endpoint);

const debug = (): boolean => env.debug;

const baseUrl = (): string => env.baseUrl;

const chicksGoldBaseUrl = (): string => env.chicksGoldBaseUrl;

const accKingsBaseUrl = (): string => env.accKingsBaseUrl;

const gamerTotalBaseUrl = (): string => env.gamerTotalBaseUrl;

const divicaSalesBaseUrl = (): string => env.divicaSalesBaseUrl;

const chicksXBlogsUrl = (): string => env.chicksXBlogsUrl;

const firebaseConfig = () => env.firebaseConfig;

const adminPanelUrl = () => env.adminPanelUrl;

const googleSignInClientId = () => env.googleSignInClientId;

const discordAuthUrl = () => env.discordAuthUrl;

const discordSignInUrl = () => env.discordSignInUrl;

const discordSignUpUrl = () => env.discordSignUpUrl;

const applePayMerchantId = () => env.applePayMerchantId;

const appleClientId = () => env.appleClientId;

const environmentName = () => env.environment;

const fingerprintApiKey = () => env.fingerprintApiKey;

const fingerprintDomainUrl = () => env.fingerprintDomainUrl;

const chicksGroupBaseUrl = () => env.chicksGroupBaseUrl;

const clientId = () => env.clientId;

const googleMapsKey = (): string => env.googleMaps;

const stripeConfig = () => env.stripe;

const vgsInboundRouteId = () => env.vgsInboundRouteId;

const vgsGooglePayInboundRouteId = () => env.vgsGooglePayInboundRouteId;

const vgsApplePayInboundRouteId = () => env.vgsApplePayInboundRouteId;

const vgsVaultId = () => env.vgsVaultId;

const vgsEnv = () => env.vgsEnv;

const vgsOrganizationId = () => env.vgsOrganizationId;

const sardineAiClientId = () => env.sardineAi.clientId;

const sardineAiEnvironment = () => env.sardineAi.environment as 'sandbox' | 'production';

const sardineAiApiSubdomain = () => env.sardineAi.apiSubdomain;

const sardineAiPixelSubdomain = () => env.sardineAi.pixelSubdomain;

const countries = (): { code: string; name: string; }[] => env.countries;

const googlePayMerchantId = (): string => env.googlePayMerchantId;

export default this;

export {
    adminPanelUrl,
    signalrEndpoint,
    apiPdfEndpoint,
    websiteShortCode,
    apiEndpoint,
    getAWSBucketEndpoint,
    firebaseConfig,
    debug,
    baseUrl,
    chicksGoldBaseUrl,
    accKingsBaseUrl,
    gamerTotalBaseUrl,
    divicaSalesBaseUrl,
    chicksXBlogsUrl,
    googleSignInClientId,
    discordAuthUrl,
    discordSignInUrl,
    discordSignUpUrl,
    applePayMerchantId,
    appleClientId,
    environmentName,
    fingerprintApiKey,
    fingerprintDomainUrl,
    chicksGroupBaseUrl,
    clientId,
    googleMapsKey,
    stripeConfig,
    vgsInboundRouteId,
    vgsGooglePayInboundRouteId,
    vgsApplePayInboundRouteId,
    vgsVaultId,
    vgsEnv,
    vgsOrganizationId,
    sardineAiClientId,
    sardineAiEnvironment,
    sardineAiApiSubdomain,
    sardineAiPixelSubdomain,
    countries,
    googlePayMerchantId
};
