export class BillingAddress {
    street?: string;
    countryCode?: string;
    countryName?: string;
    city?: string;
    state?: string;
    zip?: string;
}

export interface Billing {
    street?: string;
    city?: string;
    state?: string;
    country?: string;
    zip?: string;
}
