// Module
var code = `<template>
    <div id="cx-amount-payment-selector" ref="amountPaymentSelector">
        <div class="amount-payment-container" tabindex="1">
            <div class="double-input-extra-field">
                <cx-payment-method-selector
                    ref="myPaymentSelector"
                    selected-payment-method.two-way="selectedPaymentMethod"
                    given-currency-selected.bind="giveSelectedCurrency"
                    receiving-currency-selected.bind="receiveSelectedCurrency"
                    game.bind="game"
                    home="true"
                ></cx-payment-method-selector>
            </div>
        </div>
    </div>
</template>
`;
// Exports
export default code;