/* eslint-disable camelcase */
import { IpAddress } from 'services/models/user/ipAddress';

const emailDisallowConRegExr = () => new RegExp(/^(\s+)?[\w-.+%-]+@([\w-]+\.)+(?!con$)[\w-]{1,4}(\s+)?$/);

const onlyNumberRegExp = () => new RegExp('\\d');

const numbersAndSlashExp = () => new RegExp('^[0-9/]+$');
const isStaticAsset = () => new RegExp('!/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i');

const buttonRegExp = () => new RegExp(/\${button:\s*([^,]+),\s*link:\s*([^,}]+)(?:,\s*display:\s*([^}]+))?}/g);

export const automaticPaymentMethods = () => ['apple-pay-direct', 'google-pay-direct', 'stripe'];

export const cardTypesFor3DSTrigger = () => ['visa', 'mastercard', 'amex', 'discover'];

export const automaticPaymentMethodsWithout = () => ['bluesnap-checkout', 'skrill-direct', 'google-pay-direct', 'apple-pay-direct'];

export const automaticPaymentMethodsWithout3DS = () => ['paymentlync'];

export const paymentMethodListForShowingButton = () => ['bluesnap-checkout', 'skrill-direct', 'google-pay-direct', 'game-currency', 'apple-pay-direct'];

const ForceCurrencyEvent = 'force-currency';
const UserUpdatedEvent = 'user-updated';
const PhoneUpdatedEvent = 'phone-updated';
const VeriffVerificationEvent = 'veriff-verification';
const CurrencyChangedEvent = 'currency-change';
const SizeChangedEvent = 'size-changed';
const MethodPayoutableChangedEvent = 'method-payoutable';
const BankAccountChangedEvent = 'bank-account-changed';
const ForcePaymentMethodEvent = 'force-payment-method';
const SpecialSelectorEvent = 'special-selector-focus-toggle';
const OpenedVeriffDialogEvent = 'opened-veriff-dialog';
const LanguageChangedEvent = 'language-change';
const AdminViewUpdatedEvent = 'admin-view-updated';
const ResetPaymentMethodEvent = 'reset-payment-method';
const DesktopBreakpoint = 992;
const TabletBreakpoint = 768;
const PhoneBreakpoint = 576;

export const device__smallphone = 320;
export const device__smallphoneplus = 375;
export const device__mediumphone = 425;
export const device__phone = 576;
export const device__smalltablet = 720;
export const device__tablet = 768;
export const device__smalldesktop = 952;
export const device__desktop = 992;
export const device__laptop = 1024;
export const device__mediumdesktop = 1080;
export const device__widescreen = 1200;
export const device__widescreenplus = 1235;
export const device__mediumwidescreen = 1400;
export const device__laptoplarge = 1440;
export const device__extrascreen = 1500;
export const device__extrawidescreen = 1550;
export const device__extralargeplusscreen = 1920;
export const device__extralargescreen = 3839;

export const PAGE_NAMES = {
    HOME: 'Home',
    CURRENCY: 'Currency',
    CONTACT: 'Contact Us',
    BLOG: 'Blog',
    FAQ: 'Faq',
    ABOUT: 'About Us',
    PRIVACY_POLICY: 'Privacy Policy',
    TERMS_OF_SERVICE: 'Terms of Service',
    COPYRIGHT_POLICY: 'Copyright Policy',
    CART: 'Cart',
    CUSTOMER_PORTAL: 'Customer Portal',
    EXPIRED_LINK: 'Expired Link',
    SITE_DOWN: 'Site Down',
    RATE_LIMIT: 'Rate Limit',
    ORDER_DETAILS: 'Order Details',
    BUG_BOUNTY: 'Bug Bounty',
    SITEMAP: 'Sitemap',
    SUBSCRIPTION: 'Subscription',
    BALANCE: 'Balance',
    BALANCE_TOP_UP: 'balance Top Up',
    COOKIES: 'Cookies',
    CAREERS: 'Careers',
    NOTIFICATIONS: 'Notifications',
    LOCATIONS: 'Locations',
};

const LanguageAbbreviations = [
    { name: 'Afar', abbreviation: 'AFR' },
    { name: 'Abkhazian', abbreviation: 'ABK' },
    { name: 'Avestan', abbreviation: 'AVE' },
    { name: 'Afrikaans', abbreviation: 'AFR' },
    { name: 'Akan', abbreviation: 'AKA' },
    { name: 'Amharic', abbreviation: 'AMH' },
    { name: 'Aragonese', abbreviation: 'ARG' },
    { name: 'Arabic', abbreviation: 'ARA' },
    { name: 'Assamese', abbreviation: 'ASM' },
    { name: 'Avaric', abbreviation: 'AVA' },
    { name: 'Aymara', abbreviation: 'AYM' },
    { name: 'Azerbaijani', abbreviation: 'AZE' },
    { name: 'Bashkir', abbreviation: 'BAK' },
    { name: 'Belarusian', abbreviation: 'BEL' },
    { name: 'Bulgarian', abbreviation: 'BUL' },
    { name: 'Bihari Languages', abbreviation: 'BIH' },
    { name: 'Bislama', abbreviation: 'BIS' },
    { name: 'Bambara', abbreviation: 'BAM' },
    { name: 'Bengali', abbreviation: 'BEN' },
    { name: 'Tibetan', abbreviation: 'TIB' },
    { name: 'Breton', abbreviation: 'BRE' },
    { name: 'Bosnian', abbreviation: 'BOS' },
    { name: 'Catalan', abbreviation: 'CAT' },
    { name: 'Chechen', abbreviation: 'CHE' },
    { name: 'Chamorro', abbreviation: 'CHA' },
    { name: 'Chinese', abbreviation: 'CHN' },
    { name: 'Corsican', abbreviation: 'COS' },
    { name: 'Cree', abbreviation: 'CRE' },
    { name: 'Czech', abbreviation: 'CES' },
    { name: 'Old Slavonic', abbreviation: 'CHU' },
    { name: 'Chuvash', abbreviation: 'CHV' },
    { name: 'Welsh', abbreviation: 'CYM' },
    { name: 'Danish', abbreviation: 'DAN' },
    { name: 'German', abbreviation: 'DEU' },
    { name: 'Divehi', abbreviation: 'DIV' },
    { name: 'Dzongkha', abbreviation: 'DZO' },
    { name: 'Ewe', abbreviation: 'EWE' },
    { name: 'Greek', abbreviation: 'ELL' },
    { name: 'English', abbreviation: 'ENG' },
    { name: 'Esperanto', abbreviation: 'EPO' },
    { name: 'Spanish', abbreviation: 'ESP' },
    { name: 'Estonian', abbreviation: 'EST' },
    { name: 'Basque', abbreviation: 'EUS' },
    { name: 'Persian', abbreviation: 'FAS' },
    { name: 'Fulah', abbreviation: 'FUL' },
    { name: 'Finnish', abbreviation: 'FIN' },
    { name: 'Fijian', abbreviation: 'FIJ' },
    { name: 'Faroese', abbreviation: 'FRO' },
    { name: 'French', abbreviation: 'FRA' },
    { name: 'Western Frisian', abbreviation: 'FRY' },
    { name: 'Irish', abbreviation: 'GLE' },
    { name: 'Gaelic Gaelic', abbreviation: 'GLA' },
    { name: 'Galician', abbreviation: 'GLG' },
    { name: 'Manx', abbreviation: 'GLV' },
    { name: 'Guarani', abbreviation: 'GRN' },
    { name: 'Gujarati', abbreviation: 'GUJ' },
    { name: 'Haitian Creole', abbreviation: 'HAT' },
    { name: 'Hausa', abbreviation: 'HAU' },
    { name: 'Hebrew', abbreviation: 'HEB' },
    { name: 'Herero', abbreviation: 'HER' },
    { name: 'Hindi', abbreviation: 'HIN' },
    { name: 'Hiri Motu', abbreviation: 'HMO' },
    { name: 'Croatian', abbreviation: 'HRV' },
    { name: 'Hungarian', abbreviation: 'HUN' },
    { name: 'Armenian', abbreviation: 'HYE' },
    { name: 'Interlingua', abbreviation: 'INA' },
    { name: 'Indonesian', abbreviation: 'IND' },
    { name: 'Interlingue', abbreviation: 'ILE' },
    { name: 'Igbo', abbreviation: 'IBO' },
    { name: 'Sichuan Yi', abbreviation: 'III' },
    { name: 'Inupiaq', abbreviation: 'IPK' },
    { name: 'Ido', abbreviation: 'IDO' },
    { name: 'Icelandic', abbreviation: 'ISL' },
    { name: 'Italian', abbreviation: 'ITA' },
    { name: 'Inuktitut', abbreviation: 'IKU' },
    { name: 'Japanese', abbreviation: 'JPN' },
    { name: 'Javanese', abbreviation: 'JAV' },
    { name: 'Georgian', abbreviation: 'KAT' },
    { name: 'Kongo', abbreviation: 'KON' },
    { name: 'Kikuyu', abbreviation: 'KIK' },
    { name: 'Kuanyama', abbreviation: 'KUA' },
    { name: 'Kazakh', abbreviation: 'KAZ' },
    { name: 'Kalaallisut', abbreviation: 'KAL' },
    { name: 'Central Khmer', abbreviation: 'KHM' },
    { name: 'Kannada', abbreviation: 'KAN' },
    { name: 'Korean', abbreviation: 'KOR' },
    { name: 'Kanuri', abbreviation: 'KAU' },
    { name: 'Kashmiri', abbreviation: 'KAS' },
    { name: 'Kurdish', abbreviation: 'KUR' },
    { name: 'Komi', abbreviation: 'KOM' },
    { name: 'Cornish', abbreviation: 'COR' },
    { name: 'Kirghiz', abbreviation: 'KIR' },
    { name: 'Latin', abbreviation: 'LAT' },
    { name: 'Luxembourgish', abbreviation: 'LTZ' },
    { name: 'Ganda', abbreviation: 'LUG' },
    { name: 'Limburgan', abbreviation: 'LIM' },
    { name: 'Lingala', abbreviation: 'LIN' },
    { name: 'Lao', abbreviation: 'LAO' },
    { name: 'Lithuanian', abbreviation: 'LIT' },
    { name: 'Luba-Katanga', abbreviation: 'LUB' },
    { name: 'Latvian', abbreviation: 'LAV' },
    { name: 'Malagasy', abbreviation: 'MLG' },
    { name: 'Marshallese', abbreviation: 'MAH' },
    { name: 'Maori', abbreviation: 'MRI' },
    { name: 'Macedonian', abbreviation: 'MKD' },
    { name: 'Malayalam', abbreviation: 'MAL' },
    { name: 'Mongolian', abbreviation: 'MON' },
    { name: 'Marathi', abbreviation: 'MAR' },
    { name: 'Malay', abbreviation: 'MSA' },
    { name: 'Maltese', abbreviation: 'MLT' },
    { name: 'Burmese', abbreviation: 'MYA' },
    { name: 'Nauru', abbreviation: 'NAU' },
    { name: 'Navajo', abbreviation: 'NAV' },
    { name: 'Ndebele', abbreviation: 'NBL' },
    { name: 'Nepali', abbreviation: 'NEP' },
    { name: 'Ndonga', abbreviation: 'NDO' },
    { name: 'Dutch', abbreviation: 'NLD' },
    { name: 'Norwegian', abbreviation: 'NNO' },
    { name: 'BokmÃ¥l', abbreviation: 'NOB' },
    { name: 'Norwegian', abbreviation: 'NOR' },
    { name: 'Chichewa', abbreviation: 'NYA' },
    { name: 'Occitan', abbreviation: 'OCI' },
    { name: 'Ojibwa', abbreviation: 'OJI' },
    { name: 'Oriya', abbreviation: 'ORI' },
    { name: 'Oromo', abbreviation: 'ORM' },
    { name: 'Ossetian', abbreviation: 'OSS' },
    { name: 'Panjabi', abbreviation: 'PAN' },
    { name: 'Pali', abbreviation: 'PAL' },
    { name: 'Polish', abbreviation: 'POL' },
    { name: 'Pushto', abbreviation: 'PUS' },
    { name: 'Portuguese', abbreviation: 'POR' },
    { name: 'Quechua', abbreviation: 'QUE' },
    { name: 'Romansh', abbreviation: 'ROH' },
    { name: 'Rundi', abbreviation: 'RUN' },
    { name: 'Romanian', abbreviation: 'RON' },
    { name: 'Russian', abbreviation: 'RUS' },
    { name: 'Kinyarwanda', abbreviation: 'KIN' },
    { name: 'Sanskrit', abbreviation: 'SAN' },
    { name: 'Sardinian', abbreviation: 'SRD' },
    { name: 'Sindhi', abbreviation: 'SND' },
    { name: 'Northern Sami', abbreviation: 'SME' },
    { name: 'Samoan', abbreviation: 'SMO' },
    { name: 'Sango', abbreviation: 'SAG' },
    { name: 'Serbian', abbreviation: 'SRP' },
    { name: 'Shona', abbreviation: 'SHN' },
    { name: 'Sichuan Yi', abbreviation: 'III' },
    { name: 'Sindhi', abbreviation: 'SND' },
    { name: 'Sinhala', abbreviation: 'SIN' },
    { name: 'Slovak', abbreviation: 'SLK' },
    { name: 'Slovenian', abbreviation: 'SLV' },
    { name: 'Northern Sami', abbreviation: 'SME' },
    { name: 'Samoan', abbreviation: 'SMO' },
    { name: 'Sango', abbreviation: 'SAG' },
    { name: 'Serbian', abbreviation: 'SRP' },
    { name: 'Gaelic', abbreviation: 'GLE' },
    { name: 'Shona', abbreviation: 'SHN' },
    { name: 'Sindhi', abbreviation: 'SND' },
    { name: 'Sinhala', abbreviation: 'SIN' },
    { name: 'Slovak', abbreviation: 'SLK' },
    { name: 'Slovenian', abbreviation: 'SLV' },
    { name: 'Somali', abbreviation: 'SOM' },
    { name: 'Sotho, Southern', abbreviation: 'SOT' },
    { name: 'Spanish', abbreviation: 'SPA' },
    { name: 'Sardinian', abbreviation: 'SRD' },
    { name: 'Sindhi', abbreviation: 'SND' },
    { name: 'Northern Sami', abbreviation: 'SME' },
    { name: 'Samoan', abbreviation: 'SMO' },
    { name: 'Sango', abbreviation: 'SAG' },
    { name: 'Serbian', abbreviation: 'SRP' },
    { name: 'Gaelic', abbreviation: 'GLE' },
    { name: 'Shona', abbreviation: 'SHN' },
    { name: 'Sindhi', abbreviation: 'SND' },
    { name: 'Sinhala', abbreviation: 'SIN' },
    { name: 'Slovak', abbreviation: 'SLK' },
    { name: 'Slovenian', abbreviation: 'SLV' },
    { name: 'Somali', abbreviation: 'SOM' },
    { name: 'Sotho, Southern', abbreviation: 'SOT' },
    { name: 'Spanish', abbreviation: 'SPA' },
    { name: 'Sundanese', abbreviation: 'SUN' },
    { name: 'Swahili', abbreviation: 'SWA' },
    { name: 'Swati', abbreviation: 'SSW' },
    { name: 'Swedish', abbreviation: 'SWE' },
    { name: 'Tahitian', abbreviation: 'TAH' },
    { name: 'Tamil', abbreviation: 'TAM' },
    { name: 'Tatar', abbreviation: 'TAT' },
    { name: 'Telugu', abbreviation: 'TEL' },
    { name: 'Tajik', abbreviation: 'TGK' },
    { name: 'Tagalog', abbreviation: 'TLG' },
    { name: 'Thai', abbreviation: 'THA' },
    { name: 'Tibetan', abbreviation: 'TIB' },
    { name: 'Tigrinya', abbreviation: 'TIR' },
    { name: 'Tonga', abbreviation: 'TON' },
    { name: 'Tswana', abbreviation: 'TSN' },
    { name: 'Tsonga', abbreviation: 'TSO' },
    { name: 'Turkmen', abbreviation: 'TUK' },
    { name: 'Turkish', abbreviation: 'TUR' },
    { name: 'Tatar', abbreviation: 'TAT' },
    { name: 'Tajik', abbreviation: 'TGK' },
    { name: 'Tagalog', abbreviation: 'TLG' },
    { name: 'Thai', abbreviation: 'THA' },
    { name: 'Tibetan', abbreviation: 'TIB' },
    { name: 'Tigrinya', abbreviation: 'TIR' },
    { name: 'Tonga', abbreviation: 'TON' },
    { name: 'Tswana', abbreviation: 'TSN' },
    { name: 'Tsonga', abbreviation: 'TSO' },
    { name: 'Turkmen', abbreviation: 'TUK' },
    { name: 'Turkish', abbreviation: 'TUR' },
    { name: 'Twi', abbreviation: 'TWI' },
    { name: 'Uighur', abbreviation: 'UIG' },
    { name: 'Ukrainian', abbreviation: 'UKR' },
    { name: 'Urdu', abbreviation: 'URD' },
    { name: 'Uzbek', abbreviation: 'UZB' },
    { name: 'Venda', abbreviation: 'VEN' },
    { name: 'Vietnamese', abbreviation: 'VIE' },
    { name: 'VolapÃ¼k', abbreviation: 'VOL' },
    { name: 'Walloon', abbreviation: 'WLN' },
    { name: 'Wolof', abbreviation: 'WOL' },
    { name: 'Xhosa', abbreviation: 'XHO' },
    { name: 'Yiddish', abbreviation: 'YID' },
    { name: 'Yoruba', abbreviation: 'YOR' },
    { name: 'Zhuang', abbreviation: 'ZHA' },
    { name: 'Zulu', abbreviation: 'ZUL' }
];

export const getUSStates = () => {
    return [
        { name: 'Alabama', abbreviation: 'AL' },
        { name: 'Alaska', abbreviation: 'AK' },
        { name: 'American Samoa', abbreviation: 'AS' },
        { name: 'Arizona', abbreviation: 'AZ' },
        { name: 'Arkansas', abbreviation: 'AR' },
        { name: 'California', abbreviation: 'CA' },
        { name: 'Colorado', abbreviation: 'CO' },
        { name: 'Connecticut', abbreviation: 'CT' },
        { name: 'Delaware', abbreviation: 'DE' },
        { name: 'District of Columbia', abbreviation: 'DC' },
        { name: 'States of Micronesia', abbreviation: 'FM' },
        { name: 'Florida', abbreviation: 'FL' },
        { name: 'Georgia', abbreviation: 'GA' },
        { name: 'Guam', abbreviation: 'GU' },
        { name: 'Hawaii', abbreviation: 'HI' },
        { name: 'Idaho', abbreviation: 'ID' },
        { name: 'Illinois', abbreviation: 'IL' },
        { name: 'Indiana', abbreviation: 'IN' },
        { name: 'Iowa', abbreviation: 'IA' },
        { name: 'Kansas', abbreviation: 'KS' },
        { name: 'Kentucky', abbreviation: 'KY' },
        { name: 'Louisiana', abbreviation: 'LA' },
        { name: 'Maine', abbreviation: 'ME' },
        { name: 'Marshall Islands', abbreviation: 'MH' },
        { name: 'Maryland', abbreviation: 'MD' },
        { name: 'Massachusetts', abbreviation: 'MA' },
        { name: 'Michigan', abbreviation: 'MI' },
        { name: 'Minnesota', abbreviation: 'MN' },
        { name: 'Mississippi', abbreviation: 'MS' },
        { name: 'Missouri', abbreviation: 'MO' },
        { name: 'Montana', abbreviation: 'MT' },
        { name: 'Nebraska', abbreviation: 'NE' },
        { name: 'Nevada', abbreviation: 'NV' },
        { name: 'New Hampshire', abbreviation: 'NH' },
        { name: 'New Jersey', abbreviation: 'NJ' },
        { name: 'New Mexico', abbreviation: 'NM' },
        { name: 'New York', abbreviation: 'NY' },
        { name: 'North Carolina', abbreviation: 'NC' },
        { name: 'North Dakota', abbreviation: 'ND' },
        { name: 'Northern Mariana Islands', abbreviation: 'MP' },
        { name: 'Ohio', abbreviation: 'OH' },
        { name: 'Oklahoma', abbreviation: 'OK' },
        { name: 'Oregon', abbreviation: 'OR' },
        { name: 'Palau', abbreviation: 'PW' },
        { name: 'Pennsylvania', abbreviation: 'PA' },
        { name: 'Puerto Rico', abbreviation: 'PR' },
        { name: 'Rhode Island', abbreviation: 'RI' },
        { name: 'South Carolina', abbreviation: 'SC' },
        { name: 'South Dakota', abbreviation: 'SD' },
        { name: 'Tennessee', abbreviation: 'TN' },
        { name: 'Texas', abbreviation: 'TX' },
        { name: 'Utah', abbreviation: 'UT' },
        { name: 'Vermont', abbreviation: 'VT' },
        { name: 'Virgin Islands', abbreviation: 'VI' },
        { name: 'Virginia', abbreviation: 'VA' },
        { name: 'Washington', abbreviation: 'WA' },
        { name: 'West Virginia', abbreviation: 'WV' },
        { name: 'Wisconsin', abbreviation: 'WI' },
        { name: 'Wyoming', abbreviation: 'WY' }
    ];
};

export const getCanadaProvinces = () => {
    return [
        { name: 'Alberta', abbreviation: 'AB', hst: 0.05 },
        { name: 'British Columbia', abbreviation: 'BC', hst: 0.12 },
        { name: 'Manitoba', abbreviation: 'MB', hst: 0.12 },
        { name: 'New Brunswick', abbreviation: 'NB', hst: 0.15 },
        { name: 'Newfoundland and Labrador', abbreviation: 'NL', hst: 0.15 },
        { name: 'Northwest Territories', abbreviation: 'NT', hst: 0.05 },
        { name: 'Nova Scotia', abbreviation: 'NS', hst: 0.15 },
        { name: 'Nunavut', abbreviation: 'NU', hst: 0.05 },
        { name: 'Ontario', abbreviation: 'ON', hst: 0.13 },
        { name: 'Prince Edward Island', abbreviation: 'PE', hst: 0.15 },
        { name: 'Quebec', abbreviation: 'QC', hst: 0.14975 },
        { name: 'Saskatchewan', abbreviation: 'SK', hst: 0.11 },
        { name: 'Yukon Territory', abbreviation: 'YT', hst: 0.05 }
    ];
};

const DefaultLocationData: IpAddress = {
    ip: '',
    countryCode: 'CA',
    countryName: 'Canada',
    regionName: 'Ontario',
    vpn: false,
    tor: false,
    proxy: false,
    userId: null,
    user: null,
    latitude: 43.5996,
    longitude: -79.7685,
    city: 'Mississauga',
    id: 0,
    createdDate: null,
    updatedDate: null,
    isDeleted: false,
    organization: 'ChicksX',
    zip: 'L5M 2G6',
    connectionType: '',
    host: '',
    isp: '',
    timeZone: 'America/Toronto'
};

export default this;

export {
    isStaticAsset,
    emailDisallowConRegExr,
    onlyNumberRegExp,
    numbersAndSlashExp,
    buttonRegExp,
    ForceCurrencyEvent,
    PhoneUpdatedEvent,
    UserUpdatedEvent,
    VeriffVerificationEvent,
    CurrencyChangedEvent,
    SizeChangedEvent,
    MethodPayoutableChangedEvent,
    DesktopBreakpoint,
    TabletBreakpoint,
    ForcePaymentMethodEvent,
    SpecialSelectorEvent,
    PhoneBreakpoint,
    OpenedVeriffDialogEvent,
    LanguageChangedEvent,
    AdminViewUpdatedEvent,
    LanguageAbbreviations,
    DefaultLocationData,
    BankAccountChangedEvent,
    ResetPaymentMethodEvent
};
