import './cx-user-dropdown.scss';
import { Router } from 'aurelia-router';
import { autoinject, bindable, computedFrom, observable } from 'aurelia-framework';
import { apiEndpoint, adminPanelUrl } from 'environment';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { CustomerService } from 'services/customer-service';
import { Helper } from 'resources/helpers/helper';
import { WebsiteService } from 'services/website-service';
import { SessionService } from 'services/session-service';
import { LanguageRouteValueConverter } from 'resources/value-converters/language-route';
import { ToastService } from 'services/toast-service';
import { ToastType } from 'services/models/toast';

@autoinject()
export class CxUserDropdown {
    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private customerService: CustomerService,
        private helper: Helper,
        private websiteService: WebsiteService,
        private sessionService: SessionService,
        private languageRouteValueConverter: LanguageRouteValueConverter,
        private toastService: ToastService
    ) {
        this.helper.getResolutions(this);
        this.helper.widthHandler(this);
    }

    @bindable navigationItems;
    @bindable clickHandler;
    @bindable hideSubmenuOnMouseLeave = false;
    @bindable userClass;
    @bindable buttonClass;
    @bindable class;
    @bindable user;
    @bindable pageLoading: boolean = false;
    firstUserName;
    switchElement;
    userVeriffData;
    iconsNumbers;
    isLoading = true;
    environment = apiEndpoint();
    iconOutlineStates = this.createIcons(this.helper.range(8), 'icon', false);

    avatarDialogModule;
    otherAvatarDialogShow;
    openDropdown = false;
    openByClick = false;
    pages;
    avatarModalSubscriber: Subscription;
    sizeChangedSubscriber: Subscription;
    width: number;
    clickOutsideEvent: void;
    navigation;
    dropdownMenu;
    dropdownMenuButton;
    showableClasses = ['show', 'force-show'];
    bottomClass:string = '';
    navbar;
    @observable isClicked:boolean = false;
    @observable isHovered:boolean = false;
    pageChangeSubscriber: Subscription;
    notificationClickedSubscription;
    notificationsIsHovered = false;
    customerPortalPageRoute;
    viewingAsAdmin: boolean;
    formattedAccordionCategories;
    search: string;
    drawer;
    adminURL: string = adminPanelUrl();
    baseItems = [
        { name: 'Profile', slug: 'profile' },
        { name: 'Subscription', slug: 'subscription' },
        { name: 'Orders', slug: 'orders', items: [{ name: 'Purchased', slug: 'purchased' }, { name: 'Sold', slug: 'sold' }, { name: 'Swap', slug: 'swap' }] },
        { name: 'Balance', slug: 'balance', items: [{ name: 'History', slug: 'balance/history' }, { name: 'Withdraw', slug: 'balance/withdraw' }, { name: 'Top Up', slug: 'balance/top-up' }] },
        { name: 'Verification', slug: 'verification', items: [{ name: 'Mobile Verification', slug: 'verification' }, { name: 'Email Verification', slug: 'verification' }, { name: 'ID Verification', slug: 'verification' }, { name: 'Adress Verification', slug: 'verification' }] },
        { name: 'Security', slug: 'security', items: [{ name: 'Reset Password', slug: 'security/reset-password' }, { name: '2FA Settings', slug: 'security/two-fa-settings' }, { name: 'Withdraw Password', slug: 'security/withdraw' }, { name: 'Delete Account', slug: 'security' }] },
        { name: 'Support', slug: 'support' }
    ];

    async attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.customerPortalPageRoute = await this.websiteService.getRouteOrDefault('Customer Portal');
        this.viewingAsAdmin = await this.sessionService.getAdminView() && await this.sessionService.checkRolesForPanelAccess();
        this.formattedAccordionCategories = await this.getFormattedCategoriesForProfile();
        this.handleEventSubscriptions();
    }

    bind(bindingContext, overrideContext) {
        this.navbar = overrideContext.bindingContext;
    }

    detached() {
        this.avatarModalSubscriber?.dispose();
        this.sizeChangedSubscriber?.dispose();
        this.pageChangeSubscriber?.dispose();
        this.notificationClickedSubscription?.dispose();
        document.removeEventListener('click', () => {}, true);
    }

    handleEventSubscriptions() {
        this.avatarModalSubscriber = this.eventAggregator.subscribe('avatar-dialog-open-modal', async() => {
            this.handleAvatarDialogOpen();
        });

        this.sizeChangedSubscriber = this.eventAggregator.subscribe('size-changed', async payload => {
            this.width = payload.width;
        });

        this.eventAggregator.subscribe('user-updated', async payload => {
            this.user = payload.user;
        });

        this.clickOutsideEvent = document.addEventListener('click', event => {
            const targetElement = event.target as Element;
            let shouldReturn: boolean | Element = targetElement.closest('cx-user-dropdown');
            shouldReturn = shouldReturn || this.helper.elementsContainAnyClass(this.getShowable(), this.showableClasses);
            if (shouldReturn) return;
            this.isClicked = false;
            this.isHovered = false;
            this.bottomClass = '';
            this.removeShow();
            this.navigation?.collapseAll();
        });

        this.pageChangeSubscriber = this.eventAggregator.subscribe('router:navigation:success', () => {
            this.isClicked = false;
            this.isHovered = false;
            this.bottomClass = '';
            this.removeShow();
            this.navigation?.collapseAll();
        });

        this.notificationClickedSubscription = this.eventAggregator.subscribe('rigth-element-is-hovered', payload => {
            if (!Object.keys(payload).includes('notificationsIsHovered')) return;
            this.notificationsIsHovered = payload.notificationsIsHovered;
        });
    }

    removeShow() {
        this.getShowable().forEach(element => this.showableClasses.map(cls => element.classList.toggle(cls, false)));
    }

    navigate(slug) {
        this.clickHandler(slug);
        this.handlePageChange();
    }

    handlePageChange() {
        this.isClicked = false;
    }

    getShowable = () => [this.dropdownMenu, this.dropdownMenuButton].filter(x => x);

    1() {
        this.isHovered = this.isClicked = !this.isClicked;
        this.getShowable().map(x => {
            const list = x.classList;
            list.toggle('show', this.isClicked);
        });
    }

    handleAvatarDialogOpen() {
        if (!this.otherAvatarDialogShow) {
            this.avatarDialogModule.dialog.open();
            const menuLinks = document.querySelectorAll('.dropdown-item');
            menuLinks.forEach((link: HTMLAnchorElement) => link.blur());
        }
    }

    @computedFrom('user.firstName', 'user.lastName', 'userVeriffData.dateOfBirth', 'userVeriffData.firstName', 'userVeriffData.lastName')
    get firstName() {
        return this.helper.userNameFormat(this.user, this.userVeriffData).split(' ')[0];
    }

    async userChanged() {
        if (!this.user) return;
        this.userVeriffData = await this.customerService.getVeriffUserData(this.user.id);
    }

    createIcons(array: number[], name: string, value: boolean) {
        return array.reduce((obj, num) => ({ ...obj, [`${name}${num}`]: value }), {});
    }

    materialIconClass(icon: boolean): string {
        return icon ? 'material-icons' : 'material-icons-outlined';
    }

    getFirstName(name: string): string {
        return name.split(' ')[0];
    }

    onMouseHover() {
        if (this.pageLoading || this.isClicked || this.notificationsIsHovered) return;
        const navbar = document.getElementById('navigation-bar');
        const navbarIsPurple = !navbar.classList.value.includes('navbar-transparent');
        this.bottomClass = `bottom-border ${navbarIsPurple ? 'border-bottom-white' : ''}`;
        this.isHovered = true;
        if (this.dropdownMenu) this.dropdownMenu.classList.add('show');
    }

    onMouseLeave() {
        if (this.isClicked) return;
        this.bottomClass = '';
        this.isHovered = false;
        if (this.dropdownMenu) this.dropdownMenu.classList.remove('show');
    }

    async handleOpenAndCloseDrawer() {
        if (this.drawer.open) this.isHovered = false;
        this.drawer.open = !this.drawer.open;
        this.clearSearch();

        if (this.drawer.open) this.eventAggregator.publish('reset-selection-stack');
    }

    async getFormattedCategoriesForProfile() {
        const menu = this.baseItems.map(item => ({
            name: item.name,
            link: !item.items?.length,
            isCategory: true,
            isProfile: true,
            items: item.items?.map(subItem => ({
                ...subItem,
                slug: [`${this.customerPortalPageRoute}/${subItem.slug}`],
                link: true
            })),
            slug: !item.items?.length ? [`${this.customerPortalPageRoute}/${item.slug}`] : `${this.customerPortalPageRoute}/${item.slug}`,
            viewingAsAdmin: null,
            customContent: null
        }));

        if (await this.canAccessAdminPanel()) {
            menu.push({ name: 'Admin Panel', slug: [this.adminURL], isCategory: true, isProfile: true, link: false, items: null, viewingAsAdmin: undefined, customContent: undefined });
            menu.push({ name: 'View as Admin', viewingAsAdmin: this.viewingAsAdmin, customContent: this.makeViewAsAdminTemplate()?.outerHTML, isCategory: true, isProfile: true, link: false, items: null, slug: null });
        }

        menu.push({ name: 'Sign out', isCategory: true, isProfile: true, link: false, items: null, slug: null, viewingAsAdmin: undefined, customContent: undefined });

        return menu;
    }

    clearSearch() {
        this.search = null;
        this.eventAggregator.publish('search-updated', { search: null });
    }

    handleSearch() {
        this.eventAggregator.publish('search-updated', { search: this.search });
    }

    async handleDrawerNavigationClick(item, menu) {
        if (menu === 'menu' || (menu !== 'menu' && !item?.category)) {
            if (item?.link) {
                const linkWithLang = await this.languageRouteValueConverter.toView(item.slug[0]);
                this.router.navigate(linkWithLang);
                this.handleOpenAndCloseDrawer();
            } else if (item?.name === 'Admin Panel') {
                this.openAdminPanel();
            } else if (item?.name === 'Sign out') {
                await this.logout();
            }
        }
    }

    async logout() {
        await this.sessionService.logout();
        this.toastService.showToast('Info', 'Please log back in to see your orders and continue trading', ToastType.INFO);
    }

    openAdminPanel() {
        const checkOutWindow = window.open(this.adminURL, '_blank');
        if (!checkOutWindow || checkOutWindow.closed || typeof checkOutWindow.closed === 'undefined') {
            this.eventAggregator.publish('banner-updated', { stateBanner: 'warning', text: 'Looks like you have AdBlock enabled. Some functionality may be affected by AdBlock' });
        }
    }

    async canAccessAdminPanel() {
        return await this.sessionService.checkRolesForPanelAccess();
    }

    makeViewAsAdminTemplate() {
        const el1 = document.createElement('div');
        el1.style.cssText = 'display: flex; align-items: space-between; text-transform: captilize;';
        el1.classList.add('customer-dropdown-option');
        const labelContainer = document.createElement('label');
        labelContainer.classList.add('switch');
        const inputContainer = document.createElement('input');
        inputContainer.setAttribute('id', 'view-switch-responsive');
        inputContainer.type = 'checkbox';
        const spanContainer = document.createElement('span');
        spanContainer.classList.add('slider', 'round');
        labelContainer.appendChild(inputContainer);
        labelContainer.appendChild(spanContainer);
        el1.prepend(labelContainer);
        return el1;
    }

    isClickedChanged(newValue) {
        this.eventAggregator.publish('rigth-element-is-hovered', { profileIsHovered: newValue || this.isHovered });
    }

    isHoveredChanged(newValue) {
        this.eventAggregator.publish('rigth-element-is-hovered', { profileIsHovered: newValue || this.isClicked });
    }
}
