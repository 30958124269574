// Module
var code = `<template>
    <section id="bug-bounty" class="legal-page policy \${pageLoading ? 'skeleton-policy' : ''}">
        <div class="header tos-body font-bold text-light">
            <div class="header-title text-start title-container title-1" skeleton-loading="is-loading.bind:pageLoading;">Bug Bounty</div>
        </div>
        <div class="tos-main-content text-medium">
            <div class="tos-body">
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_LAST_UPDATED') || viewingAsAdmin" key.bind="'BUG_BOUNTY_LAST_UPDATED'" class="text-end last-update text-1 tos-body" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_HEADER_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_HEADER_CONTENT'" class="text-content text-content-s" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_PROGRAM_RULES_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_PROGRAM_RULES_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_PROGRAM_RULES_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_PROGRAM_RULES_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_CONFIDENTIALITY_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_CONFIDENTIALITY_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_CONFIDENTIALITY_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_CONFIDENTIALITY_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_ELIGIBILITY_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_ELIGIBILITY_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_ELIGIBILITY_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_ELIGIBILITY_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_DOMAINS_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_DOMAINS_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_DOMAINS_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_DOMAINS_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_IN_SCOPE_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_IN_SCOPE_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_IN_SCOPE_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_IN_SCOPE_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_COMMON_TEMPLATE_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_COMMON_TEMPLATE_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_COMMON_TEMPLATE_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_COMMON_TEMPLATE_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_ASSET_SCOPE_DETAILS_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_ASSET_SCOPE_DETAILS_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_ASSET_SCOPE_DETAILS_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_ASSET_SCOPE_DETAILS_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_SUBDOMAIN_TAKEOVER_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_SUBDOMAIN_TAKEOVER_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_SUBDOMAIN_TAKEOVER_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_SUBDOMAIN_TAKEOVER_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_APPLICATION_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_APPLICATION_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_APPLICATION_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_APPLICATION_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_GENERAL_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_GENERAL_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_GENERAL_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_GENERAL_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_LAST_90DAYS_RESPONSE_TIMES_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_LAST_90DAYS_RESPONSE_TIMES_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_LAST_90DAYS_RESPONSE_TIMES_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_LAST_90DAYS_RESPONSE_TIMES_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_REWARD_STRUCTURE_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_REWARD_STRUCTURE_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_REWARD_STRUCTURE_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_REWARD_STRUCTURE_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_REGULAR_REWARD_STRUCTURE_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_REGULAR_REWARD_STRUCTURE_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_REGULAR_REWARD_STRUCTURE_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_REGULAR_REWARD_STRUCTURE_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_SECRET_SHOPPER_REWARD_STRUCTURE_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_SECRET_SHOPPER_REWARD_STRUCTURE_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_SECRET_SHOPPER_REWARD_STRUCTURE_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_SECRET_SHOPPER_REWARD_STRUCTURE_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_OUT_OF_SCOPE_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_OUT_OF_SCOPE_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_OUT_OF_SCOPE_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_OUT_OF_SCOPE_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_LEGAL_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_LEGAL_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_LEGAL_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_LEGAL_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_FAQ_TITLE') || viewingAsAdmin" key.bind="'BUG_BOUNTY_FAQ_TITLE'" class="font-bold text-title" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
                <cx-page-content-area if.bind="helpers.findMarkupByKey(bugBountyPageContentArea, 'BUG_BOUNTY_FAQ_CONTENT') || viewingAsAdmin" key.bind="'BUG_BOUNTY_FAQ_CONTENT'" class="text-content" is-custom-legal-page.bind="true" skeleton-loading="is-loading.bind:pageLoading;"></cx-page-content-area>
          
            </div>
        </div>
    </section>
</template>
`;
// Exports
export default code;