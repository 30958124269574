import './cx-crypto-price-card.scss';
import { autoinject, bindable, BindingEngine, computedFrom, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { getAWSBucketEndpoint } from 'environment';
import { Flip } from 'resources/helpers/flip';
import { Helper } from 'resources/helpers/helper';
import { Chart } from 'chart.js/auto';
import { DataCurrencyStats, DataStat } from 'services/models/currency/dataStatsCurrenciesInterface';
import { PageContentAreaService } from 'services/page-content-area-service';
import { CurrencyCryptoPriceCard } from 'services/models/currency/currencyCryptoPriceCard';

@autoinject()
export class CxCryptoPriceCard {
    @bindable currency: CurrencyCryptoPriceCard;
    @bindable @observable isLoading: boolean = true;
    @bindable learnMoreRoute: string;
    @bindable learnMoreFunc;
    @bindable dataStatsCurrencies: DataCurrencyStats[];
    @bindable ctaKey: string = 'CX_LEARN_MORE';
    @bindable isLoaded: boolean = false;
    @bindable ready: boolean = false;
    baseAwsEndpoint: string;
    upwardTrend: boolean = true;
    flipElPrice: HTMLElement | null = null;
    flipElPercentage: HTMLElement | null = null;
    flipElArrow: HTMLElement | null = null;
    flipInstancePrice: Flip;
    flipInstancePercentage: Flip;
    flipInstanceArrow: Flip;
    dataValues: number[] = [];
    labels: string[] = [];
    chartData: Chart | null = null;
    intervalGraphs: NodeJS.Timeout | null = null;
    startedInterval: boolean = false;
    lastUpdatedPrice: number | null = null;
    oldCurrency: CurrencyCryptoPriceCard | null = null;
    skeletonPrice: boolean = true;
    skeletonPercentage: boolean = true;
    skeletonArrow: boolean = true;
    ignoreTimeout: boolean = true;

    maxConsecutiveDuplicates: number = 3;
    duplicateCounter: number = 0;

    constructor(
        private router: Router,
        private helper: Helper,
        private pageContentAreaService: PageContentAreaService,
        private bindingEngine: BindingEngine
    ) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('currencies');
    }

    bind() {
        this.bindExchangeProperty();
        const { currentInstruction } = this.router;

        this.ignoreTimeout = currentInstruction.config.name === 'home';
    }

    detached() {
        if (this.intervalGraphs) clearInterval(this.intervalGraphs);
    }

    currencyChanged(newValue: CurrencyCryptoPriceCard | null, oldValue: CurrencyCryptoPriceCard | null) {
        if (!newValue) return;
        try {
            this.lastUpdatedPrice = newValue.priceUSD;
            this.oldCurrency = { ...newValue };
            if (!this.isLoading && this.flipInstancePrice) {
                this.skeletonPrice = this.skeletonPercentage = this.skeletonArrow = false;
                if (this.flipElPrice?.style.height === '0px') this.flipInstancePrice.resetHTML(this.doubleToInteger(newValue?.price));
                this.flipInstancePrice.flipTo({
                    from: this.doubleToInteger(oldValue?.price || 0),
                    to: this.doubleToInteger(newValue?.price)
                });
                const newPercentage = this.doubleToInteger(newValue?.changePercentLast24);
                const oldPercentage = this.doubleToInteger(oldValue?.changePercentLast24 || 0);

                if (this.flipElPercentage?.style.height === '0px') this.flipInstancePercentage.resetHTML(Math.abs(newPercentage));
                if (this.flipElArrow?.style.height === '0px') this.flipInstanceArrow.resetHTML(this.upwardTrend ? 1 : 0);

                if (newPercentage !== oldPercentage) {
                    this.upwardTrend = newPercentage >= 0;
                    this.flipInstancePercentage.flipTo({
                        from: Math.abs(oldPercentage),
                        to: Math.abs(newPercentage)
                    });
                    this.flipInstanceArrow.flipTo(this.upwardTrend ? { to: 1 } : { to: 0 });
                }
                this.skeletonPrice = this.activeSkeleton(this.flipElPrice);
                this.skeletonPercentage = this.activeSkeleton(this.flipElPercentage);
                this.skeletonArrow = this.activeSkeleton(this.flipElArrow);
                this.ready = !this.skeletonPrice && !this.skeletonPercentage && !this.skeletonArrow;
            }

            if (!this.isLoading && !this.startedInterval) {
                this.startedInterval = true;
                this.intervalGraphs = setInterval(() => {
                    this.updateChart(this.lastUpdatedPrice);
                }, 60000);
            }
        } catch (error) {
            console.error(error);
        }
    }

    doubleToInteger(value: number): number {
        return parseInt((value * 100).toFixed(0));
    }

    getFlipElements() {
        this.flipElPrice = document.getElementById(`currency-price-${ this.currency?.code }`);
        this.flipElPercentage = document.getElementById(`percentage-variation-${ this.currency?.code }`);
        this.flipElArrow = document.getElementById(`arrow-trend-${ this.currency?.code }`);
    }

    addFlipInstances() {
        if (this.flipElPrice) {
            if (!this.flipInstancePrice) {
                this.flipInstancePrice = new Flip ({
                    node: this.flipElPrice,
                    from: this.doubleToInteger(this.currency?.price),
                    separateEvery: 3,
                    separateOnly: 2,
                    separator: '.',
                    duration: 2
                });
            }
        }

        if (this.flipElPercentage) {
            if (!this.flipInstancePercentage) {
                this.flipInstancePercentage = new Flip ({
                    node: this.flipElPercentage,
                    from: Math.abs(this.doubleToInteger(this.currency?.changePercentLast24)),
                    separateEvery: 3,
                    separateOnly: 2,
                    separator: '.',
                    duration: 2
                });
            }
        }

        if (this.flipElArrow) {
            if (!this.flipInstanceArrow) {
                this.flipInstanceArrow = new Flip ({
                    from: this.upwardTrend ? 1 : 0,
                    node: this.flipElArrow,
                    systemArr: ['<span class="material-icons">arrow_downward</span>', '<span class="material-icons">arrow_upward</span>'],
                    isFloatingNumber: false,
                    duration: 2
                });
            }
        }
    }

    isLoadingChanged() {
        try {
            if (!this.isLoading) {
                this.upwardTrend = this.currency?.changePercentLast24 >= 0;

                setTimeout(() => {
                    this.getFlipElements();
                    this.addFlipInstances();
                    this.skeletonPrice = this.activeSkeleton(this.flipElPrice);
                    this.skeletonPercentage = this.activeSkeleton(this.flipElPercentage);
                    this.skeletonArrow = this.activeSkeleton(this.flipElArrow);

                    if (this.skeletonPrice || this.skeletonPercentage || this.skeletonArrow) {
                        this.currency.price = 0;
                    }
                    this.loadGraphsData();
                    this.ready = !this.skeletonPrice && !this.skeletonPercentage && !this.skeletonArrow;
                }, this.loadingTimeoutDuration);
            }
        } catch (error) {
            console.error(error);
        }
    }

    @computedFrom('skeletonPrice', 'skeletonPercentage', 'skeletonArrow', 'ignoreTimeout')
    get loadingTimeoutDuration(): number {
        return (this.skeletonPrice || this.skeletonPercentage || this.skeletonArrow) && !this.ignoreTimeout ? 500 : 0;
    }

    async goToLearnMore() {
        const { currentInstruction } = this.router;

        if (currentInstruction.config.name === 'home') {
            this.router.navigate(`buy/${this.currency?.code?.toLowerCase()}`);
            return;
        }

        this.router.parent.navigate(this.learnMoreRoute || `buy/${this.currency?.code?.toLowerCase()}`);
    }

    loadGraphsData(): void {
        if (!this.currency) return;
        const containerGraph = document.getElementById('graph-' + this.currency.code);
        if (!containerGraph) return;
        let canvas = Array.from(containerGraph.children).find(child => child.tagName === 'CANVAS') as HTMLCanvasElement;
        if (canvas) canvas.remove();
        canvas = document.createElement('canvas');
        const width = containerGraph.offsetWidth;
        const height = containerGraph.offsetHeight;
        canvas.width = width;
        canvas.height = height;
        const color = this.getColorLine(this.currency.code);
        const statsByCurrency = this.getDataStatsCurrency(this.currency.code);
        this.labels = statsByCurrency.map(data => this.helper.timestampToDateHour(data.time));
        this.dataValues = statsByCurrency.map(data => data.price);

        this.chartData = new Chart(canvas, {
            type: 'line',
            data: {
                labels: this.labels,
                datasets: [{
                    data: this.dataValues,
                    borderColor: color,
                    borderWidth: 2,
                    fill: false,
                    pointRadius: 0,
                    pointHoverRadius: 0
                }]
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                scales: {
                    x: {
                        display: false,
                        beginAtZero: true
                    },
                    y: {
                        display: false,
                        beginAtZero: false,
                    },
                }
            }
        });
        containerGraph.appendChild(canvas);
    }

    getColorLine(code: string): string {
        const colors = {
            BTC: 'rgba(255, 132, 0, 1)',
            ETH: 'rgba(98, 126, 234, 1)',
            SOL: 'rgba(201, 50, 247, 1)',
            ADA: 'rgba(0, 51, 173, 1)'
        };

        return colors[code] || 'rgba(0, 0, 0, 1)'; // Default color
    }

    getDataStatsCurrency(code: string): DataStat[] {
        if (!this.dataStatsCurrencies) return [];
        const filteredData = this.dataStatsCurrencies.find(data => data.code === code);
        return filteredData ? filteredData.dataStats : [];
    }

    updateChart(value: number) {
        if (this.dataValues.length > 0 && value === this.dataValues[this.dataValues.length - 1]) {
            this.duplicateCounter++;
            if (this.duplicateCounter >= this.maxConsecutiveDuplicates) {
                clearInterval(this.intervalGraphs);
                this.startedInterval = false;
            }
            return;
        }
        this.duplicateCounter = 0;

        const time = this.helper.timestampToDateHour(new Date().getTime());
        this.labels = this.labels.slice(1);
        this.dataValues = this.dataValues.slice(1);
        this.labels.push(time);
        this.dataValues.push(value);
        this.chartData.data.labels = this.labels;
        this.chartData.data.datasets[0].data = this.dataValues;
        this.chartData.update();
    }

    bindExchangeProperty() {
        if (this.currency) {
            this.bindingEngine
                .propertyObserver(this.currency, 'price')
                .subscribe(() => this.currencyChanged(this.currency, this.oldCurrency));

            this.bindingEngine
                .propertyObserver(this.currency, 'changePercentLast24')
                .subscribe(() => this.currencyChanged(this.currency, this.oldCurrency));
        }
    }

    activeSkeleton(element: HTMLElement | null): boolean {
        return element?.style.height === '0px';
    }
}
