import { SessionService } from 'services/session-service';
import { autoinject } from 'aurelia-dependency-injection';
import { PaymentMethodWebsiteService } from './payment-method-website-service';
import { Helper } from 'resources/helpers/helper';
import { IPlaidOnsuccessMetadata } from './models/plaid/plaid.interfaces';

@autoinject()
export class SavedPaymentMethodService {
    constructor(private paymentMethodWebsiteService: PaymentMethodWebsiteService, private helper: Helper, private sessionService: SessionService) {}
    private localStoragePlaidKey = 'plaidSavedPaymentMethods';

    async getSavedPaymentMethods() {
        const savedPaymentsMethods = await this.paymentMethodWebsiteService.getSavedPaymentMethodByUserId();
        const savedPlaidPaymentMethods = this.helper.getWindowLocalStorageValue(this.localStoragePlaidKey) || [];
        if (!savedPlaidPaymentMethods || savedPlaidPaymentMethods.length === 0) return savedPaymentsMethods;
        const savedPlaidPaymentMethodsParsed: IPlaidOnsuccessMetadata[] = JSON.parse(savedPlaidPaymentMethods);
        const bankTransferPaymentMethod = await this.paymentMethodWebsiteService.getByReference('apaylo-eft');
        for (const savedPaymentsMethod of savedPlaidPaymentMethodsParsed) {
            const institutionName = savedPaymentsMethod.institution.name;
            for (const account of savedPaymentsMethod.accounts) {
                const paymentMethodFinal = this.helper.copyObject(bankTransferPaymentMethod);
                const paymentMethod = this.helper.copyObject(paymentMethodFinal.paymentMethod);
                paymentMethod.name = `${institutionName} - ***${account.mask}`;
                paymentMethod.paymentMethodUserSavedPlaidId = account.id;
                paymentMethodFinal.paymentMethod = paymentMethod;
                const isAlreadySaved = savedPaymentsMethods.some(
                    x => x.paymentMethod.name === paymentMethodFinal.paymentMethod.name
                );
                if (!isAlreadySaved) savedPaymentsMethods.push(paymentMethodFinal);
            }
        }
        return savedPaymentsMethods;
    }

    async savePlaidPaymentMethods(metadata: IPlaidOnsuccessMetadata) {
        const isLogged = this.sessionService.isLoggedIn();
        if (!isLogged) await this.savePlaidPaymentsMethodsToLocalStorage(metadata);
        else await this.savePlaidPaymentsMethodsToRemote(metadata);
    }

    async savePlaidPaymentsMethodsToLocalStorage(metadata: IPlaidOnsuccessMetadata) {
        let savedPaymentMethods = this.helper.getWindowLocalStorageValue(this.localStoragePlaidKey);
        savedPaymentMethods = savedPaymentMethods ? JSON.parse(savedPaymentMethods) : [];
        const dataToSave = {
            // eslint-disable-next-line camelcase
            public_token: metadata.public_token,
            institution: metadata.institution,
            accounts: metadata.accounts,
        };
        savedPaymentMethods.push(dataToSave);
        const stringToSave = JSON.stringify(savedPaymentMethods);
        this.helper.saveWindowLocalStorageValue(this.localStoragePlaidKey, stringToSave);
    }

    async savePlaidPaymentsMethodsToRemote(metadata: IPlaidOnsuccessMetadata) {
        this.helper.destroyWindowLocalStorageValue(this.localStoragePlaidKey);
        return await this.paymentMethodWebsiteService.savePlaidUserPaymentMethod(metadata.public_token);
    }

}
