import { inject } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { ToastService } from 'services/toast-service';
import { EventAggregator } from 'aurelia-event-aggregator';
@inject(
    ToastService,
    SessionService,
    EventAggregator
)
export class ErrorPagesRedirection {
    protected toastService: ToastService;
    protected sessionService: SessionService;
    protected eventAggregator: EventAggregator;
    constructor(
        toastService: ToastService,
        sessionService: SessionService,
        eventAggregator: EventAggregator
    ) {
        this.toastService = toastService;
        this.sessionService = sessionService;
        this.eventAggregator = eventAggregator;
    }

    handleRateLimitToken(response) {
        const item = JSON.parse(this.sessionService.getRateLimitToastFired());
        const now = new Date();

        if (window.location.pathname.includes('/rate-limit')) return response;

        if (item && now.getTime() < item?.expiry) {
            this._redirectToRateLimit();
        }

        if (item && now.getTime() > item?.expiry) {
            this.sessionService.removeRateLimitToastFired();
        }
    }

    handleRateLimitHttpStatus = async (response: Response) => {
        const defaultResponse = Response.json({}, { status: 200 });
        const currentUrl = window.location.href;
        const encodedUrl = encodeURIComponent(currentUrl);

        if (response?.status === 429 && window.location.pathname.includes('/rate-limit')) return defaultResponse;

        if (response?.status === 429 && this.sessionService.getRateLimitToastFired()) {
            this._redirectToRateLimit();
            return defaultResponse;
        }
        if (response?.status === 429 && !this.sessionService.getRateLimitToastFired()) {
            if (document.visibilityState === 'visible') {
                !window.location.pathname.includes('/rate-limit') && this.handleRateLimitBanner(encodedUrl);
            } else {
                this._redirectToRateLimit();
            }


            return defaultResponse;
        }
    };

    handleRateLimitBanner(encodedUrlSrc) {
        if (this.sessionService.getRateLimitToastFired()) return;

        if (document.readyState !== 'loading') {
            setTimeout(() => {
                this.eventAggregator.publish('banner-updated', { successful: 'error', text: 'You are being rate limited' });
            }, 2000);
        }
        if (document.readyState !== 'loading') {
            setTimeout(() => {
                this.eventAggregator.publish('banner-updated', { successful: 'error', text: 'You are being rate limited' });
            }, 2000);
        }

        document.addEventListener('click', () => {
            const item = JSON.stringify(this._createNewTempToken(1));
            this.sessionService.setRateLimitToastFired(item);
            this._redirectToRateLimit(encodedUrlSrc);
        }, { once: true });


    }

    _redirectToRateLimit(encodedUrlSrc?) {
        const currentUrl = window.location.href;
        const encodedUrl = encodeURIComponent(currentUrl);

        window.location.href = `/rate-limit?redirectTo=${encodedUrlSrc ? encodedUrlSrc : (encodedUrl || '/')}`;
    }

    _createNewTempToken(minutes = 1) {
        const now = new Date();
        const expiryTime = now.getTime() + (minutes * 60 * 1000);

        const item = {
            value: true,
            expiry: expiryTime
        };
        return item;
    }

    handleSiteDownBanner(encodedUrlSrc?) {
        const currentUrl = window.location.href;
        const encodedUrl = encodeURIComponent(currentUrl);

        if (this.sessionService.getSiteDownToastFired()) return;

        if (document.readyState !== 'loading') {
            setTimeout(() => {
                this.eventAggregator.publish('banner-updated', { successful: 'error', text: 'Chicksx is currently down. To check status, please', url: 'https://status.chicksx.com/' });
            }, 2000);
        }

        document.addEventListener('click', (event) => {
            const elementToIgnore = document.querySelector('#cds-banner');
            if (event.target instanceof Node) {
                if (event.target !== elementToIgnore && !elementToIgnore.contains(event.target)) {
                    this.sessionService.setSiteDownToastFired(true);
                    window.location.href = `/site-down?redirectTo=${encodedUrlSrc ? encodedUrlSrc : (encodedUrl || '/')}`;
                }
            }
        });
        document.addEventListener('click', (event) => {
            const elementToIgnore = document.querySelector('#cds-banner');
            if (event.target instanceof Node) {
                if (event.target !== elementToIgnore && !elementToIgnore.contains(event.target)) {
                    this.sessionService.setSiteDownToastFired(true);
                    window.location.href = `/site-down?redirectTo=${encodedUrlSrc ? encodedUrlSrc : (encodedUrl || '/')}`;
                }
            }
        });
    }
}
