import { BaseEntity } from '../baseEntity';
import { Website } from '../website/website';
import { DeliveryMethod } from './delivery-method';

export class PaymentMethodCategory {
    name: string;
    paymentMethods: PaymentMethod[];
}

export enum TransactionAddressType {
    WalletAddress,
    AccountAddress,
    EmailAddress,
    InPersonAddress,
    MailAddress
}

export class PaymentMethodWebsite {
    createdDate?: Date;
    updatedDate?: Date;
    websiteId: number;
    website: Website;
    paymentMethodId: number;
    paymentMethod: PaymentMethod;
    fee: number;
    receivingFee?: number;
    defaultValue?: number;
    proccessorPercentFee?: number;
    proccessoBaseFee?: number;
    smallOrderFee?: number;
    smallOrderMinimumAmount?: number;
    live: boolean;
    calculateTaxes?: boolean;
    minimum?: number;
    maximum?: number;
    spreadFee?: number;
    paymentStepNotes?: string;
    orderCompletedStepNotes: string;
    withdrawStepNotes?: string;
    position?: number;
    payoutable: boolean;
    additionalBalance: boolean;
    is3dsRequired: boolean;
    supportedCurrencies: PaymentMethodSupportedCurrencies[];
    restrictedCountries: PaymentMethodRestrictedCountries[];
    additionalInternalFee?: number;
    show?: boolean;
    transactionAddressType?: TransactionAddressType;
    withdrawFee?: number;
    baseWithdrawFee?: number;
    minimumWithdrawAmount?: number;
    maximumWithdrawAmount?: number;
    isFiatSwap: boolean;
}
export interface PaymentMethodForSelectorResponse {
    id: number;
    name: string;
    reference: string;
    imagePath: string;
    payoutable: boolean;
    live: boolean;
    paymentMethodCategoryId?: number;
    position?: number;
    paymentMethod?: {
        id?: number;
        name?: string;
        imagePath?: string;
        reference?: string;
    };
}



export class PaymentMethodSupportedCurrencies {
    currencyId: number;
    paymentMethodId: number;
    websiteId: number;
}

export class PaymentMethodRestrictedCountries {
    countryId: number;
    paymentMethodId: number;
    websiteId: number;
}

export class PayoutableInfo {
    address: string;
    code?: string;
    additionalInformation?: string;
    type?: TransactionAddressType;
}

export class ApayloEFTRequest
{
    bankId: number;
    bankBranchId: number;
    accountNumber: string;
}

export class BankAccountInfo extends ApayloEFTRequest {
    institutionNumber: string;
    transitNumber: string;
    bankName: string;
    bankIcon: string;
    bankStaticIcon: boolean;
}

export class PaymentMethod extends BaseEntity {
    name: string;
    imagePath: string;
    reference: string;
    paymentAddress: string;
    qrCodeValue: string;
    paypalUrl: string;
    isBalance: boolean;
    cryptoAbbreviation: string;
    websites: PaymentMethodWebsite[];
    paymentMethodCategoryId?: number;
    paymentMethodCategory: PaymentMethodCategory;
    usesCard?: boolean;
    cardInfo: CardInfo;
    paymentMethodUserSavedId?: number;
    paymentMethodUserSavedPlaidId?: string;

    /**
     * Exists only in the frontend. Used in withdraw flow for showing users saved payment methods.
     */
    payoutableInfo?: PayoutableInfo;

    /**
     * Exists only in the frontend. Used in EFT flow.
     */

    bankAccountInfo?: BankAccountInfo;
}

export class PaymentMethodResponse {
    id: number;
    name: string;
    reference: string;
    paymentMethodCategoryId?: number;
}

export class Exchange {
    giveSelectedCurrency: Currency;
    giveCurrencyOptions: Currency[];
    receiveSelectedCurrency: Currency;
    receiveCurrencyOptions: Currency[];
    selectedPaymentMethod: PaymentMethodWebsite;
    receivingPaymentMethod: PaymentMethodWebsite;
    amountGifted: number;
    amountReceived: number;
    currentRate: number;
    youGiveRates: { [key: string]: number };
    youReceiveRates: { [key: string]: number };
    transactionFeeBeforeRateApplied: number;
    deliveryFeeBeforeRateApplied: number;
    transactionFee: number;
    cryptoFee: number;
    deliveryFee: number;
    deliveryFeePercentage: number;
    walletAddress: string;
    extraField: string;
    timeLeft: number;
    trackingNumber: string;
    validCoupon: CouponCode;
}

export class Currency {
    code: string;
    text: string;
    description: string;
    shortName: string;
    imagePath: string;
    symbol: string;
    type: CurrencyType;
    id: number;
    isStable: boolean;
    hasFiatToFiat: boolean;
    restrictedCountries: CurrencyCountry[];

    show?: boolean;
    reference: string;
    originalCode: string;
    isTrending: boolean;
    position?: number;
}

export class OrderCryptoExchanges {
    currencyBase: string;
    currencyTarget: string;
    walletAddress: string;
    rate: number;
    amountBase: number;
    amountTarget: number;
    paymentFee: number;
    cryptoFee: number;
    deliveryFee: number;
    deliveryMethodId: number;
    deliveryMethod?: DeliveryMethod;
    operationType: string;
    baseCurrency?: Currency;
    targetCurrency?: Currency;
    couponAmountCalculated?: number;
    amountTotal?: number;
    baseAmountTarget?: number;
    targetCurrencyReference?: string;
    baseCurrencyReference?: string;
}

export type OrderDetail = {
    cashInMailDetail?: CashInMailDetail;
    cashInPersonDetail?: CashInPersonDetail;
    pylonOrderDetail?: PylonOrderDetail;
    nuveiOrderDetail?: NuveiOrderDetail;
    paypalDetail?: PayPalDetail;
    apayloEFTData?: ApayloEFTRequest;
};

export type CashInMailDetail = {
    address: string;
    country: string;
    state: string;
    city: string;
    zipCode: string;
    deliveryType: string;
};

export type CashInPersonDetail = {
    meetingType: string;
    location: string;
    date: string;
    time: string;
};

export type PayPalDetail = {
    deliveryType: string;
};

export type NuveiOrderDetail = {
    transactionId: string;
    userTokenId: string;
    userPaymentOptionId: string;
    clientUniqueId: string;
};

export class PaymentMethodCurrencyFeeWebRequest {
    currencyId?: number;
    baseCurrencyId?: number;
    targetCurrencyId?: number;
    paymentMethodId?: number;
    amount?: number;
    operationType: string;
}

export class PaymentMethodCurrencyFeeValuesResponse extends BaseEntity {
    applyFromAmount: number;
    applyToAmount: number;
    percentage: number;
    operationType: string;
    paymentMethodCryptoFeeId?: number;
    baseFee: number;
}

export class ExchangeRate {
    currency: string;
    rates: {
        [key: string]: number;
    };
}

export class ExchangeRatesResponse {
    data: ExchangeRate;
}

export class Country {
    id: number;
    name: string;
    alpha2: string;
    alpha3: string;
    mainCurrencyId: number;
    numeric: string;
}

export class CurrencyCountry {
    currencyId: number;
    countryId: number;
    country: Country;
}

export class PylonPaymentDataRequest {
    cardReferenceID?: string;
    baseUrl: string;
}

export class PylonOrderDetail {
    type: string;
    serialNumber: string;
    merchantOrderId: string;
    paynetOrderId: string;
    redirectUrl: string;
}

export class NuveiPaymentDataRequest {
    cvv?: string;
    userPaymentOptionId?: number;
}

export class CardInfo {
    lastDigits: string;
    reference: string;
    type: string;
    cardId: string;
    cvv?: string;
    cardExpiry: {
        year?: number;
        month?: number;
        day?: number;
    };
}

export class NuveiAddCreditCard {
    ccCardNumber: string;
    ccExpMonth: string;
    ccExpYear: string;
    ccNameOnCard: string;
    billingAddress: NuveiBillingAddress;
}

export class NuveiBillingAddress {
    firstName: string;
    lastName: string;
    address: string;
    phone: string;
    zip: string;
    city: string;
    countryCode: string;
    state: string;
    email: string;
    country: string;
}

export class NuveiPaymentMethod
{
    userPaymentOptionId: number;
    upoName: string;
    paymentMethodName: string;
    upoStatus: string;
    upoRegistrationDate: string;
    upoRegistrationTime: string;
    expiryDate: string;
    cardNumber: string;
    depositSuccess: string;
    withdrawSuccess: string;
    billingAddress: NuveiBillingAddress;
    cccId: number;
    userTokenId: string;
    upoData: NuveiUPOData;
}

export class NuveiUPOData {
    uniqueCC: string;
    ccCardNumber: string;
    cardProduct: string;
    bin: string;
    cardType: string;
    ccExpMonth: string;
    ccExpYear: string;
    allowDcc: string;
    issuerCountry: string;
    APMVerified: string;
    optionalWdType: string;
    brand: string;
    ccNameOnCard: string;
}

export class NuveiAddUPOCreditCardResponse {
    internalRequestId: number;
    status: string;
    errCode: number;
    reason: string;
    merchantId: string;
    merchantSiteId: string;
    version: string;
    clientRequestId: string;
    userPaymentOptionId: number;
    ccToken: string;
    brand: string;
    uniqueCC: string;
    bin: string;
    cardType: string;
    last4Digits: string;
    billingAddress: NuveiBillingAddress;
}

export class NuveiEditCreditCard
{
    userPaymentOptionId: number;
    ccExpMonth: string;
    ccExpYear: string;
    ccNameOnCard: string;
    billingAddress: NuveiBillingAddress;
}
export class NuveiDeleteCreditCard
{
    userPaymentOptionId: number;
}

export class NuveiEditDeleteUPOResponse {
    merchantId: string;
    merchantSiteId: string;
    internalRequestId: string;
    clientRequestId: string;
    status: string;
    errCode: string;
    reason: string;
    version: string;
}
export class ThresholdCountry {
    countryId: number;
    thresholdId: number;
}

export class PaymentMethodThreshold {
    id: number;
    websiteId: number;
    paymentMethodId: number;
    countries: ThresholdCountry[];
    operationTypeList: ThresholdOperationType[];
    verificationTypeList: VerificationType[];
    frequency: number;
    threshold: number;
    currencyId: number;
    transactionThreshold: number;
}

export enum VerificationType {
    Phone,
    ID,
    Email,
    Address
}

export enum ThresholdOperationType {
    Buy = 'B',
    Sell = 'S'
}

export class CouponCode {
    code: string;
    type: string;
    value: number;
    active: boolean;
    validThrough: Date;
    minimumPurchase: number;
    maximumPurchase: number;
    maximumDiscount: number;
    totalUses: number;
    maxUses: number;
    userId?: number;
    position?: number;
    imagePath: string;
    description: string;
    title: string;
    websites;
    products;
    productCategories;
    games;
    category?;
    conditions;
    discount?: number;
}

export class Billing {
    firstName: string;
    lastName: string;
    address: string;
    phone: string;
    zip: string;
    city: string;
    countryCode: string;
    state: string;
    email: string;
    country: string;
}

export class RecentlySavedCC {
    ccCardNumber: string;
    ccExpMonth: string;
    ccExpYear: string;
    ccNameOnCard: string;
    billingAddress: Billing;
    cvv: string;
    cardNumber: string;
    cardCvv: string;
    monthYear: string[];
}

export enum OrderType {
    TopUp = 1,
    Cashback = 2,
    Withdraw = 5
}

export enum OperationType {
    Buy = 'B',
    Sell = 'S',
    Fiat2Fiat = 'FF',
    Crypto2Crypto = 'CC'
}

export enum CurrencyDirection {
    Give = 'GIVE',
    Receive = 'RECEIVE'
}

export enum CurrencyType {
    Crypto = 'C',
    Fiat = 'F'
}

export class PaymentMethodCategoryInfo {
    id: number;
    name: string;
}

export class StripeRadarSession {
    chicksXSpZooRadarSessionId: string;
}
