import './orders.scss';
import { autoinject } from 'aurelia-framework';
import { Helper } from 'resources/helpers/helper';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { OrderService } from 'services/order-service';
import { OrderOperationType, OrderPaginationRequest, OrderServiceOrderTotalInfoResponse } from 'services/models/order';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { websiteShortCode } from 'environment';
import { SignalrService } from 'services/signalr-service';
import { ToastService } from 'services/toast-service';
import { ToastType } from 'services/models/toast';
import { AdminViewUpdatedEvent, CurrencyChangedEvent } from 'resources/constants';
import { PageContentAreaService } from 'services/page-content-area-service';

@autoinject()
export class Orders {
    constructor(
        private helper: Helper,
        private eventAggregator: EventAggregator,
        private router: Router,
        private orderService: OrderService,
        private currencyFormatValueConverter: CurrencyFormatValueConverter,
        private signalRService: SignalrService,
        private toastService: ToastService,
        private pageContentAreaService: PageContentAreaService
    ) {}

    contentLoading = true;
    buyDescription = '';
    sellDescription = '';
    exchangeDescription = '';
    OrderOperationType = OrderOperationType;
    connection: signalR.HubConnection;
    totalInfo: OrderServiceOrderTotalInfoResponse[] = [];

    amountMessageByType = {
        [OrderOperationType.Buy]: 'CX_TOTAL_SPENT',
        [OrderOperationType.Sell]: 'CX_TOTAL_SOLD',
        [OrderOperationType.Exchange]: 'CX_TOTAL_SWAPPED'
    };

    async attached() {
        try {
            this.helper.addLoadingComponent('orders', this);
            await this.handleEventSubscriptions();
            await this.updateData();
        } catch (e) {
            console.error(e);
        } finally {
            this.helper.validateLoading('orders');
        }
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
        this.helper.killSignalRListeners(this.connection);
    }

    async handleEventSubscriptions() {
        // Sockets
        this.connection = await this.signalRService.getSignalRConnection();
        this.helper.subscribeSignalRConnections(this.connection, {
            'SendCryptoExchangeOrderFulfillment': async response => {
                if (!response.fulfilled) {
                    return;
                }
                await this.updateData();
                this.toastService.showToast('Order Fulfilled', `Your order #${response.orderId} has been fulfilled.`, ToastType.SUCCESS);
            }
        });

        // Event Aggregator
        const events = {
            'page-loaded': () => this.contentLoading = false,
            [CurrencyChangedEvent]: async() => await this.updateData(true),
            [AdminViewUpdatedEvent]: async() => await this.updateData(true)
        };
        this.helper.subscribeEvents(this, events);
    }

    redirectTo = (name: string) => this.router.navigate(name);

    async getMessage(transactionType: OrderOperationType) {
        const totalInfo = this.totalInfo.find(x => x.operationType === transactionType);
        const priceMsg = this.pageContentAreaService.displaySiteString(this.amountMessageByType[transactionType]);
        const convertedPrice = await this.currencyFormatValueConverter.toViewFiatOnly(totalInfo?.totalPrice);
        const ordersCount = this.pageContentAreaService.displaySiteString('CX_ORDERS_COUNT');
        return `${ordersCount}: ${totalInfo?.count} | ${priceMsg}: ${convertedPrice}`;
    }

    async getCount(transactionType: OrderOperationType) {
        const request = {
            orderOperationType: transactionType,
            websiteShortCode: websiteShortCode()
        } as OrderPaginationRequest;
        return await this.orderService.getOrdersCountByWebsiteByUserId(request, true);
    }

    async getTotal(transactionType) {
        return await this.orderService.getCustomerTotalPriceOrders(transactionType);
    }

    async updateData(onlyMessage = false) {
        if (!onlyMessage) {
            this.totalInfo = await this.orderService.getCustomerOrdersTotalInfo();
        }

        for (const type of [OrderOperationType.Buy, OrderOperationType.Sell, OrderOperationType.Exchange]) {
            const enumName = OrderOperationType.enumName(type).toLowerCase();
            this[`${enumName}Description`] = await this.getMessage(type);
        }
    }
}
