import './bug-bounty.scss';
import { PageContentAreaService } from 'services/page-content-area-service';
import { autoinject } from 'aurelia-framework';
import { WebsiteService } from 'services/website-service';
import { Helper } from 'resources/helpers/helper';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';

@autoinject()
export class BugBounty {
    pageLoading = true;
    pageLoadedSubscriber: Subscription;
    constructor(
        private pageContentAreaService: PageContentAreaService,
        private websiteService: WebsiteService,
        private helpers: Helper,
        private eventAggregator: EventAggregator
    ) {
        this.websiteService = websiteService;
        this.pageContentAreaService = pageContentAreaService;
    }

    bugBountyPageContentArea;
    viewingAsAdmin;
    adminViewSubscription;

    async attached() {
        try {
            this.helpers.addLoadingComponent('bug');
            const pages = await this.websiteService.getPagesByWebsiteShortcode();
            const pageId = pages.find(x => x.name === 'Bug Bounty')?.id;
            this.bugBountyPageContentArea = await this.pageContentAreaService.getByPageId(pageId);
            this.handleSubscriptions();
        } finally {
            this.helpers.validateLoading('bug'); }
    }

    detached() {
        this.pageLoadedSubscriber?.dispose();
        this.adminViewSubscription?.dispose();
    }

    handleSubscriptions() {
        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });

        this.adminViewSubscription = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
        });
    }
}
