// Module
var code = `<template>
    <div id="cx-footer-menu">
        <cds-menu 
            typeahead-controller.bind="typeaheadController"
            menuopening.trigger="openMenu = true"
            menuclosing.trigger="menuClosing()">
            <a slot="menu-anchor" class="menu-anchor">
                \${link.keyName | siteStrings}
                <cds-icon class="d-none d-md-flex footer-menu-icon \${openMenu ? 'active' : 'inactive'}" icon="arrow_drop_down"></cds-icon>
                <cds-icon class="d-md-none footer-menu-icon \${openMenu ? 'active' : 'inactive'}" icon="keyboard_arrow_down" aria-hidden="true"></cds-icon>
            </a>
            <md-menu-item if.bind="link.showFilter" keep-open="true" slot="menu-options" class="menu-items-filter">
                <cds-option-box>
                    <cds-text-field
                        leading-icon="search"
                        variant="filled"
                        value.two-way="keyword & debounce: 400"
                        label="\${'CX_FILTER_PAGES' | siteStrings}"
                        stay-open-on-focusout="true"
                        stay-open-on-outside-click="true"
                    >
                    </cds-text-field>
                </cds-option-box>
            </md-menu-item>
            <md-menu-item slot="menu-options" repeat.for="item of filteredItems" if.bind="\$index < 10">
                <a class="menu-item-anchor w-100" href.bind="item.route">
                    <cds-option-box class="w-100 d-block" click.trigger="navigateToRoute(item)">
                        <div class="w-100 text-truncate">\${item.name}</div>
                    </cds-option-box>
                </a>
            </md-menu-item>
            <cx-no-results slot="menu-options" show.bind="!filteredItems.length"
                message="\${'CX_NO_ENTRIES_TO_SHOW' | siteStrings}"
            ></cx-no-results>
        </cds-menu>
    </div>
</template>
`;
// Exports
export default code;