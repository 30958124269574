import './cx-payment-method-item.scss';
import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { PaymentMethodWebsite, TransactionAddressType } from 'services/models/purchase-flow/exchange';
import { Helper } from 'resources/helpers/helper';
import { CreditCardHelper, StagesCardForm } from 'resources/helpers/credit-card-helper';
import { getAWSBucketEndpoint } from 'environment';
import moment from 'moment';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';

@autoinject()
export class CxPaymentMethodItem {
    constructor(
        private helper: Helper,
        private creditCardHelper: CreditCardHelper,
        private eventAggregator: EventAggregator
    ) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('payment-methods');
    }

    @bindable paymentMethod: PaymentMethodWebsite;
    @bindable selectedPaymentMethod: PaymentMethodWebsite;
    @bindable isLastItem: boolean;

    isDragging = false;
    originX: number;
    originY: number;
    axisX: number;
    axisY: number;
    baseAwsEndpoint: string;
    isDeletingPaymentMethod: boolean = false;
    isDeletingInProgress: boolean = false;
    parent;
    paymentMethodItemEl: HTMLElement;
    paymentMethodItemElId: string;
    displacement: number;
    itemRight: boolean;
    itemLeft: boolean;
    deleteSwipeIconEl: HTMLElement;
    deleteSwipeIconElId: string;
    editSwipeIconEl: HTMLElement;
    editSwipeIconElId: string;
    id: string;
    pmSelExpandedSubcription: Subscription;
    paymentMethodNameRef: HTMLElement;

    attached() {
        this.paymentMethodItemEl = document.getElementById(this.paymentMethodItemElId);
        this.deleteSwipeIconEl = document.getElementById(this.deleteSwipeIconElId);
        this.editSwipeIconEl = document.getElementById(this.editSwipeIconElId);
        this.handleEventSubscriptions();
    }

    detached() {
        this.pmSelExpandedSubcription?.dispose();
    }

    handleEventSubscriptions() {
        this.pmSelExpandedSubcription = this.eventAggregator.subscribe('payment-method-selector-expanded', payload => {
            if (!payload.expanded) {
                this.isDeletingPaymentMethod = false;
                this.isDeletingInProgress = false;
                this.isDragging = false;
                this.itemLeft = false;
                this.itemRight = false;
                this.onInit();
            }
        });
    }

    bind(bindingContext, overrideContext) {
        this.parent = overrideContext.parentOverrideContext.bindingContext;
        const randomKey = Date.now().toString(36) + Math.random().toString(36).substring(2);
        this.paymentMethodItemElId = `payment-method-item-${ randomKey }`;
        this.deleteSwipeIconElId = `delete-swipe-icon-${ randomKey }`;
        this.editSwipeIconElId = `edit-swipe-icon-${ randomKey }`;
    }

    handleCardImage(type) {
        return this.helper.getCardImageType(type);
    }

    handleCardName(type) {
        return this.helper.getCardName(type);
    }

    getformattedExpiryDate(method: PaymentMethodWebsite) {
        const cardExpiry = method.paymentMethod.cardInfo.cardExpiry;
        const val = moment(new Date(cardExpiry.year, cardExpiry.month - 1, 1)).format('MM/YY');
        return `Exp: ${val}`;
    }

    isCardExpiredOrClose(method: PaymentMethodWebsite) {
        const cardExpiry = method.paymentMethod.cardInfo.cardExpiry;
        return this.creditCardHelper.isCardExpiredOrClose(cardExpiry.year, cardExpiry.month);
    }

    selectPaymentMethod(method: PaymentMethodWebsite) {
        if (this.isDeletingPaymentMethod && this.parent.stageCardForm === StagesCardForm.INACTIVE) {
            this.isDeletingPaymentMethod = false;
            return;
        }
        this.parent.selectPaymentMethod(method);
    }

    editCard(method: PaymentMethodWebsite) {
        this.parent.editCard(method);
    }

    deleteCard() {
        this.isDeletingPaymentMethod = true;
        this.parent.deleteCard();
    }

    confirmDeletion(method: PaymentMethodWebsite) {
        this.isDeletingInProgress = true;
        this.parent.confirmDeletion(method);
    }

    cancelDeletion() {
        this.parent.stageCardForm = StagesCardForm.INACTIVE;
        this.isDragging = false;
        this.itemLeft = false;
        this.itemRight = false;
    }

    onTouchStart(ev) {
        if (ev.cancelable) ev.preventDefault();
        if (!this.paymentMethodItemEl || !this.deleteSwipeIconEl || !this.editSwipeIconEl) {
            this.paymentMethodItemEl = document.getElementById(this.paymentMethodItemElId);
            this.deleteSwipeIconEl = document.getElementById(this.deleteSwipeIconElId);
            this.editSwipeIconEl = document.getElementById(this.editSwipeIconElId);
        }
        this.isDragging = true;
        const e = (ev.changedTouches && ev.changedTouches[0]) || ev;
        this.axisX = this.originX = e.pageX;
        this.axisY = this.originY = e.pageY;
        this.onLoop();
    }

    onTouchMove(ev) {
        if (ev.cancelable) ev.preventDefault();
        const e = (ev.changedTouches && ev.changedTouches[0]) || ev;
        if (this.isDragging && (this.paymentMethod?.paymentMethod?.cardInfo?.type || this.paymentMethod?.paymentMethod?.bankAccountInfo)) {
            this.axisX = e.pageX;
        }
        this.axisY = e.pageY;
    }

    onTouchEnd(ev) {
        if (ev.cancelable) ev.preventDefault();
        if (ev.target.className.includes('material-icons')) {
            return;
        }

        if (this.paymentMethodItemEl.clientWidth - Math.abs(this.displacement) <= this.paymentMethodItemEl.clientWidth / 3) {
            if (this.itemLeft) {
                this.deleteCard();
            }
            if (this.itemRight) {
                this.editCard(this.paymentMethod);
            }
        }

        if (this.axisX === this.originX && this.axisY === this.originY && !this.isDeletingPaymentMethod) {
            this.paymentMethodItemEl.click();
        }

        this.isDragging = false;
        this.itemLeft = false;
        this.itemRight = false;
        this.onInit();
    }

    onInit() {
        this.paymentMethodItemEl?.style.setProperty('--displacementItem', '0');
        this.paymentMethodItemEl?.style.setProperty('transition', 'all 0.3s ease');
        this.deleteSwipeIconEl?.style.setProperty('--displacementIconDelete', '0');
        this.editSwipeIconEl?.style.setProperty('--displacementIconEdit', '0');
        setTimeout(() => this.paymentMethodItemEl?.style.removeProperty('transition'), 500);
    }

    onLoop = () => {
        if (this.isDragging) {
            requestAnimationFrame(this.onLoop);
            this.displacement = this.axisX - this.originX;
            this.itemLeft = this.displacement < 0;
            this.itemRight = this.displacement > 0;
            this.paymentMethodItemEl.style.setProperty('--displacementItem', `${ this.displacement }px`);
            this.deleteSwipeIconEl.style.setProperty('--displacementIconDelete', `${ this.paymentMethodItemEl.clientWidth + this.displacement + 5 }px`);
            this.editSwipeIconEl.style.setProperty('--displacementIconEdit', `${ this.displacement - this.editSwipeIconEl.clientWidth - 15 }px`);
        }
    };

    get payoutableDisplayInfo() {
        const payoutableInfo = this.paymentMethod.paymentMethod.payoutableInfo;
        if (!payoutableInfo) return;
        if (payoutableInfo.type === null) return payoutableInfo.address;

        switch (payoutableInfo.type) {
            case TransactionAddressType.AccountAddress: return `**** ${payoutableInfo.code}`;
            case TransactionAddressType.WalletAddress: {
                const firstSix = payoutableInfo.address.substring(0, 6);
                const lastSix = payoutableInfo.address.substring(-6);
                return `${firstSix}...${lastSix}`;
            }
            default: return payoutableInfo.address;
        }
    }

    @computedFrom('paymentMethod.paymentMethod.bankAccountInfo')
    get bankAccountDisplayInfo() {
        return `****${this.paymentMethod.paymentMethod.bankAccountInfo.accountNumber.slice(-4)}`;
    }

    hoverPaymentMethod(event) {
        event.stopPropagation();
        const target = event.target as HTMLElement;
        const textWidth = this.calculateTextWidth(`${this.paymentMethod.paymentMethod.name}`);
        if (textWidth <= target.clientWidth || !target.matches(':hover') || target !== this.paymentMethodNameRef)
            return;
        target.style.setProperty('transform', `translateX(${target.clientWidth - textWidth}px)`);
        this.paymentMethodNameRef.classList.remove('text-truncate');
    }

    hoverOutPaymentMethod(event) {
        const target = event.target as HTMLElement;
        target.style.setProperty('transform', 'translateX(0)');
        this.paymentMethodNameRef.style.setProperty('transform', 'translateX(0)');
        this.paymentMethodNameRef.classList.add('text-truncate');
    }

    calculateTextWidth(text) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = '17px Roboto';
        return context.measureText(text).width;
    }
}
