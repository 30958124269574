// Module
var code = `<template>
    <div id="cx-dialog">
        <mdc-dialog view-model.ref="dialog" mdcdialog:closing.trigger="handleClosing()">
            <header class="dialog-header d-flex justify-content-between">
                <mdc-dialog-title class="dialog-title mb-2 text-center text-sm-start">
                    \${title}
                </mdc-dialog-title>
                <span click.delegate="handleClosing()" data-mdc-dialog-action="close" class="material-icons close-icon cursor-pointer d-none d-sm-inline-block">
                    close
                </span>
            </header>
            <article class="dialog-inner">
                <img if.bind="!htmlContent" class="dialog-icon side-image" src="\${ sideIcon }" alt="\${ sideIconAlt }" loading="lazy">
                <div class="main-content">
                    <mdc-dialog-content class="d-flex align-items-center justify-content-center dialog-content \${htmlContent ? 'overflow-hidden' : ''}">
                        <cx-circular-progress if.bind="!htmlContent && triggerCircularLoad"></cx-circular-progress>
                        <div if.bind="htmlContent" ref="htmlContentEl" class="html-content-wrapper" innerhtml.bind="htmlContent" tabindex="0"></div>
                        <p if.bind="!htmlContent" class.bind="textClass">\${ displayedContentText }</p>
                    </mdc-dialog-content>
                    <mdc-dialog-actions if.bind="!htmlContent" class="dialog-actions d-flex flex-column flex-sm-row w-100">
                        <div if.bind="step === 1" class="w-100 d-flex align-items-center justify-content-between flex-column flex-sm-row">
                            <cx-button
                                if.bind="buttons"
                                class=""
                                button-text="\${ confirmText ? confirmText : 'Confirm' }"
                                button-style.bind="'secondary confirm-btn text-medium-static font-medium'"
                                data-mdc-dialog-action="ok"
                                data-mdc-dialog-button-default
                                data-mdc-dialog-initial-focus
                                data-mdc-dialog-action="confirm"
                                click.delegate="confirm()"
                            ></cx-button>
                            <cx-button
                                if.bind="buttons"
                                class=""
                                button-text="Cancel"
                                button-style.bind="'primary primary-style cancel-btn text-medium-static font-medium'"
                                data-mdc-dialog-action="cancel"
                                data-mdc-dialog-button-default
                                data-mdc-dialog-action="cancel"
                            ></cx-button>
                        </div>
                    </mdc-dialog-actions>
                </div>
            </article>
        </mdc-dialog>
    </div>
</template>
`;
// Exports
export default code;