// Module
var code = `<template>
    <section
        id="locations"
        class="legal-page policy \${pageLoading ? 'skeleton-policy' : ''}"
    >
        <div class="main-container">
            <div class="locations-section">
                <div class="locations-container">
                    <div class="locations-header">
                        <h1 class="page-title title-1" skeleton-loading="is-loading.bind: pageLoading;">
                            <cx-page-content-area
                                key="LOCATION_TITLE_1_LANDING"
                            ></cx-page-content-area>
                        </h1>
                        <p class="page-description description-1" skeleton-loading="is-loading.bind: pageLoading;">
                            <cx-page-content-area
                                key="LOCATION_DESCRIPTION_1_LANDING"
                                skeleton-loading="is-loading.bind: pageLoading;"
                            ></cx-page-content-area>
                        </p>
                    </div>
                    <div if.bind="pageLoading">
                        <div class="locations-list">
                            <div
                                class="card"
                                repeat.for="location of locations"
                                click.delegate="onCardClick(location.slug, \$event)"
                                skeleton-loading="is-loading.bind: pageLoading;"
                            ></div>
                        </div>
                    </div>
                    <div class="locations-list" if.bind="!pageLoading">
                        <div
                            class="location-card card"
                            repeat.for="location of locations"
                            click.delegate="onCardClick(location.slug, \$event)"
                        >
                            <div class="image-container">
                                <cx-page-content-image
                                    class="location-card-image"
                                    key.bind="location.imageKeyLeft"
                                    click.delegate="onImageClick(location.slug, \$event)"
                                ></cx-page-content-image>
                            </div>

                            <div class="location-card-content">
                                <h2
                                    class="truncate-multilinee"
                                    id="ellipsis-truncate"
                                >
                                    <cx-page-content-area
                                        key.bind="location.titleKey"
                                    ></cx-page-content-area>
                                </h2>
                                <p
                                    class="location-card-address truncate-multiline"
                                >
                                    <cx-page-content-area
                                        key.bind="location.descriptionKey"
                                    ></cx-page-content-area>
                                </p>
                                <div class="location-card-hours">
                                    <span>
                                        <cx-page-content-area
                                            key.bind="location.locationDays"
                                        ></cx-page-content-area>
                                    </span>
                                    |
                                    <span>
                                        <cx-page-content-area
                                            key.bind="location.locationHours"
                                        ></cx-page-content-area>
                                    </span>
                                </div>
                            </div>

                            <div class="image-container-right">
                                <div class="image-wrapper">
                                    <cx-page-content-image
                                        class="location-card-image-right"
                                        key.bind="location.imageKeyRight"
                                        click.delegate="onMapImageClick(location.slug, \$event)"
                                    ></cx-page-content-image>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-container" if.bind="isModalOpen">
                        <div
                            class="modal-backdrop"
                            click.delegate="closeModal()"
                        ></div>
                        <div
                            class="modal-content"
                            click.delegate="preventClose(\$event)"
                        >
                            <button
                                class="closee-button"
                                click.delegate="closeModal()"
                            >
                                &times;
                            </button>

                            <div class="swiper-container">
                                <div class="swiper-wrapper">
                                    <div
                                        class="swiper-slide"
                                        repeat.for="imageKey of modalImages"
                                    >
                                        <cx-page-content-image
                                            class="locationn-card-image"
                                            key.bind="imageKey"
                                        ></cx-page-content-image>
                                    </div>
                                </div>
                                <div class="swiper-button-next"></div>
                                <div class="swiper-button-prev"></div>

                                <div class="swiper-pagination-numbers"></div>
                                <div class="swiper-pagination-lines"></div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="map-modal-container"
                        if.bind="isMapModalOpen"
                        click.delegate="closeMapModal(\$event)"
                    >
                        <div
                            class="map-modal-content"
                            click.delegate="preventMapModalClose(\$event)"
                        >
                            <button
                                class="modal-close-button"
                                click.delegate="closeMapModal(\$event)"
                            >
                                ✖
                            </button>

                            <iframe
                                class="google-map-frame"
                                if.bind="mapLocation"
                                src.bind="getGoogleMapUrl(mapLocation)"
                                frameborder="0"
                                allowfullscreen
                                loading="lazy"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <div class="offer-section">
                <div class="offer-header">
                    <h2
                      class="offer-title offer-card-title"
                      skeleton-loading="is-loading.bind: pageLoading;"
                    >
                      <cx-page-content-area
                        key="LOCATION_TITLE_2_LANDING"
                      ></cx-page-content-area>
                    </h2>
                  
                    <p
                      class="offer-description offer-card-description"
                      skeleton-loading="is-loading.bind: pageLoading;"
                    >
                      <cx-page-content-area
                        key="LOCATION_DESCRIPTION_2_LANDING"
                      ></cx-page-content-area>
                    </p>
                  </div>
                  
                  <div class="offer-cards">
                    <cds-large-button
                      icon="shopping_cart"
                      class="offer-card-content offer-card-skeleton"
                      click.trigger="navigateTo('/buy')"
                      label="Cart shop"
                      title.bind="'CX_BUY' | siteStrings"
                      skeleton-loading="is-loading.bind: pageLoading; force-div:true; height:var(--large-button-skeleton);"
                    >
                      <cx-page-content-area
                        key="OFFER_DESCRIPTION_CARD_1"
                      ></cx-page-content-area>
                    </cds-large-button>
                  
                    <cds-large-button
                      icon="sell"
                      class="offer-card-content offer-card-skeleton"
                      click.trigger="navigateTo('/sell')"
                      label="Sell"
                      title.bind="'CX_SELL' | siteStrings"
                      skeleton-loading="is-loading.bind: pageLoading; force-div:true; height:var(--large-button-skeleton);"
                    >
                      <cx-page-content-area
                        key="OFFER_DESCRIPTION_CARD_2"
                      ></cx-page-content-area>
                    </cds-large-button>
                  
                    <cds-large-button
                      icon="swap_horiz"
                      class="offer-card-content offer-card-skeleton"
                      click.trigger="navigateTo('/swap/fiat')"
                      label="swap fiat"
                      title.bind="'CX_SELL_SWAP_FIAT' | siteStrings"
                      skeleton-loading="is-loading.bind: pageLoading; force-div:true; height:var(--large-button-skeleton);"
                    >
                      <cx-page-content-area
                        key="OFFER_DESCRIPTION_CARD_3"
                      ></cx-page-content-area>
                    </cds-large-button>
                  
                    <cds-large-button
                      icon="swap_horizontal_circle"
                      class="offer-card-content offer-card-skeleton"
                      click.trigger="navigateTo('/swap/crypto')"
                      label="swap crypto"
                      title.bind="'CX_SELL_SWAP_CRYPTO' | siteStrings"
                      skeleton-loading="is-loading.bind: pageLoading; force-div:true; height:var(--large-button-skeleton);"
                    >
                      <cx-page-content-area
                        key="OFFER_DESCRIPTION_CARD_4"
                      ></cx-page-content-area>
                    </cds-large-button>
                  </div>                  
            </div>
        </div>
    </section>
</template>`;
// Exports
export default code;