import './support.scss';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SupportTicketService } from 'services/support-ticket-service';
import { SupportTicket, SupportTicketStatus } from 'services/models/support-ticket/support-ticket';
import { SignalrService } from 'services/signalr-service';
import { SessionService } from 'services/session-service';
import { FaqSectionService } from 'services/faq-section-service';
import { ImageService } from 'services/image-service';
import { QueryParamsValueConverter } from 'resources/value-converters/query-params';
import { ToastService } from 'services/toast-service';
import { adminPanelUrl } from 'environment';
import { PageContentAreaService } from 'services/page-content-area-service';
import { Helper } from '../../../resources/helpers/helper';
import { User } from '../../../services/models/user/user';

@autoinject()
export class Support {
    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private sessionService: SessionService,
        private supportTicketService: SupportTicketService,
        private signalRService: SignalrService,
        private faqSectionService: FaqSectionService,
        private imageService: ImageService,
        private queryParamsValueConverter: QueryParamsValueConverter,
        private toastService: ToastService,
        private pageContentAreaService: PageContentAreaService,
        private helper: Helper,
    ) { }

    tickets = [];

    @bindable currentState = 'list';
    @bindable titleEdit = false;
    @bindable title = '';
    @bindable issue;
    @bindable selectOptions = [
        {
            selection: 'all',
            active: true
        },
        {
            selection: 'active',
            active: false
        },
        {
            selection: 'closed',
            active: false
        },
        {
            selection: 'draft',
            active: false
        },
        {
            selection: 'pending-response',
            active: false
        }
    ];

    @bindable fileList = [];
    @observable page = 1;
    count = 1;
    perPage;
    sizeChanged;
    width;
    filteredTickets = [];
    isAttached = false;
    initPromise: Promise<void | User>;

    clickedOption = [{
        selection: 'all',
        active: true
    }];

    isSavingDraft = false;
    sort = [
        {
            class: 'ticket-no',
            state: false,
            key: 'ticketKey',
            type: 'string'
        },
        {
            class: 'title',
            state: false,
            key: 'title',
            type: 'string'
        },
        {
            class: 'message',
            state: false,
            key: 'description',
            type: 'string'
        },
        {
            class: 'date',
            state: false,
            key: 'date',
            type: 'string'
        },
        {
            class: 'recent-activity',
            state: false,
            key: 'updateDate',
            type: 'string'
        },
        {
            class: 'status',
            state: false,
            key: 'status',
            type: 'string'
        }
    ];

    user;
    connection;
    ticketId;
    subscribed = false;
    pageLoading = false;
    sectionsFaq = [];
    supportTicketAttachments = [];
    issueInput;
    issueInputKeyupSubscription;
    ticketsCount;
    urlParams;
    existingTicket;
    messagePosition;
    createdNewTicket = false;
    triggeredSendMessage = false;
    pageLoadedSubscriber;

    async attached() {
        try {
            this.pageLoading = true;
            this.urlParams = this.queryParamsValueConverter.toView(window.location.href);
            this.initPromise = Promise.all([
                this.urlParams?.ticketKey ? this.supportTicketService.getTicketByKey(this.urlParams.ticketKey) : Promise.resolve(null),
                this.sessionService.getProfile()
            ]).then(([existingTicket, user]) => {
                this.existingTicket = existingTicket;
                this.user = user;

                if (this.urlParams?.ticketKey && this.urlParams?.messagePosition && this.existingTicket) {
                    if (this.sessionService.canAccessAdminPanel()) {
                        this.router.navigate(`${adminPanelUrl()}management/support-ticket/${this.existingTicket.id}?messagePosition=${this.urlParams.messagePosition}`);
                    }
                }

                if (!this.user) {
                    this.router.navigateToRoute('home');
                    return;
                }
            });
            this.helper.addLoadingComponent('support', this);
            const windowWidth = window.innerWidth;
            await this.initPromise;

            if (windowWidth < 579) {
                this.perPage = 6;
            } else {
                this.perPage = 8;
            }

            const [ticketsCount, sectionsFaq, tickets] = await Promise.all([
                this.supportTicketService.getTicketsCountByUserIdAndWebsite(),
                this.faqSectionService.getForSupport(),
                this.supportTicketService.getTicketsByCustomerId(this.user.id, this.perPage, this.count)
            ]);

            this.ticketsCount = ticketsCount;
            this.sectionsFaq = sectionsFaq;
            this.tickets = tickets;
            this.filteredTickets = this.tickets;

            this.handleDraftTicket();
            this.handleEventSubscriptions();

            if (this.urlParams?.ticketKey && this.urlParams?.messagePosition && this.existingTicket) {
                this.switchToDetails(this.existingTicket.id);
            }

            this.isAttached = true;
            this.count = Math.ceil(this.ticketsCount / this.perPage);
            if (this.tickets.length) {
                this.page = await this.sessionService.getTicketsCurrentPageView() ?? 1;
                this.currentState = 'list';
            }
        }
        finally {
            this.helper.validateLoading('support');
        }
    }

    detached() {
        this.sizeChanged?.dispose();
        this.issueInputKeyupSubscription?.dispose();
        this.connection?.invoke('UnsubscribeSupportTicketChat', this.ticketId);
        this.connection?.off('SubscribeSupportTicketChat');
        this.connection?.off('UnsubscribeSupportTicketChat');
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', async payload => {
            this.width = payload.width;
            if (this.width < 579) {
                this.perPage = 6;
            } else {
                this.perPage = 8;
            }
            await this.pageChanged();
        });

        this.issueInputKeyupSubscription = this.eventAggregator.subscribe('support-issue-input-keyup', async payload => {
            if (payload.key === 'Enter') {
                if (this.currentState === 'create') {
                    await this.sendUsMessage();
                }
            }
        });

        this.pageLoadedSubscriber = this.eventAggregator.subscribe('page-loaded', () => {
            this.pageLoading = false;
        });
    }

    async setActiveOption(clickedOption) {
        if (clickedOption.some(option => option.selection === 'all')) {
            this.filteredTickets = this.tickets;
        } else {
            this.filteredTickets = this.tickets.filter(ticket => clickedOption.some(option => option.selection === ticket.status));
        }
    }

    switchToCreate() {
        this.currentState = 'create';
    }

    switchToDetails(id) {
        this.ticketId = id;
        this.connection?.off('ReceiveMessage');
        this.currentState = 'detail';
        this.fileList = [];
        this.subscribed = false;
        this.titleEdit = false;
        this.issue = this.title = '';
        this.issueInput?.au.controller.viewModel.cleanFileList();
    }

    async handleBack() {
        if (this.currentState === 'create' || this.currentState === 'detail' || this.currentState === 'suggested-title') {
            if (this.createdNewTicket || this.currentState === 'detail') {
                this.tickets = await this.supportTicketService.getTicketsByCustomerId(this.user.id, this.perPage, this.page);
                this.filteredTickets = this.tickets;
                this.router.navigateToRoute('support');
            }
            this.connection?.off('ReceiveMessage');
            this.subscribed = false;
            this.messagePosition = null;
            this.currentState = this.tickets.length ? 'list' : 'home';
            if (this.currentState === 'list') {
                this.handleDraftTicket();
            }
            this.titleEdit = false;
            this.issue = this.title = '';
            this.issueInput?.au.controller.viewModel.cleanFileList();
            this.fileList = [];
        }
    }

    async showIntercomMessage() {
        if (window.Intercom) {
            if (!this.issue) {
                window.Intercom('showNewMessage', 'Hello, I need help');
            } else {
                if (this.title !== 'Choose a Title') {
                    window.Intercom('showNewMessage', `${this.title}: ${this.issue}`);
                } else {
                    window.Intercom('showNewMessage', this.issue);
                }
            }
        }
    }

    async pageChanged(event?) {
        if (this.isAttached) {
            this.count = Math.ceil(this.ticketsCount / this.perPage);
            if (event) {
                this.sessionService.saveTicketsCurrentPageView(this.page);
            }
            if (this.page > this.count && this.count > 0) {
                this.page = this.count;
            }
            this.filteredTickets = await this.supportTicketService.getTicketsByCustomerId(this.user.id, this.perPage, this.page);
            this.handleDraftTicket();
        }
    }

    sortBy(index) {
        const column = this.sort[index];

        this.filteredTickets.sort((a, b) => {
            const item = [a, b];
            if (this.sort[index].state) { item.reverse(); }
            if (column.type === 'string') {
                if (item[0][column.key] > item[1][column.key]) {
                    return 1;
                }
                if (item[0][column.key] < item[1][column.key]) {
                    return -1;
                }
                return 0;
            } else if (column.type === 'number') {
                return item[0][column.key] - item[1][column.key];
            }
        });

        this.sort[index].state = !this.sort[index].state;

        const sortAsc = document.getElementById(`${column.class}-up`);
        const sortDesc = document.getElementById(`${column.class}-down`);
        if (this.sort[index].state) {
            sortAsc.style.color = 'rgba(67, 0, 169, 1)';
            sortDesc.style.color = 'rgba(0, 0, 0, 1)';
        } else {
            sortAsc.style.color = 'rgba(0, 0, 0, 1)';
            sortDesc.style.color = 'rgba(67, 0, 169, 1)';
        }
    }

    async sendUsMessage() {
        if (!this.issue || !this.title) {
            return this.toastService.showToast('Info', !this.issue ? 'Please enter a message to proceed with creating a ticket.' : 'Please enter a custom title to proceed with creating a ticket.', 'info');
        }

        if (!this.triggeredSendMessage) {
            this.triggeredSendMessage = true;
            try {
                const ticket: SupportTicket = {
                    customerId: this.user.id,
                    description: this.issue,
                    title: this.title,
                    status: SupportTicketStatus.Active,
                    orderId: null,
                    websiteId: 4,
                    departmentId: null,
                    ticketKey: null,
                    messages: [],
                };

                if (!this.subscribed) {
                    this.subscribed = true;
                    this.ticketId = (await this.supportTicketService.createTicket(ticket)).id;

                    this.connection = await this.signalRService.getSignalRConnection();

                    this.connection.invoke('SubscribeSupportTicketChat', this.ticketId);

                    this.connection.on('ReceiveMessage', (result) => {
                        if (result) {
                            this.toastService.showToast(undefined, 'Created a support ticket.', 'success');
                            this.createdNewTicket = true;
                            this.currentState = 'detail';
                            this.issueInput?.au.controller.viewModel.cleanFileList();
                        }
                    });
                }
                await this.sessionService.destroyDraftMessage();
                await this.sessionService.destroyDraftTitle();
                await this.sessionService.destroyDraftCreatedDate();
                await this.sessionService.destroyDraftUpdatedDate();
                this.supportTicketAttachments = await this.uploadAttachments(this.fileList);
                this.supportTicketService.chat(this.ticketId, {
                    userId: ticket.customerId,
                    message: ticket.description,
                    attachments: this.supportTicketAttachments
                });
                this.fileList = [];
            } catch (e) {
                console.log(e);
            } finally {
                setTimeout(() => this.triggeredSendMessage = false, 1500);
            }
        }
    }

    async uploadAttachments(fileList) {
        const uploadedFiles = [];
        for (const file of fileList) {
            const formData = this.imageService.buildFormData([file]);
            uploadedFiles.push(await this.imageService.postDocumentSupportTicket(formData, this.ticketId));
        }
        return uploadedFiles;
    }

    async handleDraftTicket() {
        const draftMessage = await this.sessionService.getDraftMessage();
        const draftTitle = await this.sessionService.getDraftTitle();

        if ((draftMessage || draftTitle)) {
            const draftTicket = {
                ticketKey: 'DRAFT',
                title: draftTitle ?? '-',
                description: draftMessage ?? '-',
                createdDate: await this.sessionService.getDraftCreatedDate(),
                updatedDate: await this.sessionService.getDraftUpdatedDate(),
                status: 'draft'
            };

            if (this.filteredTickets.find(x => x.ticketKey === 'DRAFT')) {
                const index = this.filteredTickets.findIndex(x => x.ticketKey === 'DRAFT');
                this.filteredTickets.splice(index, 1);
            }

            this.filteredTickets.push(draftTicket);
        }
    }
}
