import { SessionService } from 'services/session-service';
import './cx-notification.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { getAWSBucketEndpoint, baseUrl } from 'environment';
import { Language } from 'services/models/language/language';
import { Router } from 'aurelia-router';

@autoinject()
export class CxNotification {
    constructor(private sessionService: SessionService, private router: Router) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('icons');
    }

    @bindable notificationData;
    @bindable selectedFilter;
    @bindable loading;
    @bindable functionChecked;
    @bindable functionMarkRead;
    baseAwsEndpoint: string;
    isHover = false;
    iconDefault = 'icons/default-notification-icon.svg';
    currentLanguage: Language;

    async attached() {
        this.notificationData.checked = false;
        this.currentLanguage = await this.sessionService.getLanguage();
    }

    mouseEventHandler(ev) {
        switch (ev.type) {
            case 'mouseenter':
                this.isHover = true;
                break;
            case 'mouseleave':
                this.isHover = false;
                break;
            default:
                break;
        }
    }

    loadIcon() {
        if (this.notificationData.imagePath || this.notificationData.preloadedIcon) {
            return this.notificationData.imagePath ?
                `${this.baseAwsEndpoint}${this.notificationData.imagePath}` :
                `${this.baseAwsEndpoint}${this.notificationData.preloadedIcon}`;
        } else {
            return `${baseUrl()}${this.iconDefault}`;
        }
    }

    handleNavigation(element) {
        const classesToCheck = ['check-material', 'bullet'];
        if (this.notificationData?.redirectTo && !classesToCheck.some(x => element.srcElement.classList.contains(x))) {
            this.functionMarkRead !== null && this.functionMarkRead();
            this.router.navigate(this.notificationData.redirectTo);
        }
    }
}
