import { SessionService } from 'services/session-service';
import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { PaymentMethodCurrencyFeeService } from './payment-method-currency-fee-service';
import {
    Currency,
    PaymentMethod,
    PaymentMethodCurrencyFeeWebRequest,
    PaymentMethodWebsite
} from './models/purchase-flow/exchange';
import { CustomerService } from './customer-service';
import { Helper } from 'resources/helpers/helper';
import { DataCurrencyStats } from 'services/models/currency/dataStatsCurrenciesInterface';


@autoinject()
export class CurrencyService {
    path = 'Currency';
    currencyRates = [];
    coinPaymentsCurrencies;
    cryptoPaymentsCurrencies;

    constructor(
        private api: ApiService,
        private PaymentMethodCurrencyFeeService: PaymentMethodCurrencyFeeService,
        private sessionService: SessionService,
        private customerService: CustomerService,
        private helper: Helper
    ) { }

    async getAllCurrencyRates(websiteShortCode: string) {
        try {
            const currencyResponse = await this.api.doGet(`${this.path}?websiteShortCode=${websiteShortCode}`);
            if (currencyResponse) {
                this.currencyRates.push(currencyResponse.rates);
                return;
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getStoredCurrencyRates(currency: string | null): Promise<number | null> {
        try {
            if (currency === 'USD' || !currency) {
                return 1;
            }

            if (!this.currencyRates?.length) await this.getAllCurrencyRates('CX');

            let foundRate: number | null;
            const currencyProperty = currency.toLowerCase();
            const rate = this.currencyRates.find(x => Object.prototype.hasOwnProperty.call(x, currencyProperty));

            if (rate) foundRate = rate[currencyProperty];

            return foundRate;
        } catch (e) {
            console.log(e);
        }
    }

    getStoredActiveCurrenciesByWebsite() {
        return this.sessionService.getActiveCurrencies();
    }

    async getActiveCurrenciesByWebsite() {
        try {
            if (this.sessionService.getActiveCurrencies().length > 0) {
                return this.sessionService.getActiveCurrencies();
            }
            let activeCurrencies = (await this.api.doGet(`${this.path}/GetActiveCurrenciesByWebsite?websiteShortCode=CX`)).map((e: Currency) => {
                //Conditional for crypto currencies to have the same shortname as description field.
                return this.modifyCurrency(e);
            });
            const userCountry = this.sessionService.getCountry();
            activeCurrencies = this.removeBlockedCurrencies(activeCurrencies, userCountry);
            this.sessionService.setActiveCurrencies(activeCurrencies);
            return activeCurrencies;
        } catch (e) {
            console.log(e);
        }
    }

    async getCurrencyById(id: number) {
        try {
            if (!this.sessionService.getActiveCurrencies()) {
                await this.getActiveCurrenciesByWebsite();
            }
            return this.sessionService.getActiveCurrencies().find(c => c.id === id);
        } catch (e) {
            console.log(e);
        }
    }

    async getCurrencyByCode(code: string) {
        try {
            if (!this.sessionService.getActiveCurrencies()) {
                await this.getActiveCurrenciesByWebsite();
            }
            return this.sessionService.getActiveCurrencies().find(c => c.code === code);
        } catch (e) {
            console.log(e);
        }
    }

    async getCoinpaymentList(): Promise<Currency[]> {
        if (this.coinPaymentsCurrencies) return this.coinPaymentsCurrencies;
        this.coinPaymentsCurrencies = (await this.api.doGet(`${this.path}/GetAllCoinpaymentCurrencies?websiteShortCode=CX&countryShortCode=${this.sessionService.getCountry()}`)).map(e => {
            //Conditional for crypto currencies to have the same shortname as description field.
            return this.modifyCurrency(e);
        });
        return this.coinPaymentsCurrencies;
    }

    async getCryptoList(): Promise<Currency[]> {
        if (this.cryptoPaymentsCurrencies) return this.cryptoPaymentsCurrencies;
        this.cryptoPaymentsCurrencies = (await this.api.doGet(`${this.path}/GetAllCryptoCurrenciesForWebsite?websiteShortCode=CX&countryShortCode=${this.sessionService.getCountry()}`)).map(e => {
            //Conditional for crypto currencies to have the same shortname as description field.
            return this.modifyCurrency(e);
        });
        return this.cryptoPaymentsCurrencies;
    }

    async getCryptoForSelector(): Promise<Currency[]> {
        this.cryptoPaymentsCurrencies = (await this.api.doGet(`${this.path}/GetAllCryptoCurrenciesForWebsite?websiteShortCode=CX&countryShortCode=${this.sessionService.getCountry()}`)).map(e => {
            //Conditional for crypto currencies to have the same shortname as description field.
            return this.modifyCurrency(e);
        });
        return this.cryptoPaymentsCurrencies;
    }

    /**
     * Function that gets the fee based on website / payment method / currency selected
     * @returns Fee
     */
    async getFeeLegacy(dataForFee: {
        giveSelectedCurrency: Currency;
        receiveSelectedCurrency: Currency;
        selectedPaymentMethod: PaymentMethod;
        receivingPaymentMethod: PaymentMethod;
        amount: number;
    }): Promise<number> {
        let data: PaymentMethodCurrencyFeeWebRequest;
        if (dataForFee.giveSelectedCurrency?.type === 'F') {
            data = {
                currencyId: dataForFee.receiveSelectedCurrency.id ?? 0,
                paymentMethodId: dataForFee.selectedPaymentMethod?.id ?? 0,
                amount: !isNaN(dataForFee.amount) ? dataForFee.amount : 0,
                operationType: 'S'
            };
        } else {
            data = {
                currencyId: dataForFee.giveSelectedCurrency?.id ?? 0,
                paymentMethodId: dataForFee.receivingPaymentMethod?.id ?? 0,
                amount: !isNaN(dataForFee.amount) ? dataForFee.amount : 0,
                operationType: 'B'
            };
        }
        return (await this.PaymentMethodCurrencyFeeService.getByWebsitePaymentMethodAndCurrency(data)).percentage / 100;
    }

    /**
     * Function that gets the fee based on website / payment method / currency selected
     */
    async getFee(dataForFee: {
        giveSelectedCurrency: Currency;
        receiveSelectedCurrency: Currency;
        selectedPaymentMethod: PaymentMethodWebsite;
        receivingPaymentMethod: PaymentMethodWebsite;
        amount: number;
    }) {
        let paymentMethodId = 0;

        if (dataForFee.giveSelectedCurrency?.type === 'F') {
            paymentMethodId = dataForFee.selectedPaymentMethod?.paymentMethod.id ?? 0;
        } else {
            paymentMethodId = dataForFee.receivingPaymentMethod?.paymentMethod.id ?? 0;
        }

        const data: PaymentMethodCurrencyFeeWebRequest = {
            baseCurrencyId: dataForFee.giveSelectedCurrency.id ?? 0,
            targetCurrencyId: dataForFee.receiveSelectedCurrency.id ?? 0,
            paymentMethodId: paymentMethodId,
            amount: !isNaN(dataForFee.amount) ? dataForFee.amount : 0,
            operationType: 'FF'
        };

        const feeResponse = await this.PaymentMethodCurrencyFeeService.getByWebsitePaymentMethodAndCurrency(data);

        if (feeResponse) {
            feeResponse.percentage /= 100;
            return feeResponse;
        }

        return null;
    }

    async getMultipleCurrencyStatsByCode(stats) {
        const params = new URLSearchParams({ codes: stats }).toString();
        return await this.api.doGet(`${this.path}/stats?${params}`);
    }

    removeBlockedCurrencies(activeCurrencies: Currency[], currentCountryCode: string) {
        if (!currentCountryCode) return activeCurrencies;
        return activeCurrencies.filter(c => this.countryIsNotRestrictedInCurrency(c, currentCountryCode));
    }

    countryIsNotRestrictedInCurrency(currency: Currency, countryCode: string) {
        const result = !currency.restrictedCountries?.some(c => c.country?.alpha2 === countryCode);
        return result;
    }

    async getSpreadFeeByCurrencyCodeAndWebsite(currencyCode: string, websiteShortCode: string) {
        return await this.api.doGet(`${this.path}/GetSpreadFee/${currencyCode}/${websiteShortCode}`);
    }

    modifyCurrency(e: Currency): Currency {
        if (e.type === 'C') {
            e.shortName = e.description;
        }
        e.originalCode = e.code;
        if (['USDT-TRC20', 'USDT-ERC20', 'USDC-TRC20', 'USDC-ERC20'].includes(e.code)) {
            e.code = e.code.replace(/-TRC20|-ERC20/g, '');
        }
        return e;
    }

    async getDesiredCurrency(currencyCode: string) {
        const currencyOptions = await this.getActiveCurrenciesByWebsite();
        return currencyOptions.find(currency => currency.code === currencyCode);
    }

    async getConvertionRateToUSD(currency: string, isSold = false): Promise<number | null> {
        try {
            return await this.api.doGet(`${this.path}/ConvertionRateToUSD/${currency}?websiteShortCode=CX&isSold=${isSold}`);
        } catch (e) {
            console.log(e);
        }
    }

    async getStatsDataCurrency(stats, startDate: string = '', endDate: string = '', interval: string = '60') {
        const currentTimeStamp = new Date().getTime();
        if (endDate === '') endDate = currentTimeStamp.toString();
        if (startDate === '') startDate = (currentTimeStamp - (60 * 60 * 1000)).toString();

        const params = new URLSearchParams({
            codes: stats,
            interval,
            startDate,
            endDate
        }).toString();
        return await this.api.doGet(`${this.path}/GetStats?${params}`) as DataCurrencyStats[];
    }

    async getCurrencyOptions() {
        const currencies = await this.getActiveCurrenciesByWebsite();
        const currencyOptions = [];
        if (currencies?.length) {
            for (const currency of currencies) {
                if (currency.type && currency.type === 'F') {
                    currencyOptions.push(currency);
                }
            }
        }
        return currencyOptions.sort((a, b) => {
            if (a.code < b.code) return -1;
            else if (a.code > b.code) return 1;
            return 0;
        });
    }

    async invertRatesForCurrency(rates: { [key: string]: number }, desiredCurrencyCode: string) {
        try {
            const desiredCurrencyRate = rates[desiredCurrencyCode];
            const convertedRates: { [key: string]: number } = {};
            for (const [key, value] of Object.entries(rates)) {
                if (key === desiredCurrencyCode)
                    convertedRates[key] = 1; // The desired currency becomes the base (1 unit)
                else
                    convertedRates[key] = value / desiredCurrencyRate;
            }
            return Promise.resolve(convertedRates);
        } catch (error) {
            console.log(error);
        }
    }
}
