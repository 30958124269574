import './cx-amount-payment-selector.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { Currency, PaymentMethodWebsite } from 'services/models/purchase-flow/exchange';

@autoinject()
export class CxAmountPaymentSelector {
    @bindable giveSelectedCurrency: Currency;
    @bindable receiveSelectedCurrency: Currency;
    @bindable selectedPaymentMethod: PaymentMethodWebsite;
    @bindable exchangeType: 'B' | 'S' | 'FF';
    parent;
    myPaymentSelector;
    amountPaymentSelector: HTMLElement;

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async attached() {
        this.overrideMdc();
    }

    overrideMdc() {
        const selectArrow = document.getElementsByClassName('expand-icon');
        const newArrowIcon = '<span class="arrow-icon material-icons">expand_more</span>';
        if (selectArrow) {
            for (const arrow of Array.from(selectArrow)) {
                arrow.innerHTML = newArrowIcon;
            }
        }
    }

    handlePaymentMethodSwitch(newValue: PaymentMethodWebsite, oldValue: PaymentMethodWebsite) {
        this.parent.handlePaymentMethodSwitch(newValue, oldValue);
    }

    resetPaymentMethod() {
        this.myPaymentSelector.au.controller.viewModel.resetPaymentMethod();
    }

    selectedPaymentMethodChanged(newValue: PaymentMethodWebsite, oldValue: PaymentMethodWebsite) {
        if (!newValue || newValue?.paymentMethodId === oldValue?.paymentMethodId)
            return;
        this.parent.focusOutCheckValid();
    }
}
