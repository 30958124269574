// Module
var code = `<template>
    <div id="google-pay-checkout-form">
        <cx-dialog
            view-model.ref="dialogModule"
            html-content.bind="challengeIframe"
            trigger-circular-load="true"
            title="3DS Authentication"
        ></cx-dialog>
    </div>
</template>
`;
// Exports
export default code;