import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { BankCatalogNoBranchesResponse, BankCatalogResponse, IsInstitutionSupportedByPlaidResponse } from './models/bank-catalog/bank-catalog';

@autoinject()
export class BankCatalogService {
    constructor(private api: ApiService) {
        this.banksWithNoBranchesByCountry = new Map<string, BankCatalogNoBranchesResponse[]>();
        this.banksByCountry = new Map<string, BankCatalogResponse[]>();
        this.banksById = new Map<number, BankCatalogResponse>();
    }

    path = 'BankCatalog';
    banksWithNoBranchesByCountry: Map<string, BankCatalogNoBranchesResponse[]>;
    banksByCountry: Map<string, BankCatalogResponse[]>;
    banksById: Map<number, BankCatalogResponse>;

    async getBanksWithNoBranchesByCountry(countryCode: string): Promise<BankCatalogNoBranchesResponse[]> {
        if (!this.banksWithNoBranchesByCountry.has(countryCode)) {
            this.banksWithNoBranchesByCountry.set(countryCode, await this.api.doGet(`${this.path}/country/${countryCode}/no-branches`));
        }
        return this.banksWithNoBranchesByCountry.get(countryCode);
    }

    async getBanksByCountry(countryCode: string): Promise<BankCatalogResponse[]> {
        if (!this.banksByCountry.has(countryCode)) {
            this.banksByCountry.set(countryCode, await this.api.doGet(`${this.path}/country/${countryCode}`));
        }

        return this.banksByCountry.get(countryCode);
    }

    async getBankById(bankId: number): Promise<BankCatalogResponse> {
        if (!this.banksById.has(bankId)) {
            this.banksById.set(bankId, await this.api.doGet(`${this.path}/${bankId}`));
        }

        return this.banksById.get(bankId);
    }

    async getBankIsSupportedByPlaid(bankId: number): Promise<IsInstitutionSupportedByPlaidResponse> {
        return await this.api.doGet(`${this.path}/supported-by-plaid/bank/${bankId}`);
    }
}
