// Module
var code = `<template>
    <cx-try-again-box
        id="site-down"
        use-keys.bind="true"
        show-status.bind="true"
        main="SITE_DOWN_TITLE"
        subtitle="SITE_DOWN_SUBTITLE"
        main-fallback="Website under maintenance"
        subtitle-fallback="Website is currently down, please try again after a few minutes. If you are still unable to connect, send us an email to support@chicksgold.com and we’ll get back you in a few minutes."
        page="Site Down"
        variant="filled"
        button-text="Try again"
        icon="build"
        href.bind="redirectTo"
    >
    </cx-try-again-box>
</template>
`;
// Exports
export default code;