import './cx-amount-currency-selector.scss';
import { CDS_TEXT_FIELD_STATE, CdsTextField } from '@chicksgroup/web-components/dist';
import { Subscription, EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, computedFrom, InternalPropertyObserver, observable, ObserverLocator } from 'aurelia-framework';
import { AdminViewUpdatedEvent, DesktopBreakpoint, SizeChangedEvent } from 'resources/constants';
import { Helper } from 'resources/helpers/helper';
import { Currency } from 'services/models/purchase-flow/exchange';
import { ISizeEvent } from 'types/events';
import { SiteStringsValueConverter } from 'resources/value-converters/site-strings';
import { ToastService } from 'services/toast-service';
import { ThousandSeparatorValueConverter } from 'resources/value-converters/thousand-separator';
import { CurrencyDropdownResponse } from 'services/models/currency/currencyDropdownResponse';

@autoinject()
export class CxAmountCurrencySelector {
    constructor(
        private helper: Helper,
        private eventAggregator: EventAggregator,
        private siteStringsValueConverter: SiteStringsValueConverter,
        private toastService: ToastService,
        private thousandSeparator: ThousandSeparatorValueConverter,
        private observerLocator: ObserverLocator
    ) { }

    @bindable currencyList: Currency[];
    @bindable currencySelected: Currency;
    @bindable exchangeCurrencySelected: Currency;
    @bindable intent: string;
    @bindable activeCurrencies: Currency[];

    @bindable amount: number;
    @bindable defaultAmount: number = 0;
    @bindable selectedPaymentMethod: string;
    @bindable tooltipRefId: string;
    @bindable pageLoading: boolean;
    @bindable maxAmount: number;
    @bindable recentCurrencies: CurrencyDropdownResponse[];
    @observable inputAmount: number | string;
    parent;
    currencyCodeSuffixRef: HTMLElement;
    showTooltip: boolean;
    width: number;
    amountInputMobileRef: CdsTextField;
    sizeSub: Subscription;
    tradeIntentChangedSub: Subscription;
    adminViewUpdatedSub: Subscription;
    clientWidthObserver: InternalPropertyObserver;
    amountCurrencySelector: HTMLElement;
    inputState: CDS_TEXT_FIELD_STATE = 'valid';
    viewingAsAdmin: boolean;
    isDesktop: boolean;
    isFocused: boolean;

    bind(bindingContext) {
        this.parent = bindingContext;
        this.handleSizeScreenChange();
        this.handleEventSubscriptions();
        this.observeSuffixClientWidth();
    }

    attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.isDesktop = this.width >= DesktopBreakpoint;
        if (this.tooltipRefId)
            this.showTooltip = true;

    }

    detached() {
        this.sizeSub.dispose();
        this.tradeIntentChangedSub?.dispose();
        this.adminViewUpdatedSub?.dispose();
        this.clientWidthObserver?.unsubscribe(() => this.getAmountWidth());
    }

    handleSizeScreenChange() {
        this.sizeSub = this.eventAggregator.subscribe(SizeChangedEvent, (payload: ISizeEvent) => {
            this.width = payload.width;
            this.isDesktop = this.width >= DesktopBreakpoint;
            if (!this.inputAmount)
                return;
            if (this.width <= 350) {
                this.currencyCodeSuffixRef.style.left = '';
                this.currencyCodeSuffixRef.style.right = '0px';
            } else {
                this.currencyCodeSuffixRef.style.right = '';
                this.getAmountWidth();
            }
        });
    }

    handleEventSubscriptions() {
        this.tradeIntentChangedSub = this.eventAggregator.subscribe('trade-intent-changed', () => this.getAmountWidth());
        this.adminViewUpdatedSub = this.eventAggregator.subscribe(AdminViewUpdatedEvent, (payload) => this.viewingAsAdmin = payload.bool);
    }

    private observeSuffixClientWidth() {
        this.clientWidthObserver = this.observerLocator.getObserver(this.currencyCodeSuffixRef, 'clientWidth');
        this.clientWidthObserver.subscribe(() => {
            this.getAmountWidth();
        });
    }

    selectChanged(currencySelected: Currency, intent: string, isClicked: boolean = false) {
        this.parent.selectChanged(currencySelected, intent, isClicked);
        if (!currencySelected) {
            this.inputAmount = undefined;
            this.amount = this.defaultAmount = 0;
        }
    }

    amountChanged() {
        if (this.amount.toFixed() !== this.inputAmount) {
            this.inputAmount = this.helper.parseInputAmount(this.amount);
            if (!this.isFocused)
                this.inputAmount = this.thousandSeparator.toView(this.inputAmount, this.getFractionDigits());
        }
    }

    inputAmountChanged() {
        this.getAmountWidth();
    }

    currencySelectedChanged() {
        this.getAmountWidth();
    }

    async handleKeydown() {
        if ((!this.inputAmount || !this.isFocused))
            return;
        if (this.inputAmount)
            this.amount = parseFloat(this.inputAmount?.toString());
        if (this.amount.toString().includes('e')) {
            this.toastService.showToast('Error', 'The entered number is too large to process. Please enter a smaller value', 'error', 'error');
            this.amount = this.defaultAmount;
        }
        await this.parent.focusOutCheckValid(this.intent, 'keydown', this.inputAmount);
    }

    focusIn() {
        this.isFocused = true;
        this.eventAggregator.publish('trade-field-focused', { intent: this.intent, isFocused: true });
        this.inputAmount = this.thousandSeparator.fromView(this.inputAmount);
    }

    async handleBlur() {
        this.isFocused = false;
        this.eventAggregator.publish('trade-field-focused', { intent: this.intent, isFocused: false });
        this.inputAmount = this.thousandSeparator.toView(this.inputAmount, this.getFractionDigits());
        await this.parent.focusOutCheckValid(this.intent, 'focusout', this.inputAmount);
    }

    @computedFrom('intent', 'pageLoading', 'viewingAsAdmin')
    get intentLabel() {
        const intentMap = {
            'give': 'CX_YOU_GIVE',
            'receive': 'CX_YOU_RECEIVE'
        };
        return this.siteStringsValueConverter.toView(intentMap[this.intent]);
    }

    handleInput() {
        this.inputAmount = this.inputAmount.toString().replace(/[^0-9.]/g, '');
        // Prevent multiple decimal points
        const parts = this.inputAmount.toString().split('.');
        if (parts.length > 2)
            this.inputAmount = parts[0] + '.' + parts.slice(1).join('');
        this.checkForMaximum();
    }

    async handleInputMobile(event) {
        // Allow only numbers and decimals
        if (event?.target?.value) {
            this.inputAmount = event?.target?.value.replace(/[^0-9.]/g, '');
            this.amountInputMobileRef.setValue(this.inputAmount, false);
        }
        this.handleInput();
        await this.handleKeydown();
    }

    async getAmountWidth() {
        if (this.width >= DesktopBreakpoint || !this.amountInputMobileRef)
            return;
        if (!(this.inputAmount?.toString())?.length)
            return;
        const inputDigitLength = (this.inputAmount?.toString())?.replace(/[,.]/g, '').length;
        let attempts = 0;
        while ((!this.currencyCodeSuffixRef?.clientWidth) && attempts < 3) {
            await new Promise((resolve) => setTimeout(resolve, 20));
            attempts++;
        }
        if (!this.currencyCodeSuffixRef) return;
        this.amountInputMobileRef.style.maxWidth = `calc(100% - ${this.currencyCodeSuffixRef.clientWidth}px)`;
        this.currencyCodeSuffixRef.style.left = `${inputDigitLength + 1}ch`;
        this.currencyCodeSuffixRef.style.right = 'unset';
        if ((this.currencyCodeSuffixRef.offsetLeft + 25) >= this.amountInputMobileRef.clientWidth) {
            this.currencyCodeSuffixRef.style.left = 'unset';
            this.currencyCodeSuffixRef.style.right = '0';
            return;
        }
    }

    @computedFrom('inputAmount')
    get labelClass() {
        return this.inputAmount ? 'input-prefilled' : 'input-empty';
    }

    @computedFrom('currencySelected', 'exchangeCurrencySelected', 'intent')
    get showTooltipForReceive(): boolean {
        return this.currencySelected && this.exchangeCurrencySelected && this.intent === 'receive';
    }

    @computedFrom('inputAmount.length')
    get mobileInputClass() {
        if (!(this.inputAmount?.toString())?.length || this.isDesktop)
            return;
        const inputDigitLength = (this.inputAmount?.toString())?.replace(/[,.]/g, '').length;
        if (inputDigitLength > 9)
            return 'amount-input-mobile-10-digits';
        else if (inputDigitLength === 9)
            return 'amount-input-mobile-9-digits';
        else if (inputDigitLength === 8)
            return 'amount-input-mobile-8-digits';
        else if (inputDigitLength === 7)
            return 'amount-input-mobile-7-digits';
        else return '';
    }

    @computedFrom('inputAmount', 'pageLoading', 'amount')
    get showSuffix() {
        return this.inputAmount?.toString().length && !this.pageLoading;
    }

    checkForMaximum() {
        if (this.maxAmount && parseFloat(this.inputAmount?.toString()) > this.maxAmount) {
            const toFixedAmount = this.currencySelected?.type === 'F' || this.currencySelected?.isStable ? 2 : 6;
            this.inputAmount = this.maxAmount.toFixed(toFixedAmount);
        }
    }

    defaultAmountChanged(newValue: number, oldValue: number) {
        if (!this.amount && !oldValue && this.intent === 'give') {
            this.inputAmount = this.amount = this.defaultAmount;
            this.handleBlur();
        }
    }

    private getFractionDigits(): number {
        return this.currencySelected?.type === 'F' || this.currencySelected?.isStable || !this.currencySelected ? 2 : 6;
    }
}
