// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/home/shield.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/home/medal_trustpilot.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/home/star_full.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/home/star_half.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/home/star_semisesqui.svg", import.meta.url);
// Module
var code = `<template>
    <div id="cx-hero">
        <div class="hero-content text-center text-md-start w-100">
            <div class="text-start">
                <div class="banner text-start">
                    <div class="banner-title" skeleton-loading="is-loading.bind:isLoading;">
                        <cx-page-content-area key.bind="firstText" if.bind="!isLoading"></cx-page-content-area>
                    </div>
                    <div class="banner-subtitle text-medium-static font-regular" skeleton-loading="is-loading.bind:isLoading;">
                        <cx-page-content-area key.bind="secondText" if.bind="!isLoading"></cx-page-content-area>
                    </div>
                    <a href.bind="tradeRoute">
                        <cx-button
                            skeleton-loading="is-loading.bind:isLoading;height:50px;"
                            class="text-medium d-inline-block"
                            button-text="\${'CX_EXCHANGE_NOW' | siteStrings}"
                            button-style.bind="'exchange-btn text-medium font-medium text-white'"
                            button-icon-style="me-3 text-large-plus"
                            click.delegate="navigateToTrade()"
                        ></cx-button>
                    </a>
                </div>
                <div class="d-flex flex-column flex-md-row">
                    <div class="licenses-container d-flex flex-row align-items-center">
                        <img class="shield-image" skeleton-loading="is-loading.bind:isLoading;" src="${___HTML_LOADER_IMPORT_0___}" alt="shield icon" loading="lazy">
                        <div class="d-flex flex-column text-white">
                            <div class="lh-huge licenses-text" skeleton-loading="is-loading.bind:isLoading;">
                                <cx-page-content-area key.bind="licenses" if.bind="!isLoading"></cx-page-content-area>
                            </div>
                            <cx-touch-target>
                                <a class="learn-more mt-1 mb-0 d-flex align-items-center cursor-pointer text-medium-static" skeleton-loading="is-loading.bind:isLoading;" href.bind="faqRoute">
                                    <span>\${'CX_LEARN_MORE' | siteStrings}</span>
                                    <span class="material-icons ms-2">
                                        arrow_forward
                                    </span>
                                </a>
                            </cx-touch-target>
                        </div>
                    </div>
                    <div class="trustpilot-container d-flex flex-row align-items-center">
                        <img class="medal-image" src="${___HTML_LOADER_IMPORT_1___}" skeleton-loading="is-loading.bind:isLoading;" alt="medal trustpilot" loading="lazy">
                        <div class="d-flex flex-column text-white">
                            <div class="d-flex flex-row align-items-center \${isLoading ? 'mb-1 rating-stars' : ''}" skeleton-loading="is-loading.bind:isLoading;">
                                <img repeat.for="i of amountOfStars" src="${___HTML_LOADER_IMPORT_2___}" class="trust-pilot-star" alt="Star icon" loading="lazy">
                                <img if.bind="halfStar" src="${___HTML_LOADER_IMPORT_3___}" class="trust-pilot-star" alt="Half Star icon" loading="lazy">
                                <img if.bind="semiSesquiStar" src="${___HTML_LOADER_IMPORT_4___}" class="trust-pilot-semisesqui-star" alt="Semisesqui Star icon" loading="lazy">
                                <span if.bind="viewingAsAdmin" class="admin-view-size ms-1">Key: { CX_TRUST_PILOT_RATING }/5</span>
                                <span else class="text-medium-static trust-pilot-text">\${ trustPilotStarRating }/5</span>
                            </div>
                            <cx-touch-target class="rating-text" skeleton-loading="is-loading.bind:isLoading;">
                                <a
                                    class="learn-more mt-1 mb-0 d-flex align-items-center text-medium-static"
                                    href="https://www.trustpilot.com/review/chicksx.com"
                                    target="_blank"
                                    rel="nofollow noopener noreferrer"
                                >
                                    <span>\${'CX_CUSTOMERS_REVIEW_TRUSTPILOT' | siteStrings}</span>
                                    <span class="material-icons ms-2">
                                        arrow_forward
                                    </span>
                                </a>
                            </cx-touch-target>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <slot></slot>
    </div>
</template>
`;
// Exports
export default code;